import { UserIcon } from "@heroicons/react/solid";
import { getReleaseCardClass } from "helpers/constants/release";
/**
 *
 * @param {Object} event
 * @param {Function} onClick
 * @returns
 */

function EventCard({ event, onClick }) {
  return (
    <div
      className={`release-card space-y-2.5 ${getReleaseCardClass(
        event?.startDate,
        event?.endDate
      )}`}
    >
      <h1 className="line-clamp-2">{event?.name || event?.title}</h1>
      <div className="flex flex-row items-center space-x-1.5 justify-between">
        <div className="flex flex-row items-center" key={event?.owner?.id}>
          {event?.owner?.image?.url ? (
            <img
              src={event?.owner?.image?.url}
              className="rounded-full h-3 w-3 mr-2 -mt-1"
              alt=""
            />
          ) : (
            <UserIcon className="rounded-full h-3 w-3 mr-2 -mt-1" />
          )}
          <p className="">{event?.owner?.first_name}</p>
        </div>
        <div className="capitalize">
          {getReleaseCardClass(event?.startDate, event?.endDate)}
        </div>
      </div>
    </div>
  );
}

function EventCardLoader() {
  return (
    <div className={`release-card space-y-2.5 failed`}>
      <p className="inter text-sm h-2 rounded bg-primary-red-lightest animate-pulse w-full font-normal text-primary-gray-600"></p>

      <div className="flex flex-row items-center space-x-1.5 justify-between">
        <div className="flex flex-row items-center">
          <UserIcon className="rounded-full h-3 w-3 mr-2 -mt-1" />

          <p className="inter text-sm h-2 rounded bg-primary-red-lightest animate-pulse w-32 font-normal text-primary-gray-600"></p>
        </div>
        <div className="capitalize">
          <p className="inter text-sm h-2 rounded bg-primary-red-lightest animate-pulse w-20 font-normal text-primary-gray-600"></p>
        </div>
      </div>
    </div>
  );
}

export default EventCard;
export { EventCardLoader };
