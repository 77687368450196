import React from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import Type from "./Type";
import { Link } from "react-router-dom";
const EventDetails = () => {
  return (
    <div>
      <div className="p-6 bg-white flex w-full justify-between items-center">
        <div className="flex">
          <IoArrowBackOutline size={24} />
          <h1 className="text-xl text-red-500 ml-4">CGC Beta</h1>
        </div>
        <Link to="/cgcRoadmap">
          <button className="bg-red-500 text-white px-4 py-2 rounded">
            Done
          </button>
        </Link>
      </div>
      <div className="bg-white mt-2 p-12">
        <div className="flex gap-4 items-center">
          <img src={require("../../../assets/images/hq.png")} />
          <div>
            <h1 className="text-lg text-neutral-800">
              Event Strategy for CGC Beta
            </h1>
            <h1 className="px-2">
              <span className="text-red-500 text-sm mt-2">Q1-2023 </span>
              (Jul-Sep)
            </h1>
          </div>
        </div>
        <p className="text-md text-neutral-800 font-light mt-8">
          Choose the type of events you want to organize this quarter. CGC
          events are supposed to be organized{" "}
          <span className="font-semibold">once a week.</span> Choose a good mix
          of events from different categories to keep your audience engaged.{" "}
        </p>
        <div className="mt-16 flex gap-32 justify-center items-center w-full">
          <div className="flex gap-1">
            <h1 className="text-red-500 font-medium text-2xl">0</h1>
            <h1 className="self-end text-xs font-light text-neutral-800">
              Events Selected
            </h1>
          </div>
          <div className="flex gap-1">
            <h1 className="text-red-500 font-medium text-2xl">0</h1>
            <h1 className="self-end text-xs font-light text-neutral-800">
              Categories Explored
            </h1>
          </div>
        </div>
        <Type />
      </div>
    </div>
  );
};

export default EventDetails;
