import { createSlice } from "@reduxjs/toolkit";

import * as ProjectAPI from "config/APIs/project";

const initialState = {
  list: [],
  typeWiseList: { FWS: [] },
};

// Storing projects
export const project = createSlice({
  name: "project",
  initialState,
  reducers: {
    updateList: (state, action) => {
      state.list = action.payload.list;
      let list = action.payload.list;

      let types = [];

      let temp = {};

      list?.map((i) => {
        if (types?.includes(i?.type)) {
          temp[i.type].push(i);
        } else {
          types.push(i?.type);
          temp[i.type] = [i];
        }
      });
      state.typeWiseList = temp;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateList } = project.actions;

export default project.reducer;

export function fetchAllProjects(type, status, isActive) {
  return async (dispatch) => {
    try {
      const response = await ProjectAPI.findAll({
        is_active: isActive,
        status: "Live",
      });
      let data = response.data.data.response;

      dispatch(updateList({ list: data }));
    } catch (err) {
      console.log(err);
    }
  };
}
