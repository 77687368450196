import { configureStore } from "@reduxjs/toolkit";

import navigationReducer from "./navigation";
import toasterReducer from "./toaster";
import userReducer from "./user";
import tasksReducer from "./task";
import collegeReducer from "./college";
import okrReducer from "./okrs";
import shareablesReducer from "./shareables";
import releasesReducer from "./release";
import sourcingDriveReducer from "./sourcingDrive";
import projectReducer from "./projects";
import goalReducer from "./goals";
import dropdown from "./dropdown";
import tracksReducer from "./tracks";

const store = configureStore({
  reducer: {
    sideNav: navigationReducer,
    toaster: toasterReducer,
    user: userReducer,
    tasks: tasksReducer,
    college: collegeReducer,
    okr: okrReducer,
    shareables: shareablesReducer,
    releases: releasesReducer,
    sourcingDrive: sourcingDriveReducer,
    dropdown: dropdown,
    goals: goalReducer,
    projects: projectReducer,
    tracks: tracksReducer,
  },
});

export default store;
