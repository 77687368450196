function StatCard({ label, count }) {
  return (
    <div className="dsr-stat-card flex flex-row items-center space-x-6">
      <h1 className="count">{count}</h1>
      <p className="stat-name">{label}</p>
    </div>
  );
}

export default StatCard;
