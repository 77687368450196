import { useEffect, useState } from "react";
import CalendarView from "./Calendar";
import RightSidebar from "./RightSidebar";
import { getAllEvents } from "config/APIs/events";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import ChronosButton from "components/Comman/Buttons";
import { showToast } from "redux/toaster";
import { LIVE_ENDPOINT } from "config";

export default function EventsComponent() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.user);
  const [events, setEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const googleSignIn = () => {
    try {
      let redirecturl = `${LIVE_ENDPOINT}auth/google`;

      window.location.replace(redirecturl);
    } catch (e) {
      console.log("Gmail mail Auth Redirect", e);
      dispatch(
        showToast({
          message: e?.response?.data?.message,
          type: "error",
        })
      );
    }
  };

  const fetchAllEvents = async () => {
    try {
      const res = await getAllEvents({ eventsOnly: true });
      setEvents(res?.data?.data);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    fetchAllEvents();
  }, []);

  return (
    <div className="flex items-stretch">
      <div className="w-[calc(100%-300px)] bg-white p-4 rounded-lg">
        <div className="flex flex-row items-center justify-between space-x-2">
          <h1 className="text-black text-2xl font-light">
            All Events for <strong className="font-semibold">Caarya</strong>
          </h1>
          {user?.googleAuth ? (
            <ChronosButton
              tertiary
              text="Google Calendar Synced!"
              onClick={() => {}}
            />
          ) : (
            <ChronosButton
              primary
              text="Sync Google Calendar"
              onClick={() => {
                googleSignIn();
              }}
            />
          )}
        </div>
        <div className="relative mt-8">
          <CalendarView
            events={events}
            fetchAll={fetchAllEvents}
            selectedDate={selectedDate}
          />
        </div>
      </div>
      <RightSidebar
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        eventsToday={events?.filter(
          (e) =>
            moment(e?.startDate) >= moment().startOf("day") &&
            moment(e?.startDate) < moment().add(1, "day").startOf("day")
        )}
        upcomingEvents={events?.filter(
          (e) => moment(e?.startDate) >= moment().add(1, "day").startOf("day")
        )}
      />
    </div>
  );
}
