import React from "react";
import { Redirect } from "react-router-dom";

import SquadUser from "pages/MySquad/SquadUser";
import Tasks from "pages/Tasks/IndividualPage";
import Releases from "pages/Releases";
import Targets from "pages/Targets";
import CGC from "pages/CGCs";
import College from "pages/CGCs/College";
import Today from "pages/Today";
import DailyReports from "pages/Report";

import { checkAppAccess } from "helpers/utils/accessCheck";
import ShortenedUrl from "pages/ShortendUrl";
import Programs from "pages/Programs";
import Events from "pages/Events";
import TalentRequests from "pages/TalentRequests";
import GoalsPage from "pages/Goals/IndividualPage";
import Shareables from "pages/Shareables";
import Reports from "pages/Reports";
import Chronicles from "pages/Chronicles";
import EventPlanner from "pages/EventPlanner";
import EventDetails from "pages/EventPlanner/EventDetails";
import RoadMap from "pages/EventPlanner/RoadMap";
// Authenticated Paths

// Array of routes only a logged in user can access
const privateRoutes = [
  {
    path: "/today",
    component: Today,
  },
  {
    path: "/chronicle",
    component: Chronicles,
  },
  {
    path: "/tasks/:id",
    component: Tasks,
  },
  {
    path: "/home/releases",
    component: Releases,
  },
  {
    path: "/home/targets",
    component: Targets,
  },
  {
    path: "/home/cgc",
    component: CGC,
  },
  {
    path: "/home/shareables",
    component: Shareables,
  },
  {
    path: "/home/reports",
    component: Reports,
  },
  {
    path: "/home/programs",
    component: Programs,
  },
  {
    path: "/home/events",
    component: Events,
  },
  {
    path: "/home/talentRequests",
    component: TalentRequests,
  },
  {
    path: "/college/:id",
    component: College,
  },
  {
    path: "/squadUser/:id",
    component: SquadUser,
  },

  {
    path: "/dailyReports/:report",
    component: DailyReports,
  },
  {
    path: "/:urlType/:code",
    component: ShortenedUrl,
  },
  {
    path: "/goals/:id",
    component: GoalsPage,
  },
  {
    path: "/eventplanner",
    component: EventPlanner,
  },
  {
    path: "/cgcEvent",
    component: EventDetails,
  },
  {
    path: "/cgcRoadmap",
    component: RoadMap,
  },
  {
    path: "/",
    exact: true,
    component: () =>
      localStorage.getItem("token") ? (
        checkAppAccess() ? (
          window.innerWidth < 1024 ? (
            <Redirect to="/today" />
          ) : (
            <Redirect to="/home/programs" />
          )
        ) : (
          <Redirect to="/accessDenied" />
        )
      ) : (
        <Redirect to="/auth/signIn" />
      ),
  },
];

export { privateRoutes };
