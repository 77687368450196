import React from "react";
const stats = [
  {
    label: (
      <>
        <b>Tasks</b> Past Due Date
      </>
    ),
    field: "tasksPastDue",
    statColor: "red",
  },
  {
    label: (
      <>
        <b>Tasks</b> Not Started
      </>
    ),
    field: "tasksNotStarted",
    statColor: "gray",
  },
  {
    label: (
      <>
        <b>Tasks</b> In Progress
      </>
    ),
    field: "tasksInProgress",
    statColor: "yellow",
  },
  {
    label: (
      <>
        <b>Tasks</b> Completed
      </>
    ),
    field: "tasksCompleted",
    statColor: "green",
  },
  {
    label: (
      <>
        <b>Goals</b> Past Due Date
      </>
    ),
    field: "goalsPastDue",
    statColor: "red",
  },
  {
    label: (
      <>
        <b>Goals</b> Not Started
      </>
    ),
    field: "goalsNotStarted",
    statColor: "gray",
  },

  {
    label: (
      <>
        <b>Goals</b> In Progress
      </>
    ),
    field: "goalsInProgress",
    statColor: "yellow",
  },
  // {
  //   label: <>Unresolved Roadblocks</>,
  //   field: "unresolvedRoadblocks",
  //   statColor: "purple",
  // },
  {
    label: <>People with unassigned tasks</>,
    field: "unassignedPeople",
    statColor: "red",
  },
];

function ProjectCard({ project = {} }) {
  return (
    <div className="dsr-project-card w-full space-y-4">
      <div className="flex flex-row items-center justify-between space-x-2.5">
        <h1 className="project-title">{project?.title || "Name"}</h1>
      </div>

      <div className="-mx-1.5 flex flex-row items-center flex-wrap">
        <h1 className="m-1.5 person-label">Owner:</h1>
        {project?.owner ? (
          <div className="m-1.5 person-tag flex flex-row items-center space-x-2">
            <img
              src={
                project?.owner?.image?.url ||
                "/assets/images/icons/defaultLight.svg"
              }
              alt=""
              className="h-4 w-4 rounded-full object-cover"
            />
            <p>{project?.owner?.first_name}</p>
          </div>
        ) : (
          "NA"
        )}
      </div>
      {/* <div className="-mx-1.5 flex flex-row items-center flex-wrap">
        <h1 className="m-1.5 person-label">Members:</h1>
        {project?.members?.map((item) => {
          return (
            <div className="m-1.5 person-tag flex flex-row items-center space-x-2">
              <img
                src={
                  item?.image?.url || "/assets/images/icons/defaultLight.svg"
                }
                alt=""
                className="h-4 w-4 rounded-full object-cover"
              />
              <p>{item?.first_name}</p>
            </div>
          );
        })}
      </div> */}
      <div className="w-full divider" />
      <div className="grid grid-cols-2 gap-4">
        {stats?.map((item) => {
          return (
            <div className="stat flex flex-row items-center space-x-2">
              <h1 className={`number ${item?.statColor}`}>
                {project?.stat[item?.field] || 0}
              </h1>
              <p className="labels">{React.cloneElement(item?.label, {})}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ProjectCard;
