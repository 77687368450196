import { useState, useEffect } from "react";

import EmptyState from "components/Comman/EmptyState";
import * as CollegeAPI from "config/APIs/college";
import CollegeCard, { CollegeCardLoader } from "./Card";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateList, updateTypeWise } from "redux/college";

function CollegeList({ search, selectedType, setShowing, selectedCGC }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const college = useSelector((state) => state.college);
  const [fetching, setFetching] = useState(false);

  const fetchAll = async () => {
    if (college?.list?.length > 0) {
      let live = college?.list?.filter((i) => i?.type == "Live");
      let pipeline = college?.list?.filter((i) => i?.type == "Pipeline");
      let str = `${live?.length} Live and ${pipeline?.length} Pipeline colleges`;
      setShowing(str);
      return;
    }

    setFetching(true);
    try {
      const response = await CollegeAPI.findAll();
      let data = response.data.data.response;

      let live = data?.filter((i) => i?.type == "Live");
      let pipeline = data?.filter((i) => i?.type == "Pipeline");
      let str = `${live?.length} Live and ${pipeline?.length} Pipeline colleges`;
      setShowing(str);
      dispatch(updateList({ list: data }));
      dispatch(updateTypeWise({ list: data }));
    } catch (err) {
      console.log(err);
    }

    setFetching(false);
  };

  useEffect(() => fetchAll(), [college?.typeWiseList]);

  useEffect(() => {
    if (!selectedType?.value) {
      let live = college?.typeWiseList["Live"];
      let pipeline = college?.typeWiseList["Pipeline"];
      let str = `${live?.length} Live and ${pipeline?.length} Pipeline colleges`;
      setShowing(str);
    } else {
      setShowing(
        `${college?.typeWiseList[selectedType?.label]?.length || 0} ${
          selectedType?.label
        } colleges`
      );
    }
  }, [selectedType]);

  return (
    <div className="px-5 space-y-5 flex flex-col w-full overflow-y-auto max-h-70vh pb-20">
      {!fetching ? (
        <>
          {Object.keys(college?.typeWiseList)?.map((i) => {
            if (
              (selectedType?.value !== null && selectedType?.value === i) ||
              selectedType?.value == null
            ) {
              if (i == "Live" || i == "Pipeline") {
                return (
                  <div className="space-y-5">
                    <h1 className="text-sm font-inter text-primary-gray-1000 font-bold px-2.5">
                      {i}
                    </h1>
                    <>
                      {college?.typeWiseList[i]?.length > 0 ? (
                        college?.typeWiseList[i].map((item) => {
                          if (
                            (selectedCGC?.value !== null &&
                              selectedCGC?.value === item?.cgcId) ||
                            selectedCGC?.value == null
                          )
                            if (
                              !search?.searchText ||
                              (search?.searchText &&
                                item?.college_name
                                  ?.toLowerCase()
                                  ?.includes(search?.searchText?.toLowerCase()))
                            )
                              return (
                                <CollegeCard
                                  college={item}
                                  onClick={() => {
                                    history.push(`/college/${item?.id}`);
                                  }}
                                />
                              );
                        })
                      ) : (
                        <EmptyState />
                      )}
                    </>
                  </div>
                );
              }
            }
          })}
        </>
      ) : (
        <>
          {[1, 2, 3, 4, 5, 6].map((item) => {
            return <CollegeCardLoader {...item} />;
          })}
        </>
      )}{" "}
      {/* {!fetching ? (
        list?.length > 0 ? (
          list.map((item) => {
            if (
              !search?.searchText ||
              (search?.searchText &&
                item?.college_name
                  ?.toLowerCase()
                  ?.includes(search?.searchText?.toLowerCase()))
            )
              if (
                (selectedType?.value !== null &&
                  selectedType?.value === item?.type) ||
                selectedType?.value == null
              )
                return (
                  <CollegeCard
                    college={item}
                    onClick={() => {
                      history.push(`/college/${item?.id}`);
                    }}
                  />
                );
          })
        ) : (
          <EmptyState />
        )
      ) : (
        <>
          {[1, 2, 3, 4, 5, 6].map((item) => {
            return <CollegeCardLoader {...item} />;
          })}
        </>
      )} */}
    </div>
  );
}

export default CollegeList;
