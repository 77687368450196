import React from "react";
import { BsExclamationTriangleFill } from "react-icons/bs";
import { Link } from "react-router-dom";
const EventCard = ({ details }) => {
  return (
    <div className="mt-4 ml-4 w-1/3 bg-white p-4 shadow-xl rounded cursor-pointer">
      <Link to="/cgcEvent">
        <div className="flex w-full justify-between items-center">
          <div className="flex flex-col gap-1">
            <h1 className="text-normal text-neutral-800 font-medium">
              {details?.title}
            </h1>
            {details?.status === "not done" && (
              <div className="flex gap-1 items-center">
                <BsExclamationTriangleFill className="text-caarya-red-medium" />
                <p className="text-sm text-caarya-red-light">
                  Event Roadmap Incomplete
                </p>
              </div>
            )}
            {details?.status === "incomplete" && (
              <div className="flex gap-1 items-center">
                <BsExclamationTriangleFill className="text-primary-yellow-medium" />
                <p className="text-sm text-primary-yellow-medium">
                  Event Strategy Not Done
                </p>
              </div>
            )}
          </div>
          <img src={require(`../../assets/images/${details?.logo}`)} />
        </div>
        <div className="flex justify-between items-center mt-8">
          <div>
            <h1 className="text-yellow-400 text-3xl font-semibold">
              {details?.inProgress}
            </h1>
            <h1 className="text-sm text-neutral-800">In Progress</h1>
          </div>
          <div>
            <h1 className="text-red-400 text-3xl font-semibold">
              {details?.ready}
            </h1>
            <h1 className="text-sm text-neutral-800">Ready to Schedule</h1>
          </div>
          <div>
            <h1 className="text-green-400 text-3xl font-semibold">
              {details?.scheduled}
            </h1>
            <h1 className="text-sm text-neutral-800">Events Scheduled</h1>
          </div>
        </div>
        <div className="flex gap-4 mt-6">
          <h1 className="text-sm font-semibold text-neutral-500">
            Last Event:{" "}
          </h1>
          <h1 className="text-sm font-semibold text-neutral-800">
            {details?.last}
          </h1>
          <h1 className="text-sm">{details?.date}</h1>
        </div>
      </Link>
    </div>
  );
};

export default EventCard;
