import React, { useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { BsThreeDotsVertical } from "react-icons/bs";
import { BiEditAlt } from "react-icons/bi";
import { MdDoneAll } from "react-icons/md";
import Weeks from "./Weeks";
const RoadMap = () => {
  const [page, setPage] = useState(0);
  const [edit, setEdit] = useState(true);
  return (
    <div>
      <div className="px-6 pt-6 pb-2 bg-white flex w-full justify-between items-center">
        <div className="flex">
          <IoArrowBackOutline size={24} />
          <h1 className="text-xl text-red-500 ml-4">CGC Beta</h1>
        </div>
      </div>
      <div className={`bg-white mt-2 px-12 pt-12 pb-4 flex justify-center`}>
        <div
          className={`border-b border-1 p-2 mr-3 cursor-pointer ${
            page === 0 ? "border-red-500" : ""
          }`}
          onClick={() => setPage(0)}
        >
          <h1 className={`${page === 0 ? "text-red-500" : ""}`}>
            Event Roadmap
          </h1>
        </div>
        <div className="border-l border-1 border-red-500"></div>
        <div
          className={`border-b border-1 p-2 ml-3 cursor-pointer ${
            page === 1 ? "border-red-500" : ""
          }`}
          onClick={() => setPage(1)}
        >
          <h1 className={`${page === 1 ? "text-red-500" : ""}`}>
            Schedule Events (2)
          </h1>
        </div>
      </div>
      <div className="mt-2 bg-white px-16 py-8">
        <p className="text-neutral-500 text-sm">
          Here you will find a high level plan for events this quarter. You can
          roadmap events at a high level and add details for when required.
        </p>
        <div className="flex justify-between items-center">
          <div className="flex gap-4 items-center mt-6">
            <img src={require("../../../assets/images/hq.png")} />
            <div>
              <h1 className="text-lg text-neutral-800 font-medium">
                Event Strategy for CGC Beta
              </h1>
              <h1 className="px-2 text-sm mt-2">
                <span className="text-red-500  ">Q1-2023 </span>
                (Jul-Sep)
              </h1>
            </div>
          </div>
          {edit ? (
            <div className="p-3 items-center bg-red-500 rounded flex gap-2">
              <MdDoneAll color="white" />
              <h1 className="text-white">Save Roadmap</h1>
            </div>
          ) : (
            <div className="p-2 bg-neutral-200 rounded-full">
              <BsThreeDotsVertical />
            </div>
          )}
        </div>
        {!edit && (
          <div className="mt-12 px-8 flex justify-between">
            <div>
              <h1 className="text-sm text-neutral-600">
                <span className="text-green-700 font-medium text-2xl">0</span>
                /15
              </h1>
              <p className="text-sm text-neutral-600 mt-2">Events Roadmapped</p>
            </div>
            <div>
              <h1 className="text-green-700 font-medium text-2xl">0</h1>
              <p className="text-sm text-neutral-600 mt-2">Ready to Schedule</p>
            </div>
            <div>
              <h1 className="text-green-700 font-medium text-2xl">0</h1>
              <p className="text-sm text-neutral-600 mt-2">In Progress</p>
            </div>
            <div className="flex gap-2 items-center px-2 border border-red-500 rounded-lg">
              <BiEditAlt className="text-red-500" />
              <p className="text-sm text-red-500">Edit Event Roadmap</p>
            </div>
          </div>
        )}
        <Weeks edit={edit} />
      </div>
    </div>
  );
};

export default RoadMap;
