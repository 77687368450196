import React from "react";

const TypeCard = () => {
  return (
    <div className="flex gap-2 p-6 bg-white shadow-lg rounded-lg h-[160px] w-[344px]">
      <img
        src={require("../../../assets/images/ask_caarya.png")}
        className="h-fit w-fit"
      />
      <div className="ml-2">
        <div className="flex justify-between">
          <h1 className="text-neutral-800 font-semibold">#AskCaarya</h1>
          <input type="checkbox" />
        </div>
        <h1 className="text-sm text-neutral-500">
          Event description here lorem ipsum dolor sit amin...
        </h1>
      </div>
    </div>
  );
};

export default TypeCard;
