import React, { useEffect, useRef, useState } from "react";
import { FilePicker } from "react-file-picker";
import { uploadFile, deleteFile } from "config/APIs/files";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";

const ImageSelector = ({
  onSuccess,
  onError,
  onDelete,
  image,
  disabled,
  srOnly,
}) => {
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(image ? true : false);
  const [selectedFile, setSelectedFile] = useState(image || {});
  const [selectedFileSize, setSelectedFileSize] = useState();
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    setUploaded(image ? true : false);
    setSelectedFile(image || {});
  }, [image]);

  const handleError = (err) => {
    dispatch(showToast({ message: err, type: "error" }));

    onError && onError(err);
  };

  const handleFilePicker = async (pickedFile) => {
    if (pickedFile.size > 10485760) {
      handleError("File size cannot exceed 10MB");
      return;
    }
    console.log(pickedFile);
    setUploading(true);
    try {
      const response = await uploadFile({ file: pickedFile });
      const { file } = response.data.data;
      onSuccess(file);
      setSelectedFile(file);
      setSelectedFileSize(pickedFile.size);
      setUploaded(true);
    } catch (err) {
      console.log("File Upload error", err);
      const message = `File Upload Error!`;
      handleError(message);
    }
    setUploading(false);
  };

  const handleDelete = async () => {
    setDeleting(true);
    await deleteFile({ id: selectedFile.id });
    setSelectedFile({});
    setUploaded(false);
    setDeleting(false);
    onDelete();
  };

  const handleFilePickerError = (err) => {
    console.log("File Picker Error", err);
    const message = `Please select an appropriate image file!`;
    handleError(message);
  };

  return (
    <div className="p-4 flex flex-col items-center border border-primary-gray-200 rounded-lg border-dashed">
      {!srOnly && (
        <label htmlFor="Image" className="input-label">
          Image
        </label>
      )}{" "}
      {uploaded ? (
        <>
          <div className="flex flex-col items-start">
            <img
              src={selectedFile.url}
              style={{ width: 100, borderRadius: 10 }}
            />

            {!disabled && (
              <>
                {
                  deleting ? (
                    <div className="red text-2xs font-inter">Deleting...</div>
                  ) : (
                    <div
                      onClick={handleDelete}
                      className="text-red-600 text-2xs cursor-pointer"
                    >
                      Delete Image
                    </div>
                  )
                  // )
                }
              </>
            )}
          </div>
          {selectedFileSize > 1048576 ? (
            <div className="text-xs text-primary-green-medium">
              {"Warning:Image size is >1MB, try compressing it."}
            </div>
          ) : (
            ""
          )}
        </>
      ) : uploading ? (
        <div className="flex flex-row items-center">
          <div className="tertiary-cta font-inter text-xs">Uploading...</div>
        </div>
      ) : (
        <FilePicker
          extensions={["png", "jpeg", "jpg", "svg"]}
          onChange={handleFilePicker}
          onError={handleFilePickerError}
          maxSize={6000}
        >
          <div
            className="flex flex-col items-center space-y-1"
            disabled={disabled}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M1.33399 19.3337C1.33316 17.8609 1.70788 16.4123 2.42272 15.1247C3.13756 13.8371 4.16892 12.7531 5.41932 11.975C5.74933 9.40179 7.00572 7.03701 8.95334 5.32321C10.901 3.60942 13.4064 2.66406 16.0007 2.66406C18.5949 2.66406 21.1003 3.60942 23.048 5.32321C24.9956 7.03701 26.252 9.40179 26.582 11.975C28.133 12.9399 29.3394 14.3702 30.029 16.0616C30.7187 17.7531 30.8563 19.6192 30.4222 21.3935C29.9881 23.1678 29.0045 24.7595 27.6118 25.9415C26.2191 27.1234 24.4886 27.8352 22.6673 27.975L9.33399 28.0003C4.85932 27.635 1.33399 23.8963 1.33399 19.3337ZM22.4647 25.3163C23.7258 25.2194 24.924 24.7264 25.8881 23.9077C26.8523 23.0891 27.5331 21.9867 27.8333 20.758C28.1335 19.5293 28.0377 18.2372 27.5596 17.0662C27.0815 15.8952 26.2456 14.9052 25.1713 14.2377L24.0953 13.567L23.9353 12.311C23.6861 10.3826 22.7432 8.61092 21.2828 7.32712C19.8224 6.04332 17.9444 5.33525 16 5.33525C14.0555 5.33525 12.1776 6.04332 10.7172 7.32712C9.25682 8.61092 8.31389 10.3826 8.06465 12.311L7.90465 13.567L6.83132 14.2377C5.75705 14.9051 4.92116 15.895 4.44305 17.0659C3.96493 18.2368 3.86904 19.5288 4.16906 20.7574C4.46909 21.9861 5.14968 23.0885 6.11365 23.9072C7.07762 24.7259 8.27565 25.2191 9.53665 25.3163L9.76732 25.3337H22.234L22.4647 25.3163ZM17.334 17.3337V22.667H14.6673V17.3337H10.6673L16.0007 10.667L21.334 17.3337H17.334Z"
                fill="#E7E6E5"
              />
            </svg>
            <p className="text-primary-neutral-400 font-semibold text-sm font-lato">
              Upload Image
            </p>
            <p className="text-primary-neutral-400 font-light text-2xs font-lato">
              Supported formats: .JPG, .JPEG, .PNG
            </p>
          </div>
        </FilePicker>
      )}
    </div>
  );
};

export default ImageSelector;
