import ProjectItem, { Skeleton } from "components/Comman/Cards/ProjectCard";
import EmptyState from "components/Comman/EmptyState";
import { useState } from "react";
import { PlusCircle } from "@phosphor-icons/react";
import ProjectCreateEdit from "components/Modals/Project";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { ProjectTypes } from "helpers/projects";

export default function Projects({
  fetching = false,
  projects = [],
  selectedProject,
  setSelectedProject,
  title = "Projects",
  editable,
}) {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const projectTypes = [...new Set(projects.map((project) => project.type))];
  const projectsByType = projectTypes.reduce((acc, type) => {
    return {
      ...acc,
      [type]: projects.filter((project) => project.type === type),
    };
  }, {});
  const [expandProjects, setExpandProjects] = useState(
    projectTypes.reduce((acc, type) => {
      return {
        ...acc,
        [type]: false,
      };
    }, {})
  );
  return (
    <>
      <ProjectCreateEdit
        isOpen={showAddModal}
        closeModal={() => setShowAddModal(false)}
        onCreate={() => {
          setShowAddModal(false);
        }}
      />

      <ProjectCreateEdit
        isOpen={showEditModal}
        closeModal={() => setShowEditModal(false)}
        onUpdate={() => {
          setShowEditModal(false);
        }}
        editValues={showEditModal}
      />
      <div className="w-full h-full bg-white border border-gray-100 shadow-md rounded-md p-4 px-6">
        <div className="flex justify-between items-center">
          <h2 className="font-extrabold text-xl text-black font-inter">
            {title}
          </h2>
          {editable && (
            <button
              onClick={() => setShowAddModal(true)}
              className="flex items-center gap-1 border p-1.5 border-black rounded text-xs text-black"
            >
              <PlusCircle size={12} />
              Add New
            </button>
          )}
        </div>
        <div className="flex flex-col mt-3 gap-6 h-[80vh] overflow-y-auto">
          {!fetching ? (
            projects?.length > 0 ? (
              projectTypes.map((type, i) => {
                return (
                  <div
                    key={i}
                    className="bg-primary-gray-50 p-3 pb-0 rounded-md"
                  >
                    <h2
                      className="font-extrabold text-lg text-[#ce8151] border-b-2 border-gray-200 pb-3 flex justify-between items-center"
                      onClick={() => {
                        setExpandProjects({
                          ...expandProjects,
                          [type]: !expandProjects[type],
                        });
                      }}
                    >
                      {ProjectTypes?.find((p) => p?.value == type)?.label}

                      {!expandProjects[type] ? (
                        <ChevronDownIcon className="w-5 h-5 cursor-pointer" />
                      ) : (
                        <ChevronDownIcon className="w-5 h-5 transform rotate-180 cursor-pointer" />
                      )}
                    </h2>
                    {expandProjects[type] &&
                      projectsByType[type].map((project, i) => {
                        return (
                          <div className={`mb-3 ${i === 0 ? "mt-5" : ""}`}>
                            <ProjectItem
                              key={i}
                              project={project}
                              isSelected={selectedProject?.id === project?.id}
                              onClick={() => setSelectedProject(project)}
                            />
                          </div>
                        );
                      })}
                  </div>
                );
              })
            ) : (
              <EmptyState
                image="/assets/images/empty/tasks.png"
                text="You currently aren't a part of any project!"
              />
            )
          ) : (
            [1, 2, 3, 4, 5].map((_, i) => <Skeleton key={i} />)
          )}
        </div>
      </div>
    </>
  );
}
