import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  list: [],
  business: { thisWeek: [], thisMonth: [], thisQuarter: [] },
  product: { thisWeek: [], thisMonth: [], thisQuarter: [] },
  content: { thisWeek: [], thisMonth: [], thisQuarter: [] },
};

// Storing okrs
export const release = createSlice({
  name: "release",
  initialState,
  reducers: {
    updateList: (state, action) => {
      let type = action.payload.type;
      let time = action.payload.time;
      state[type][time] = action.payload.list;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateList } = release.actions;

export default release.reducer;
