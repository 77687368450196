import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useCallback, useMemo, useState } from "react";
import EventDescription from "./EventDescription";
import WideModalsWrapper from "components/Modals/ModalsWrapper/WideModalWrapper";
import CreateNewEvent from "./CreateNewEvent";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import { BoxedTabs } from "components/Comman/Tabs";

const tabs = [
  { label: "Month", value: "month" },
  { label: "Week", value: "week" },
  { label: "Day", value: "day" },
];

export default function CalendarView({
  events,
  fetchAll,
  setEvents,
  selectedDate,
}) {
  const [showCreateEvent, setShowCreateEvent] = useState(false);
  const [showEventCard, setShowEventCard] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const localizer = momentLocalizer(moment);
  const { components, views } = useMemo(
    () => ({
      views: {
        month: true,
        week: true,
        day: true,
      },
      components: {
        event: EventCard,
        toolbar: ({ label, onNavigate, view, onView }) => {
          return (
            <div className="w-full flex flex-row items-center justify-between space-x-6 pb-5">
              <div className="w-full flex flex-row items-center justify-start space-x-6">
                <p className="text-black font-poppins text-lg font-light leading-6">
                  {label}
                </p>
                <ChevronLeftIcon
                  onClick={() => {
                    onNavigate("PREV");
                  }}
                  className="w-6 cursor-pointer text-primary-gray-350"
                />
                <ChevronRightIcon
                  onClick={() => {
                    onNavigate("NEXT");
                  }}
                  className="w-6 cursor-pointer text-primary-gray-350"
                />
              </div>

              <BoxedTabs
                tabs={tabs}
                selectedTab={view}
                setSelectedTab={(val) => {
                  onView(val);
                }}
              />
            </div>
          );
        },
      },
    }),
    []
  );

  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      setShowCreateEvent({ isOpen: true, dates: { start, end } });
    },
    [setEvents]
  );

  const handleSelectEvent = (event) => {
    const _event = events.find((_event) => _event.id === event.id);
    setSelectedEvent(_event);

    if (_event) {
      setShowEventCard(true);
    }
  };

  const parsedEvents = useMemo(() => {
    return events.map((_event) => ({
      id: _event.id,
      title: _event.title,
      start: new Date(_event.startDate),
      end: _event.endDate
        ? new Date(_event.endDate)
        : new Date(_event.startDate),
    }));
  }, [events]);

  return (
    <>
      <CreateNewEvent
        isOpen={showCreateEvent?.isOpen}
        dates={showCreateEvent?.dates}
        closeModal={() => setShowCreateEvent({})}
        onUpdate={fetchAll}
      />

      <div className="w-full">
        <WideModalsWrapper
          isOpen={showEventCard}
          closeModal={() => setShowEventCard(false)}
          borderRadius="0px"
          height="100vh"
          component={
            <EventDescription
              event={selectedEvent}
              close={() => setShowEventCard(false)}
            />
          }
        />

        <Calendar
          defaultDate={new Date(selectedDate)}
          views={views}
          localizer={localizer}
          events={parsedEvents}
          startAccessor="start"
          endAccessor="end"
          onSelectEvent={handleSelectEvent}
          components={components}
          onSelectSlot={handleSelectSlot}
          selectable
        />
      </div>
    </>
  );
}

function EventCard({ event }) {
  return (
    <span className="">
      <strong>{event?.title}</strong>
      {event?.description && ":  " + event?.description}
    </span>
  );
}
