import { liveInstance } from "config";
import { getHeader } from "config";

export const createReleases = (body) =>
  liveInstance.post(`/releases`, body, getHeader());

export const deleteReleases = (id) =>
  liveInstance.delete(`/releases${id}`, getHeader());

export const getAllReleases = (query) =>
  liveInstance.get(
    `/releases${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );

export const getReleasesyId = (id) =>
  liveInstance.get(`/releases/${id}`, getHeader());

export const updateReleases = (id, body) =>
  liveInstance.patch(`/releases${id}`, body, getHeader());
