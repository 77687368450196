import { useState, useEffect } from "react";
import { AdjustmentsIcon } from "@heroicons/react/outline";
import IconFilter from "components/Comman/Inputs/IconFilter";
import EmptyState from "components/Comman/EmptyState";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { updateList } from "redux/release";
import { getSortedByDate } from "helpers/array";
import { releaseFilter } from "helpers/constants/release";
import * as MarketingAPIs from "config/APIs/marketing";

function ContentRelease({ search }) {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.releases?.content);
  const [fetching, setFetching] = useState(true);
  const [release, setRelease] = useState([]);
  const [query, setQuery] = useState({ time: "thisWeek" });

  const getGoals = async () => {
    setFetching(true);
    if (list[query?.time]?.length > 0) {
      setRelease(list[query?.time]);
      setFetching(false);
      return;
    }
    try {
      console.log(query);
      const response = await MarketingAPIs.getAllEntity(
        "contentOffering",
        query
      );
      let list = response.data.data;

      let tempSorted = getSortedByDate(list, "release_date");

      setRelease(tempSorted);
      dispatch(
        updateList({ list: tempSorted, type: "content", time: query?.time })
      );
    } catch (err) {
      console.log(err);
    }
    setFetching(false);
  };

  useEffect(() => getGoals(), [query]);
  return (
    <>
      <div className="z-20 w-full py-2 pl-8 pr-6 flex flex-row items-center justify-between">
        <h1 className="text-xs font-inter font-normal w-full text-primary-gray-900 flex flex-row items-center">
          Showing <b className="mx-1">{` ${release?.length || 0} releases `}</b>
          {query?.time == "thisMonth"
            ? "this month"
            : query?.time == "thisQuarter"
            ? "this quarter"
            : "this week"}
        </h1>
        <IconFilter
          icon={AdjustmentsIcon}
          list={releaseFilter}
          iconCss="text-black h-4 w-5 transform rotate-90"
          onClick={(val) => {
            setQuery({ ...query, time: val?.value });
          }}
          selected={{ value: query?.time }}
        />
      </div>
      <div className="flow-root overflow-y-auto max-h-65vh px-5">
        <ul role="list" className="">
          {!fetching ? (
            release?.length > 0 ? (
              release?.map((item, idx) => {
                if (
                  !search?.searchText ||
                  (search?.searchText &&
                    item?.name
                      ?.toLowerCase()
                      ?.includes(search?.searchText?.toLowerCase()))
                )
                  return (
                    <li>
                      <div className="relative pb-8">
                        {idx !== release.length - 1 ? (
                          <span
                            className="absolute top-9 left-4 -ml-px h-full w-0.5 border-r border-primary-gray-250"
                            aria-hidden="true"
                          />
                        ) : null}
                        <div className="relative flex space-x-6 w-full">
                          <div>
                            <span className="h-10 w-8 rounded-full flex items-center justify-center py-2 bg-primary-gray-50">
                              <img
                                src={
                                  item?.image?.url ||
                                  "/assets/images/icons/goalGray.svg"
                                }
                                alt=""
                                className={`h-6 w-6 ${
                                  item?.image?.url ? "" : "transform rotate-45"
                                }`}
                              />
                            </span>
                          </div>
                          <div className="min-w-0 flex-1 flex justify-between space-x-4 w-full">
                            <div className="w-full">
                              <div className="w-full flex flex-col md:flex-row items-start md:items-center justify-between">
                                <div
                                  className={`text-sm flex flex-row items-start font-inter cursor-pointer text-primary-gray-600 font-bold w-full break-words`}
                                >
                                  <p>{item?.name}</p>
                                </div>
                              </div>
                              <p
                                className={`text-2xs font-inter cursor-pointer text-primary-gray-350 font-normal`}
                              >
                                {item?.description}
                              </p>
                              {item?.release_date && (
                                <p
                                  className={`block mt-2.5 max-w-max text-xs font-inter cursor-pointer ${
                                    moment(item?.release_date).unix() <
                                    moment().add(7, "days").unix()
                                      ? "text-primary-red-darker font-normal bg-primary-red-lightest"
                                      : "text-primary-indigo-700 font-normal bg-primary-indigo-100"
                                  } px-4 py-1 rounded-full`}
                                >
                                  {moment(item?.release_date).format("ll")}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
              })
            ) : (
              <EmptyState
                text="No content release found!"
                imageMedium
                image="/assets/images/empty/events.svg"
              />
            )
          ) : (
            [1, 2, 3].map((epic, idx) => (
              <li>
                <div className="relative pb-8">
                  {idx !== 2 ? (
                    <span
                      className="absolute top-4 left-4 -ml-px h-full w-0.5 border-r border-color-gray-350"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div className="relative flex space-x-6 w-full">
                    <div>
                      <span className="h-10 w-8 rounded-full flex items-center justify-center py-2 components-tabs-epics-epicsv2-span">
                        <img
                          src="/assets/images/icons/goalGray.svg"
                          alt=""
                          className="h-6 w-6"
                        />
                      </span>
                    </div>
                    <div className="min-w-0 flex-1 flex justify-between space-x-4 w-full">
                      <div className="w-full">
                        <div className="w-full flex flex-row items-center justify-between">
                          <div
                            className={`text-sm w-8/12 h-4 rounded-lg bg-indigo-100 animate-pulse flex flex-row items-center inter cursor-pointer text-primary-gray-600 font-normal`}
                          ></div>
                          <p
                            className={`text-2xs w-2/12 h-4 rounded-lg bg-gray-100 animate-pulse inter cursor-pointer text-primary-gray-600 font-normal`}
                          ></p>
                        </div>
                        <p
                          className={`text-2xs inter cursor-pointer text-primary-gray-350 font-normal space-y-2 mt-2`}
                        >
                          <p
                            className={`text-2xs w-8/12 h-4 rounded-lg bg-gray-100 animate-pulse inter cursor-pointer text-primary-gray-600 font-normal`}
                          ></p>
                          <p
                            className={`text-2xs w-8/12 h-4 rounded-lg bg-gray-100 animate-pulse inter cursor-pointer text-primary-gray-600 font-normal`}
                          ></p>
                          <p
                            className={`text-2xs w-2/12 h-4 rounded-lg bg-gray-100 animate-pulse inter cursor-pointer text-primary-gray-600 font-normal`}
                          ></p>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))
          )}
        </ul>
      </div>
    </>
  );
}

export default ContentRelease;
