function ReportsStoryWrapper({ name, image, bgClass, children }) {
  return (
    <div className={`${bgClass} w-screen h-screen flex flex-col items-start`}>
      <div className="px-6 pb-4 pt-6 flex flex-row items-center space-x-4 bg-white w-full">
        {image && (
          <img
            src={image?.url || image || ""}
            alt=""
            className="w-8 h-8 bg-primary-gray-350 rounded-full object-cover"
          ></img>
        )}
        <h1 className="text-primary-gray-600 leading-4 font-inter font-semibold">
          {name}
        </h1>
      </div>
      <div className="w-full px-4 py-7.5 flex flex-col max-h-90vh">
        <div className="w-full flex flex-col space-y-6 max-h-90vh overflow-y-auto">
          {children}
        </div>
      </div>
    </div>
  );
}

export default ReportsStoryWrapper;
