import React, { useState } from "react";
import AddMembers from "components/Modals/AddMembers";

import * as TaskAPI from "config/APIs/task/task";

// Redux
import { updateTasksList } from "redux/task";
import { showToast } from "redux/toaster";
import { useDispatch } from "react-redux";
import TaskCard from "components/Tasks/Card";
function Tasks({ tasks, onUpdate, afterDelete, selectedTab }) {
  const dispatch = useDispatch();
  const [creating, setCreating] = useState(false);
  const [showAddModal, setShowAddModal] = useState();

  const addMembers = async (id, profiles) => {
    setCreating(true);

    try {
      let body = {
        students: profiles.map((e) => e.id),
      };

      const response = await TaskAPI.updateTasks(id, body);

      onUpdate(id, profiles);
      dispatch(updateTasksList());
      showToast({ message: "Members updated successfully!" });
    } catch (err) {
      switch (err.response?.status) {
        case 401:
          showToast({ message: "Unauthorized!", type: "error" });
          break;
        default:
          showToast({ message: "Something went wrong!", type: "error" });
      }
    }
    setCreating(false);
  };

  return (
    <>
      <AddMembers
        isOpen={showAddModal ? true : null}
        closeModal={() => setShowAddModal()}
        id={showAddModal?.id}
        onUpdate={(id, val) => {
          addMembers(id, val);

          setShowAddModal(null);
        }}
      />
      <div className="space-y-5 mb-5">
        {tasks.map((task, index) => {
          return (
            <TaskCard
              showAssignedBy={selectedTab === "owner"}
              showAssignedTo={selectedTab === "creator"}
              task={task}
              setShowAddModal={(val) => {
                setShowAddModal(val);
              }}
              afterDelete={(val) => afterDelete(val)}
              onUpdate={(id, val, field) => {
                onUpdate(id, val, field);
              }}
              index={index}
            />
          );
        })}
      </div>
    </>
  );
}

export default Tasks;
