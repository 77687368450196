import { classNames } from "helpers/utils/classNames";
import { RadioGroup } from "@headlessui/react";

function RadioInput({
  value,
  setValue,
  list,
  label = "",
  srOnly,
  maxItems = 2,
  showError,
  required = false,
  errorMessage = "Please select one",
}) {
  return (
    <>
      <RadioGroup
        value={value}
        onChange={(e) => {
          console.log(e);
          setValue(e);
          // setItem(e);
        }}
        className="mt-2"
      >
        <RadioGroup.Label className={srOnly ? "sr-only" : ""}>
          {label}
        </RadioGroup.Label>
        <div className={`mt-1.5 grid grid-cols-${maxItems} gap-3`}>
          {list.map((option) => (
            <RadioGroup.Option
              key={option.value}
              value={option?.value}
              className={({ active, checked }) =>
                classNames(
                  active ? "ring-2 ring-offset-2 ring-transparent" : "",
                  option?.value == value
                    ? "bg-secondary-medium border-transparent text-black "
                    : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                  "border rounded-md p-2 flex items-center justify-center text-2xs font-inter font-medium sm:flex-1 cursor-pointer"
                )
              }
            >
              <RadioGroup.Label as="p">{option.label}</RadioGroup.Label>
            </RadioGroup.Option>
          ))}
        </div>
        {showError && (!value || (value && value == "")) && (
          <p className="text-primary-red-dark text-2xs flex flex-row items-center mt-1 ml-0.5">
            {errorMessage}
          </p>
        )}
      </RadioGroup>
    </>
  );
}

export default RadioInput;
