import EmptyState from "components/Comman/EmptyState";
import EventCard, { EventCardLoader } from "components/Release/Card";

function CollegeEvents({ list = [] }) {
  return (
    <div className="px-5">
      <div className="space-y-5 max-h-70vh overflow-y-auto pb-10">
        {list.length > 0 &&
          list?.map((i) => {
            return <EventCard event={i} onCLick={() => {}} />;
          })}
      </div>
      {list?.length == 0 && (
        <EmptyState
          imageMedium
          image="/assets/images/empty/events.svg"
          text="No college events found!"
        />
      )}
    </div>
  );
}

export default CollegeEvents;
