import { getHeader } from "config";
import { instance, getMultipartHeader } from "config";
import { liveInstance } from "..";

export const uploadFile = (file) => {
  const formData = new FormData();
  formData.append("file", file);
  return instance.post(`/file/upload`, formData, getMultipartHeader());
};

export const getAllAccess = (type, query = {}) =>
  instance.get(
    `/admin/accessManagement/${type}${
      Object.keys(query)?.length > 0 ? `?${new URLSearchParams(query)}` : ""
    }`,
    getHeader()
  );

export const getReports = (query) => {
  return liveInstance.get(
    `/stats/reports${
      query && Object.keys(query).length > 0
        ? `?${new URLSearchParams(query)}`
        : ""
    }`,
    getHeader()
  );
};
