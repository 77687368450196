import { useState } from "react";

import SearchBox from "components/Comman/Inputs/SearchBox";
import GoalsList from "components/Targets/GoalsList";

function Targets() {
  const [search, setSearch] = useState({
    searchText: "",
    isSearch: false,
  });

  return (
    <>
      <div className="px-7.5 pt-2.5">
        <SearchBox
          placeholder="Find your target!"
          search={search}
          setSearch={setSearch}
        />
      </div>
      <div className="mt-5 max-h-70vh overflow-y-hidden">
        <GoalsList search={search} />
      </div>
    </>
  );
}

export default Targets;
