import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Calendar } from "@phosphor-icons/react";
function DateInput({ date, onChange }) {
  const [calendarOpen, setCalendarOpen] = useState(false);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          open={calendarOpen}
          label=""
          value={date}
          onChange={(value) => {
            onChange(value);
            setCalendarOpen(false);
          }}
          renderInput={({ inputRef, inputProps }) => (
            <div
              onClick={() => setCalendarOpen(true)}
              className="relative cursor-pointer flex flex-row items-center w-full justify-between theme-input"
            >
              {date ? (
                <input
                  ref={inputRef}
                  {...inputProps}
                  className={`w-full appearance-none border-0 text-primary-gray-1000 font-intertext-sm leading-6 placeholder:text-primary-gray-300 bg-transparent focus:outline-none focus:ring-transparent`}
                ></input>
              ) : (
                <input
                  ref={inputRef}
                  {...inputProps}
                  placeholder="Add Due Date"
                  className={`w-full appearance-none border-0 text-primary-gray-1000 font-intertext-sm leading-6 placeholder:text-primary-gray-300 bg-transparent focus:outline-none focus:ring-transparent`}
                ></input>
              )}
              <Calendar size={24} />
            </div>
          )}
        />
      </LocalizationProvider>
    </>
  );
}

export default DateInput;
