import { useState, useEffect } from "react";

import EmptyState from "components/Comman/EmptyState";
import * as API from "config/APIs/targets";
import GoalCard, { GoalCardLoader } from "./Card";
import ObjectivesModal from "./Modal/ObjectivesModal";
import { useDispatch, useSelector } from "react-redux";
import { updateList } from "redux/okrs";

function GoalsList({ search }) {
  const dispatch = useDispatch();
  const goals = useSelector((state) => state.okr);
  const [fetching, setFetching] = useState(true);
  const [selectedGoal, setSelectedGoal] = useState();
  const [list, setList] = useState([]);

  const fetchAll = async () => {
    setFetching(true);
    if (goals?.list?.length > 0) {
      setList(goals?.list);
      setFetching(false);
      return;
    }

    try {
      const response = await API.getquarterlyGoal("quarterlyGoals", {});
      let data = response.data.data;
      setList(data);
      dispatch(updateList({ list: data }));
    } catch (err) {
      console.log(err);
    }
    setFetching(false);
  };

  useEffect(() => fetchAll(), []);
  return (
    <>
      <div className="px-5 space-y-5 flex flex-col w-full overflow-y-auto max-h-70vh pb-5">
        {!fetching ? (
          list?.length > 0 ? (
            list.map((item) => {
              if (
                !search?.searchText ||
                (search?.searchText &&
                  item?.title
                    ?.toLowerCase()
                    ?.includes(search?.searchText?.toLowerCase()))
              )
                return (
                  <GoalCard
                    goal={item}
                    onClick={() => {
                      setSelectedGoal(item);
                    }}
                  />
                );
            })
          ) : (
            <EmptyState image="/assets/images/empty/goal.png" />
          )
        ) : (
          <>
            {[1, 2, 3, 4, 5, 6].map((item) => {
              return <GoalCardLoader {...item} />;
            })}
          </>
        )}
      </div>

      <ObjectivesModal
        isOpen={selectedGoal ? true : false}
        closeModal={() => {
          setSelectedGoal();
        }}
        goal={selectedGoal}
      />
    </>
  );
}

export default GoalsList;
