import React from "react";
import TypeCard from "./TypeCard";

const Type = () => {
  return (
    <div className="mt-16">
      <img src={require("../../../assets/images/bonding.png")} />
      <div className="flex justify-between mt-4">
        <div className="">
          <h1 className="text-neutral-800 text-lg font-poppins font-medium">
            Team Bonding
          </h1>
          <p className="text-md text-neutral-500">
            It is an ongoing process through which teams become closer and build
            trust and ease of communication.
          </p>
        </div>
        <p className="text-md text-neutral-500 font-medium">0 Selected</p>
      </div>
      <div className="mt-8">
        <TypeCard />
      </div>
    </div>
  );
};

export default Type;
