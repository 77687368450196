import { IoChevronBack } from "react-icons/io5";
import { XIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import { createEvents, deleteEvents, updateEvents } from "config/APIs/events";
import SimpleInput from "components/Comman/Inputs/SimpleInput";
import DropdownInputForObject from "components/Comman/Inputs/DropdownInputForObject";
import { useDispatch, useSelector } from "react-redux";
import SimpleTextArea from "components/Comman/Inputs/SimpleTextArea";
import ChronosButton from "components/Comman/Buttons";
import DateInput from "components/Comman/Inputs/DateInput";
import TimeInput from "components/Comman/Inputs/TimeInput";
import AddMembers from "components/Modals/AddMembers";
import moment from "moment";
import { fetchAllColleges, fetchEventType } from "redux/dropdown";

const Participant = ({ item, onRemove }) => {
  return (
    <div className="flex items-center gap-2 m-1.5 border border-gray-300 rounded-full px-2 py-1">
      <img
        src={
          item?.image?.url ||
          "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=2000"
        }
        alt=""
        className="w-4 h-4 rounded-full"
      />
      <div className="flex justify-between items-center gap-1 w-full">
        <p className="text-xs">{item?.first_name}</p>
        <XIcon
          onClick={() => {
            onRemove();
          }}
          className="w-3 h-3"
        />
      </div>
    </div>
  );
};

export default function CreateNewEvent({
  isOpen,
  closeModal,
  editValues,
  onUpdate,
  dates,
}) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.user);
  const dropdownItems = useSelector((state) => state?.dropdown);
  const [data, setData] = useState({});
  const [showAddMembersModal, setShowAddMembersModal] = useState(false);
  const [creating, setCreating] = useState(false);

  useEffect(() => {
    if (isOpen) {
      if (dates) {
        setData({
          ...data,
          startDate: moment(dates?.start),
          endDate: moment(dates?.end),
        });
        console.log(dates);
      }
      if (dropdownItems?.eventType?.list?.length == 0) {
        dispatch(fetchEventType());
      }
      if (dropdownItems?.colleges?.list?.length == 0) {
        dispatch(fetchAllColleges());
      }
    }
  }, [isOpen]);

  const handleCreateEvent = async () => {
    try {
      let { attendees, ...body } = data;

      body["startDate"] = new Date(data?.startDate);
      // body["endDate"] =
      //   data?.endDate && data?.endDate !== null
      //     ? new Date(data?.enddate)
      //     : new Date(new Date(data?.startDate).getTime() + 60 * 60 * 1000);
      body["endDate"] = new Date(
        new Date(data?.startDate).getTime() + 60 * 60 * 1000
      );

      body["attendees"] = attendees?.map((a) => a?.id);
      setCreating(true);
      const res = await createEvents(body);
      onUpdate();
      closeModal();
      setData({});
    } catch (err) {
      console.log(err.message);
    } finally {
      setCreating(false);
    }
  };
  const handleEditEvent = async () => {
    try {
      const res = await updateEvents();
    } catch (err) {
      console.log(err.message);
    }
  };
  const handleDeleteEvent = async () => {
    try {
      const res = await deleteEvents();
    } catch (err) {
      console.log(err.message);
    }
  };
  return (
    <>
      <AddMembers
        type="members"
        allUsers
        isOpen={showAddMembersModal}
        closeModal={() => setShowAddMembersModal(false)}
        selected={data?.attendees}
        onUpdate={(id, profiles) => {
          setData({ ...data, attendees: profiles });
          setShowAddMembersModal(false);
        }}
      />

      <Drawer
        anchor={window.innerWidth < 1024 ? "bottom" : "right"}
        PaperProps={{
          style: {
            borderRadius:
              window.innerWidth < 1024 ? "20px 20px 0px 0px" : "0px",
            maxHeight: "100vh",
            width: window.innerWidth < 1024 ? "100%" : "560px",
          },
        }}
        open={isOpen}
        onClose={() => {
          closeModal();
          setData({});
        }}
        transitionDuration={250}
      >
        <div className="h-[100vh] overflow-y-auto z-50 bg-white px-8 py-6">
          <div className="relative" onClick={closeModal}>
            <IoChevronBack className="text-xl text-gray-500 cursor-pointer absolute -left-6 top-1" />
            <span className="underline">Back</span>
          </div>
          <div className="mt-4">
            <div className="flex flex-row items-center justify-between">
              <h2 className="text-2xl font-semibold text-black mb-1 mt-4">
                Create New Event
              </h2>
            </div>
            <div className="flex flex-col gap-8">
              <div className="grid md:grid-cols-2 gap-6 mt-4">
                <DateInput
                  date={data?.startDate}
                  onChange={(val) => {
                    setData({ ...data, startDate: val });
                  }}
                />

                <TimeInput
                  value={data?.startDate}
                  onChange={(val) => {
                    setData({ ...data, startDate: val });
                  }}
                />
              </div>
              {/* <DropdownInputForObject
                label="Event For"
                field="type_id"
                details={data}
                setDetails={setData}
                list={
                  dropdownItems?.eventType?.list?.map((r) => ({
                    label: r?.name,
                    value: r?.id,
                  })) || []
                }
              />*/}
              <DropdownInputForObject
                label="Event Category"
                field="category_id"
                details={data}
                setDetails={setData}
                list={
                  dropdownItems?.eventCategory?.list
                    ?.filter?.(
                      (event) =>
                        !event?.name?.toLowerCase()?.includes("release")
                    )
                    ?.map((r) => ({
                      label: r?.name,
                      value: r?.id,
                    })) || []
                }
              />
              <DropdownInputForObject
                label="Event Type"
                field="type_id"
                details={data}
                setDetails={setData}
                list={
                  dropdownItems?.eventType?.list
                    ?.filter?.(
                      (event) =>
                        !event?.categories?.name
                          ?.toLowerCase()
                          ?.includes("release") &&
                        event?.categories?.id == data?.category_id
                    )
                    ?.map((r) => ({
                      label: r?.name,
                      value: r?.id,
                    })) || []
                }
              />
              <SimpleInput
                label="Event Title"
                details={data}
                setDetails={setData}
                field="title"
              />
              <SimpleTextArea
                label="Event Description"
                details={data}
                setDetails={setData}
                field="description"
              />
              <div className="flex flex-row items-center justify-between">
                <div className="relative">
                  <label for="" className="input-label">
                    Invite Only
                  </label>
                  <div className="mt-1">
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        checked={data?.privacy == "Private" ? true : false}
                        onChange={(e) => {
                          setData({
                            ...data,
                            privacy:
                              data?.privacy == "Private" ? "Public" : "Private",
                          });
                        }}
                        type="checkbox"
                        value=""
                        className="sr-only peer"
                      />
                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none  rounded-full peer peer-checked:after:translate-x-full peer-checked:after:bg-green-400 peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#E9FCE8] border peer-checked:border-green-400"></div>
                    </label>
                  </div>
                </div>
                {user?.googleAuth && (
                  <div className="relative">
                    <label for="" className="input-label">
                      Sync with Google
                    </label>
                    <div className="mt-1">
                      <label className="relative inline-flex items-center cursor-pointer">
                        <input
                          checked={data?.googleSync}
                          onChange={(e) => {
                            setData({
                              ...data,
                              googleSync:
                                data?.googleSync == false || !data?.googleSync
                                  ? true
                                  : false,
                            });
                          }}
                          type="checkbox"
                          value=""
                          className="sr-only peer"
                        />
                        <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none  rounded-full peer peer-checked:after:translate-x-full peer-checked:after:bg-green-400 peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#E9FCE8] border peer-checked:border-green-400"></div>
                      </label>
                    </div>
                  </div>
                )}
              </div>
              <div className="relative">
                <label for="" className="input-label">
                  College Specific
                </label>
                <div className="mt-1">
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      checked={
                        data?.college == null || !data?.college ? false : true
                      }
                      onChange={(e) => {
                        setData({
                          ...data,
                          college:
                            data?.college == null || !data?.college ? {} : null,
                        });
                      }}
                      type="checkbox"
                      value=""
                      className="sr-only peer"
                    />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none  rounded-full peer peer-checked:after:translate-x-full peer-checked:after:bg-green-400 peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#E9FCE8] border peer-checked:border-green-400"></div>
                  </label>
                </div>
              </div>
              {data?.college && (
                <DropdownInputForObject
                  label="College"
                  field="college_id"
                  details={data}
                  setDetails={setData}
                  list={
                    dropdownItems?.colleges?.list?.map((r) => ({
                      label: r?.college_name,
                      value: r?.id,
                    })) || []
                  }
                />
              )}
              <div className="relative">
                <div className="flex gap-4 text-xs font-bold">
                  <span className="input-label">Participants:</span>
                  <span
                    onClick={() => {
                      setShowAddMembersModal(true);
                    }}
                  >
                    <span className="text-red-500"> +</span>
                    <span className="text-red-500 underline ml-1">
                      Add Participants
                    </span>
                  </span>
                </div>
                <div className="flex border-b border-color-gray-200 py-3 flex-wrap flex-row items-center px-4">
                  {data?.attendees?.length > 0 ? (
                    data?.attendees?.map((a) => {
                      return (
                        <Participant
                          item={a}
                          onRemove={() => {
                            let temp = data?.attendees?.filter(
                              (s) => s?.id != a?.id
                            );
                            setData({ ...data, attendees: temp });
                          }}
                        />
                      );
                    })
                  ) : (
                    <p className="text-xs text-primary-gray-400 font-inter">
                      No attendees added
                    </p>
                  )}
                </div>
              </div>
              <SimpleInput
                label="Event Location"
                details={data}
                setDetails={setData}
                field="location"
              />
              <SimpleInput
                label="Event Link"
                details={data}
                setDetails={setData}
                field="link"
              />
              {editValues ? (
                <div className="flex justify-between items-center gap-6">
                  <ChronosButton
                    text="Delete"
                    tertiary
                    red
                    onClick={() => {
                      handleDeleteEvent();
                    }}
                  />{" "}
                  <button className="p-3 px-4 text-caarya-red-dark  font-inter text-sm ">
                    Delete
                  </button>
                  <ChronosButton
                    text="Save Event"
                    primary
                    onClick={() => {
                      handleEditEvent();
                    }}
                  />
                </div>
              ) : (
                <div className="grid place-items-center py-6 ">
                  <ChronosButton
                    loader={creating}
                    text="Add Event"
                    primary
                    onClick={() => {
                      handleCreateEvent();
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}
