import EventItem from "./EventItem";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState } from "react";
import dayjs from "dayjs";
import { CalendarPicker } from "@mui/x-date-pickers/CalendarPicker";
import EmptyState from "components/Comman/EmptyState";

const isWeekend = (date) => {
  const day = date.day();

  return day === 0 || day === 6;
};
export default function RightSidebar({
  eventsToday,
  upcomingEvents,
  selectedDate,
  setSelectedDate,
}) {
  return (
    <div className="absolute right-0 top-0 p-4 w-[300px] h-[100vh] overflow-y-scroll bg-white">
      <div className="max-w-[300px]">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <CalendarPicker
            date={dayjs(selectedDate)}
            onChange={(newDate) => setSelectedDate(newDate)}
            paperprops={{
              sx: {
                "& .MuiPickersDay-root": {
                  "&.Mui-selected": {
                    backgroundcolor: "red",
                  },
                },
              },
            }}
          />
        </LocalizationProvider>
      </div>
      <h2 className="font-semibold text-base mb-3">Events Today</h2>
      <div className="flex flex-col gap-3">
        {eventsToday?.length > 0 ? (
          eventsToday?.map((event) => {
            return <EventItem item={event} />;
          })
        ) : (
          <EmptyState
            text="There are no events for today"
            image="/assets/images/empty/events.svg"
          />
        )}
      </div>
      <h2 className="font-semibold text-base my-3">Upcoming Events</h2>
      <div className="flex flex-col gap-3">
        {upcomingEvents?.length > 0 ? (
          upcomingEvents?.map((event) => {
            return <EventItem item={event} />;
          })
        ) : (
          <EmptyState
            text="There are no upcoming events"
            image="/assets/images/empty/events.svg"
          />
        )}
      </div>
    </div>
  );
}
