import {
  Calendar,
  Clock,
  Crosshair,
  Dot,
  DotsThreeOutlineVertical,
  Fire,
  List,
  Notebook,
  Siren,
  TShirt,
  Timer,
  Warning,
} from "@phosphor-icons/react";
import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { getTotalTime } from "helpers/utils/common/clock";
import { goalFocusTime } from "helpers/constants/goals";
import StatusDropDown from "components/Tasks/Card/StatusDropDown";
import { getGoalPriority, getImpUrgent } from "helpers/utils/goal";
import { updateGoal } from "config/APIs/task/goal";
import { TRACK_CATEGORY, deafultTracks } from "helpers/constants/tracks";

function GoalHubCard({ item, onUpdate, showFocus, plannerMode, type, tab }) {
  const user = useSelector((state) => state?.user?.user);
  const [focusTime, setFocusTime] = useState();
  const [status, setStatus] = useState();
  const [important, setImportant] = useState(false);
  const [urgent, setUrgent] = useState(false);

  useEffect(() => {
    setStatus(item?.status);

    setImportant(getImpUrgent(item?.priority)?.important);
    setUrgent(getImpUrgent(item?.priority)?.urgent);
    setFocusTime(item?.focusIn);
  }, [item]);

  const handleUpdate = async ({ status, imp, ur, focus }) => {
    try {
      let body = {};
      if (status) body["status"] = status;
      if (focus) body["focusIn"] = focus;
      if (imp || ur) body["priority"] = getGoalPriority(imp, ur);

      await updateGoal(item?.id, body);
      onUpdate();
    } catch (err) {
      console.log("Error", err);
      console.log(err?.response);
    }
  };

  return (
    <div
      className="rounded-lg bg-opacity-5"
      style={{
        backgroundColor:
          TRACK_CATEGORY?.find((a) => a?.label == item?.track?.category)
            ?.color || "#fff",
      }}
    >
      <div className="border relative border-primary-gray-200 rounded-tl-[48px] rounded-b-lg rounded-tr-lg bg-white shadow-md p-4 space-y-4">
        <div className="flex flex-row items-center justify-between">
          {item?.track ? (
            <div className="flex flex-row items-center space-x-1 text-primary-gray-300 text-3xs font-lato">
              <Dot
                weight="fill"
                size={10}
                className="text-caarya-red-lighter"
              />
              <p className="font-semibold">{item?.track?.category} / </p>
              <p className="font-light">{item?.track?.title}</p>
            </div>
          ) : (
            <div className="flex flex-row items-center space-x-1 text-primary-gray-300 text-3xs font-lato">
              <p className="font-light">No Track</p>
            </div>
          )}
          <div className="flex flex-row items-center space-x-2">
            {/* <div className="px-1 py-1 rounded border border-primary-gray-200 text-primary-gray-350 text-3xs font-lato font-semibold">
            {GoalStatus?.find((g) => g?.value == item?.status)?.label}
          </div> */}
            <StatusDropDown
              disabled
              status={status}
              setStatus={(val) => {
                handleUpdate({ status: val });
                setStatus(val);
              }}
            />

            <div className="flex flex-row items-center space-x-1 px-1 py-1 rounded border border-primary-yellow-medium text-primary-yellow-medium text-3xs font-lato font-semibold">
              <Fire size={12} />
              <p className="">P{item?.priority || "?"}</p>
            </div>
            {/* <Menu as="div" className="relative block text-left">
              <Menu.Button className="">
                <DotsThreeOutlineVertical size={16} weight="fill" />
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  style={{ borderRadius: "20px 0px 20px 20px" }}
                  className={`origin-top-right absolute right-0 w-24 z-10 shadow-lg bg-white ring-1 ring-primary-gray-1000 ring-opacity-5 focus:outline-none `}
                >
                  <div className="py-1 max-h-64 overflow-y-auto relative z-50">
                    {[1].map((item) => {
                      return (
                        <Menu.Item>
                          {({ active }) => (
                            <div className="text-primary-gray-1000 font-lato font-normal block px-4 py-2 text-2xs cursor-pointer">
                              Edit
                            </div>
                          )}
                        </Menu.Item>
                      );
                    })}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu> */}
          </div>
        </div>
        <div className="flex flex-col items-start space-y-2 px-2">
          <h1 className="text-primary-gray-300 font-lato text-xs font-semibold">
            {item?.title}
          </h1>
          <p className="text-primary-gray-300 font-lato text-xs font-light">
            {item?.description}
          </p>
        </div>
        <div className="flex flex-row items-center space-x-2 px-2">
          {item?.project?.image ? (
            <img
              src={item?.project?.image?.url}
              className="bg-primary-gray-100 rounded w-3 h-3 object-contain"
            />
          ) : (
            <div className="bg-primary-gray-100 rounded w-3 h-3" />
          )}
          <p className="text-primary-gray-300 text-2xs font-lato font-light">
            {item?.project?.title}
          </p>
        </div>

        <div className="flex flex-row justify-between items-center space-x-2 px-2">
          <div className="px-2 py-1 flex flex-row items-center space-x-2 rounded border border-primary-gray-200 text-primary-gray-600 text-2xs font-lato font-light">
            {tab == "clockedIn" ? (
              <>
                {" "}
                <Timer size={16} color="#816FE9" />
                <p>
                  Total:{" "}
                  <span className="font-medium">
                    {getTotalTime(item?.sessions)}
                  </span>
                </p>
              </>
            ) : (
              <p>
                Due:{" "}
                <span className="font-medium">
                  {moment(item?.dueDate).format("ll")}
                </span>
              </p>
            )}
          </div>{" "}
          <div className="px-2 py-1 flex flex-row items-center space-x-2 rounded border border-primary-gray-200 text-primary-gray-600 text-2xs font-lato font-light">
            {item?.owner ? (
              <>
                {" "}
                <img
                  src={item?.owner?.image?.url}
                  alt=""
                  className="w-4 h-4 rounded-full bg-primary-gray-100"
                />
                <p>{item?.owner?.first_name}</p>
              </>
            ) : (
              <p>No owner assigned</p>
            )}{" "}
            {/* <Notebook color="#ABE085" size={16} />
            <p>
              Journal{" "}
              <span className="font-medium">
                {item?.sessions?.filter((a) => a?.journal?.length > 0)
                  ?.length || 0}
              </span>
            </p> */}
          </div>
        </div>

        {type == "clockedIn" && (
          <div className="flex flex-row items-center justify-between space-x-4">
            <div className="w-full bg-secondary-indigo-30 py-3 px-5 flex flex-row items-center space-x-2 text-primary-indigo-500 font-lato text-xs font-semibold">
              <Clock size={16} />
              <p>Clocked In</p>
            </div>
            {/* <div className="flex flex-col items-start">
              <h1 className="text-primary-neutral-500 font-lato font-semibold text-2xs">
                Session:
              </h1>
              <p className="text-primary-gray-800 font-lato text-xs font-light"></p>
            </div> */}
          </div>
        )}

        {type && type !== "week" && (
          <div className="flex flex-row items-center space-x-2 px-2 justify-between">
            <div className="py-1 flex flex-row items-center space-x-2 rounded text-primary-gray-280 text-xs font-lato font-light">
              <Calendar size={16} />
              <p>{moment(item?.dueDate).format("ll")}</p>
            </div>
            <div className="py-1 flex flex-row items-center space-x-2 rounded text-primary-gray-280 text-xs font-lato font-light">
              <TShirt size={16} />
              <p>{item?.metaData?.hours} h</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default GoalHubCard;
