import { QuestionMarkCircleIcon } from "@heroicons/react/outline";
import React from "react";
import TaskCard from "./TaskCard";

function TaskWrapperCard({ project, user, task, goal }) {
  return (
    <div className="dsr-task-wrapper-card w-full flex flex-col items-stretch space-y-2">
      <div className="flex flex-row items-center space-x-4">
        {goal && goal?.id == 0 ? (
          <QuestionMarkCircleIcon className="text-primary-gray-300 h-8 w-8" />
        ) : (
          <img
            src={
              project?.image?.url ||
              user?.image?.url ||
              goal?.image ||
              "/assets/images/icons/defaultLight.svg"
            }
            alt=""
            className="h-8 w-8 rounded-full object-cover"
          />
        )}
        <div className="info flex flex-col items-start space-y-2">
          <h1 className="">
            {project?.title || user?.first_name || goal?.title || "Name"}
          </h1>
          {!goal && (
            <p className="">
              {project?.type || user?.designation?.role?.role_name || "Role"}
            </p>
          )}
        </div>
      </div>
      <TaskCard task={task} />
    </div>
  );
}

export default TaskWrapperCard;
