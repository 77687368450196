import React from "react";
import moment from "moment";
import { TaskStatus } from "helpers/task";

function TaskCard({ task }) {
  return (
    <div className="dsr-task-card w-full space-y-4">
      <div className="flex flex-row items-center justify-between space-x-2.5">
        <h1 className="task-title">{task?.title || "Name"}</h1>
        <div className={`task-priority priority-${task?.priority || "6"}`}>
          <p>{task?.priority || "P?"}</p>
        </div>
      </div>
      <div className="w-full divider" />
      <div className="flex flex-row items-stretch justify-between space-x-4">
        <div className="flex flex-col items-center space-y-2">
          <div className="flex flex-row items-center task-date">
            Due: <b> {moment(task?.date).format("ll")}</b>
          </div>
          <div
            className={`due ${
              moment(task?.date) === moment().add(1, "day")
                ? "yellow"
                : moment(task?.date).unix() > moment().unix()
                ? "green"
                : "red"
            }`}
          >
            {moment(task?.date) === moment().add(1, "day")
              ? "Tomorrow"
              : moment(task?.date) === moment()
              ? "Today"
              : moment(task?.date).unix() > moment().unix()
              ? `${moment(task?.date).diff(moment(), "days")} Days`
              : `${moment().diff(moment(task?.date), "days")} Days`}
          </div>
        </div>
        <div className="flex flex-col items-center space-y-2 task-status">
          <div className="flex flex-row items-center label">Status</div>
          <div className={`status status-${task?.status || "NotStarted"}`}>
            {TaskStatus?.find((i) => i?.value == task?.status)?.label}
          </div>
        </div>
      </div>
      <div className="-mx-1.5 flex flex-row items-center flex-wrap">
        <h1 className="m-1.5 person-label">Owner:</h1>

        <div className="m-1.5 person-tag flex flex-row items-center space-x-2">
          <img
            src={
              task?.owner?.image?.url || "/assets/images/icons/defaultLight.svg"
            }
            alt=""
            className="h-4 w-4 rounded-full object-cover"
          />
          <p>{task?.owner?.first_name}</p>
        </div>
      </div>
      <div className="-mx-1.5 flex flex-row items-center flex-wrap">
        <h1 className="m-1.5 person-label">Collaborators:</h1>
        {task?.students?.map((item) => {
          return (
            <div className="m-1.5 person-tag flex flex-row items-center space-x-2">
              <img
                src={
                  item?.image?.url || "/assets/images/icons/defaultLight.svg"
                }
                alt=""
                className="h-4 w-4 rounded-full object-cover"
              />
              <p>{item?.first_name}</p>
            </div>
          );
        })}
        {task?.students?.length == 0 && (
          <div className="m-1.5 person-tag flex flex-row items-center space-x-2">
            <img
              src={"/assets/images/icons/defaultLight.svg"}
              alt=""
              className="h-4 w-4 rounded-full object-cover"
            />
            <p>No Collaborators</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default TaskCard;
