import { Clock, Link, MapPin } from "@phosphor-icons/react";
import moment from "moment/moment";
import { IoCloseCircleOutline } from "react-icons/io5";
export default function EventDescription({ event, closeModal }) {
  const { title, startDate, endDate, location, link, description, attendees } =
    event;

  return (
    <div className="bg-white m-auto p-6 z-50 rounded-lg shadow-3xl w-[400px] relative">
      <IoCloseCircleOutline
        onClick={closeModal}
        className="text-2xl text-gray-500 cursor-pointer absolute top-4 right-4"
      />
      <div className="space-y-8 py-6">
        <div className="space-y-4">
          <h3 className="text-xl font-poppins font-semibold text-black text-left">
            {title}
          </h3>{" "}
          <div className="flex flex-col space-y-4 pl-6">
            <div className="flex text-xs items-center gap-1 text-gray-500">
              <Clock size={12} />
              <span>
                {moment(startDate).format("ddd, hA")}{" "}
                {endDate && `to ${moment(endDate).format("ddd, hA")}`}
              </span>
            </div>
            <div className="flex text-xs items-center gap-1 text-gray-500">
              <MapPin size={12} />
              <span>{location}</span>
            </div>
            <div className="flex text-xs items-center gap-1 text-gray-500">
              <Link size={12} />
              <span>{link}</span>
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center w-full">
          <div className="flex justify-start items-center space-x-4">
            <img
              className="w-12 h-12 rounded-lg"
              src="https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=2000"
              alt=""
            />{" "}
            <div className="flex flex-col items-start">
              <p className="text-lg">{event?.type?.name}</p>
              <p className="text-sm text-gray-500">
                {event?.type?.categories?.name}
              </p>
            </div>
          </div>
          <div className="border border-[#F57D34] text-[#F57D34] bg-[#FFF1EB] px-4 py-2 rounded-full text-sm">
            FWS Alpha
          </div>
        </div>
        <div className="text-xs text-left">{description}</div>
        <div className="flex flex-row items-start space-x-4">
          <span className="text-gray-500 font-bold">Participants:</span>
          <div className="flex flex-col space-y-4 text-primary-gray-600 items-start">
            <div className="flex -space-x-1.5">
              {attendees?.length > 0
                ? attendees?.slice(0, 4)?.map((a) => {
                    return (
                      <img
                        className="w-6 h-6 rounded-full"
                        src={
                          a?.image?.url ||
                          "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=2000"
                        }
                        alt=""
                      />
                    );
                  })
                : "NA"}

              {attendees?.length > 4 && (
                <div className="text-gray-500 text-sm pl-4">
                  +{attendees?.length - 4}
                </div>
              )}
            </div>
            <p className="text-sm font-light">
              {
                attendees?.filter((a) => a?.user_events?.rsvp == "Accepted")
                  .length
              }{" "}
              Attending
            </p>
            <p className="text-sm font-light">
              {
                attendees?.filter((a) => a?.user_events?.rsvp == "Pending")
                  .length
              }{" "}
              Not responded yet
            </p>
            <div className="text-sm">
              <a href="#" className="underline text-[#F57D34]">
                See details
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
