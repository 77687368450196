import { useState, useEffect } from "react";
import * as CgcAPI from "config/APIs/cgc";
import { AdjustmentsIcon } from "@heroicons/react/outline";
import IconFilter from "components/Comman/Inputs/IconFilter";
import SearchBox from "components/Comman/Inputs/SearchBox";
import CollegeList from "components/CGC/CollegeList";
import { CollegeTypes } from "helpers/constants/colleges";
import { updateCGCList } from "redux/college";
import { useDispatch, useSelector } from "react-redux";
import { Funnel } from "@phosphor-icons/react";

function CGC() {
  const dispatch = useDispatch();
  const cgc = useSelector((state) => state.college.cgc);
  const [selectedType, setSelectedType] = useState({
    label: "All Colleges",
    value: null,
  });
  const [selectedCGC, setSelectedCGC] = useState({
    label: "All CGC",
    value: null,
  });
  const [search, setSearch] = useState({
    searchText: "",
    isSearch: false,
  });
  const [showing, setShowing] = useState("");
  const fetchAll = async () => {
    try {
      const response = await CgcAPI.findAll();
      let data = response.data.data.response;

      dispatch(updateCGCList({ list: data }));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (cgc?.length == 0) fetchAll();
  }, []);

  return (
    <>
      <div className="px-7.5 pt-2.5 flex flex-row items-center space-x-2">
        <SearchBox
          placeholder="Find a CGC!"
          search={search}
          setSearch={setSearch}
        />
        <IconFilter
          icon={Funnel}
          list={cgc
            ?.map((i) => ({ label: i?.name, value: i?.id }))
            ?.concat([
              {
                label: "All CGC",
                value: null,
              },
            ])
            .reverse()}
          iconCss="text-black h-4 w-5"
          onClick={(val) => {
            setSelectedCGC(val);
          }}
          selected={selectedCGC}
        />
      </div>

      <div className="z-20 w-full py-2 pl-8 pr-6 flex flex-row items-center justify-between">
        <h1 className="text-xs font-inter font-normal w-full text-primary-gray-900 flex flex-row items-center">
          Showing <p className="font-bold ml-1">{showing}</p>
        </h1>
        <IconFilter
          icon={AdjustmentsIcon}
          list={[...CollegeTypes]
            ?.concat([
              {
                label: "All Colleges",
                value: null,
              },
            ])
            .reverse()}
          iconCss="text-black h-4 w-5 transform rotate-90"
          onClick={(val) => {
            setSelectedType(val);
          }}
          selected={selectedType}
        />
      </div>

      <div className="max-h-70vh overflow-x-hidden">
        <CollegeList
          search={search}
          selectedType={selectedType}
          selectedCGC={selectedCGC}
          setShowing={setShowing}
        />
      </div>
    </>
  );
}

export default CGC;
