import { useState, useEffect } from "react";

import SearchBox from "components/Comman/Inputs/SearchBox";
import BusinessRelease from "components/Release/BusinessRelease";
import ProductRelease from "components/Release/ProductRelease";
import ContentRelease from "components/Release/ContentRelease";
import Tabs from "components/Comman/Tabs";

const tabs = [
  { label: "Product", value: "product" },
  { label: "Business", value: "business" },
  { label: "Campaign", value: "content" },
];
function Releases() {
  const [search, setSearch] = useState({
    placeholder: tabs[0]?.placeholder,
    searchText: "",
    isSearch: false,
  });
  const [selectedTab, setSelectedTab] = useState("product");
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setSearch({
        placeholder: tabs?.find((i) => i?.value == selectedTab)?.placeholder,
        isSearch: false,
        searchText: "",
      });
    }

    return () => {
      isMounted = false;
    };
  }, [selectedTab]);
  return (
    <>
      <div className="px-7.5 pt-2.5">
        <SearchBox
          placeholder="Start typing to find...."
          search={search}
          setSearch={setSearch}
        />
      </div>

      <Tabs
        tabs={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
      <div className="max-h-70vh overflow-y-hidden">
        {selectedTab == "product" && <ProductRelease search={search} />}
        {selectedTab == "business" && <BusinessRelease search={search} />}
        {selectedTab == "content" && <ContentRelease search={search} />}
      </div>
      {/* <div className="mt-5 max-h-70vh overflow-y-hidden">
        <ReleaseList search={search} />
      </div> */}
    </>
  );
}

export default Releases;
