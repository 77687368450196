import React from "react";
const stats = [
  {
    label: (
      <>
        <b>Tasks</b> Past Due Date
      </>
    ),
    field: "tasksPastDue",
    statColor: "red",
  },
  {
    label: (
      <>
        <b>Tasks</b> Not Started
      </>
    ),
    field: "tasksNotStarted",
    statColor: "gray",
  },
  {
    label: (
      <>
        <b>Tasks</b> In Progress
      </>
    ),
    field: "tasksInProgress",
    statColor: "yellow",
  },
  {
    label: (
      <>
        <b>Tasks</b> Completed
      </>
    ),
    field: "tasksCompleted",
    statColor: "green",
  },
  {
    label: (
      <>
        <b>Goals</b> Past Due Date
      </>
    ),
    field: "goalsPastDue",
    statColor: "red",
  },
  {
    label: (
      <>
        <b>Goals</b> Not Started
      </>
    ),
    field: "goalsNotStarted",
    statColor: "gray",
  },

  {
    label: (
      <>
        <b>Goals</b> In Progress
      </>
    ),
    field: "goalsInProgress",
    statColor: "yellow",
  },
];

function UserCard({ user = {} }) {
  return (
    <div className="dsr-user-card w-full space-y-4">
      <div className="flex flex-row items-start justify-start space-x-2.5">
        <img
          src={user?.image?.url || "/assets/images/icons/defaultLight.svg"}
          alt=""
          className="h-8 w-8 mt-1.5 rounded-full object-cover"
        />
        <div className="flex flex-col items-start">
          <h1 className="user-title">
            {user?.first_name || "Name"}
            {user?.last_name ? ` ${user?.last_name}` : ""}
          </h1>
          <h1 className="user-subtitle">
            {user?.designation?.role?.role_name || "Name"}
          </h1>
        </div>
      </div>

      {user?.projects && user?.projects?.length > 0 && (
        <div className="-mx-1.5 flex flex-row items-center flex-wrap">
          <h1 className="m-1.5 person-label">Projects:</h1>
          {user?.projects?.map((item) => {
            return (
              <div className="m-1.5 person-tag flex flex-row items-center space-x-2">
                <img
                  src={
                    item?.image?.url || "/assets/images/icons/defaultLight.svg"
                  }
                  alt=""
                  className="h-4 w-4 rounded-full object-cover"
                />
                <p>{item?.title}</p>
              </div>
            );
          })}
        </div>
      )}
      {/* <div className="-mx-1.5 flex flex-row items-center flex-wrap">
        <h1 className="m-1.5 person-label">Members:</h1>
        {project?.members?.map((item) => {
          return (
            <div className="m-1.5 person-tag flex flex-row items-center space-x-2">
              <img
                src={
                  item?.image?.url || "/assets/images/icons/defaultLight.svg"
                }
                alt=""
                className="h-4 w-4 rounded-full object-cover"
              />
              <p>{item?.first_name}</p>
            </div>
          );
        })}
      </div> */}
      {user?.stat && (
        <>
          <div className="w-full divider" />
          <div className="grid grid-cols-2 gap-4">
            {stats?.map((item) => {
              return (
                <div className="stat flex flex-row items-center space-x-2">
                  <h1 className={`number ${item?.statColor}`}>
                    {user?.stat[item?.field] || 0}
                  </h1>
                  <p className="labels">
                    {React.cloneElement(item?.label, {})}
                  </p>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}

export default UserCard;
