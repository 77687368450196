import { ShareIcon } from "@heroicons/react/solid";
import { create, findAll } from "config/APIs/shortUrls";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import StoryPreview from "./index";

function ReportsStory({ selectedReport }) {
  const dispatch = useDispatch();
  const createUrl = async () => {
    let response = await findAll({ type: true });
    let data = response.data.data?.response;
    let type = data?.find((i) => i?.name?.toLowerCase()?.includes("locker"));
    try {
      let response = await create({
        title: `Reports for ${selectedReport?.name}`,
        description: `Reports for ${selectedReport?.name}`,
        long_url: window.location.href,
        type_id: type?.id,
        baseUrl: window.location.origin,
        url_code:
          selectedReport?.report == "project"
            ? `daily-projects-${selectedReport?.type}`
            : selectedReport?.report == "user"
            ? `daily-users-${selectedReport?.rankName}`
            : selectedReport?.report == "userStory"
            ? `user-${selectedReport?.id}`
            : selectedReport?.report == "projectStory"
            ? `project-${selectedReport?.id}`
            : selectedReport?.report == "projectGoal"
            ? `project-goals`
            : "",
      });
      if (response?.status == 200) {
        let data = response.data.data;
        return data?.short_url;
      } else {
        dispatch(
          showToast({ message: response?.data?.message, type: "error" })
        );
      }
    } catch (err) {
      console.log(err);
      dispatch(
        showToast({ message: err?.response?.data?.message, type: "error" })
      );
    }
  };

  return (
    <div className="bg-white relative">
      <div
        style={{ zIndex: "10000" }}
        onClick={async (e) => {
          e.preventDefault();
          let link = await createUrl();
          console.log(link);
          if (navigator.share) {
            try {
              await navigator.share({
                title: `Daily Report for ${selectedReport?.name}`,
                text: "",
                url: link,
              });
            } catch (err) {
              console.log(err);
            }
          } else {
            navigator.clipboard.writeText(link);
          }
        }}
        className="absolute top-6 right-3"
      >
        <ShareIcon className="w-5 h-5 text-primary-gray-600" />
      </div>
      <StoryPreview
        selectedReport={selectedReport}
        setShowReports={(val) => {
          console.log("LLLLLLLL");
        }}
      />
    </div>
  );
}

export default ReportsStory;
