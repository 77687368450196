import React from "react";

const Weeks = ({ edit }) => {
  return (
    <div className="px-6 mt-14">
      <h1 className="text-lg text-red-500">Week 1 - 5</h1>
      <div className="flex mt-8">
        <div className="bg-red-200 px-2 py-12 rounded">
          <h1 className="font-medium text-sm text-center">Week 1</h1>
          {edit ? null : (
            <p className="text-xs text-neutral-500">Jul 3 - Jul 9</p>
          )}
        </div>
        <div className="bg-neutral-200 p-3 self-center ml-8 w-[400px]">
          <h1 className="text-neutral-600 text-sm text-center">
            Add Event Here{" "}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default Weeks;
