export const COLLEGE_TYPE_VIRTUAL_GROWTH_CENTER = "VirtualGrowthCenter";
export const COLLEGE_TYPE_GROWTH_CENTER = "GrowthCenter";
export const COLLEGE_TYPE_PIPELINE = "Pipeline";
export const COLLEGE_TYPE_OTHERS = "Other";
export const COLLEGE_TYPE_LIVE = "Live";

export const TableHeadColumns = [
  { label: "", field: "college_propic" },
  { label: "ID", field: "college_id" },
  { label: "Name", field: "college_name" },
  { label: "Date of Joining", field: "createdAt" },
  { label: "Location", field: "college_location" },
];

export const CollegeTypes = [
  // {
  //   label: "Virtual Growth Center",
  //   value: COLLEGE_TYPE_VIRTUAL_GROWTH_CENTER,
  // },
  // {
  //   label: "Growth Center",
  //   value: COLLEGE_TYPE_GROWTH_CENTER,
  // },
  {
    label: "Pipeline",
    value: COLLEGE_TYPE_PIPELINE,
  },
  // {
  //   label: "Other",
  //   value: COLLEGE_TYPE_OTHERS,
  // },
  {
    label: "Live",
    value: COLLEGE_TYPE_LIVE,
  },
];
