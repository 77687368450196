import React from "react";
import tribeLogo from "../../assets/images/tribe_events.png";
import EventCard from "./EventCard";

const Events = ({ event }) => {
  return (
    <div>
      <div className="flex items-center">
        <img src={require(`../../assets/images/${event?.logo}`)} />
        <div className="ml-6">
          <h1 className="text-lg text-neutral-800 font-medium">
            {event?.title}
          </h1>
          <h1 className="text-neutral-500">{event?.desc}</h1>
        </div>
      </div>
      <div className="flex flex-wrap gap-2">
        {event?.details?.map((event) => (
          <EventCard details={event} />
        ))}
      </div>
    </div>
  );
};

export default Events;
