import AllTalentRequests from "components/TalentRequests";
import React from "react";

function TalentRequests() {
  return (
    <div>
      <AllTalentRequests />
    </div>
  );
}

export default TalentRequests;
