import AllPrograms from "components/Programs";
import React from "react";

function Programs() {
  return (
    <div>
      <AllPrograms />
    </div>
  );
}

export default Programs;
