import Projects from "../Comman/Lists/ProjectLists/Projects";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { PROJECT_TYPE_PERSONAL } from "helpers/projects";
import Requests from "./Requests";
import RequestsDetails from "./RequestsDetails";

export default function AllTalentRequests() {
  const projects = useSelector((state) => state.projects.list);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedRequest, setSelectedRequest] = useState(null);
  useEffect(() => {
    if (projects.length > 0) {
      setSelectedProject(projects[0]);
    }
  }, [projects]);
  return (
    <div className="relative ">
      <div className="min-h-[70vh] grid grid-cols-3 gap-6 mt-5">
        <Projects
          title="Projects & Programs"
          projects={projects?.filter((p) => p?.type !== PROJECT_TYPE_PERSONAL)}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
        />

        <Requests
          selectedProject={selectedProject}
          selectedRequest={selectedRequest}
          setSelectedRequest={setSelectedRequest}
        />
        <RequestsDetails
          selectedProject={selectedProject}
          selectedRequest={selectedRequest}
        />
      </div>
    </div>
  );
}
