import { Pencil } from "@phosphor-icons/react";

const ProjectItem = ({
  isSelected = false,
  onClick,
  project,
  onEdit,
  editable,
}) => {
  return (
    <div
      className={`flex items-center justify-between w-full rounded-lg p-2 cursor-pointer ${
        isSelected
          ? "bg-primary-green-lightest text-black"
          : "bg-gray-100 text-black"
      }`}
      onClick={onClick}
    >
      <div className="flex items-center justify-between w-4/5">
        {project?.image ? (
          <img
            className="m-1 mr-2 w-8 h-8 object-contain rounded-full"
            src={project?.image?.url}
            alt=""
          />
        ) : (
          <div className="m-1 mr-2 w-8 h-8 bg-white relative flex justify-center items-center rounded-full bg-secondary-gray-300 text-black uppercase text-sm">
            {project?.title?.substring(0, 1)}
          </div>
        )}
        <h2 className="font-bold w-5/6">{project?.title}</h2>
      </div>
      {editable && <Pencil size={20} onClick={onEdit} />}
    </div>
  );
};
export const Skeleton = () => {
  return (
    <div
      className={`animate-pulse flex items-center gap-4 w-full cursor-pointer bg-primary-gray-150 text-black rounded-lg p-2 hover:shadow`}
    >
      <div className="h-8 w-8 bg-gray-200 rounded-full"></div>
      <div className="h-2.5 bg-gray-200 rounded w-2/3"></div>
    </div>
  );
};
export default ProjectItem;
