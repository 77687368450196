import EventsComponent from "components/Events";
import React from "react";

function Events() {
  return (
    <div className="h-full">
      <EventsComponent />
    </div>
  );
}

export default Events;
