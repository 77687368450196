function IssueCard({ label, count, description }) {
  return (
    <div className="dsr-issue-card flex flex-col items-stretch space-y-6">
      <div className="flex flex-row items-center space-x-6">
        <h1 className="count">{count}</h1>
        <p className="issue-name">{label}</p>
      </div>
      {description && <p className="issue-description">{description}</p>}
    </div>
  );
}

export default IssueCard;
