import moment from "moment";

export const getReleaseCardClass = (startDate, endDate) => {
  let s = moment(startDate).unix();
  let e = moment(endDate).unix();

  if (s > moment().unix()) return "upcoming";
  else if (s >= e) return "completed";
  else if (s < e) return "failed";
  else return "upcoming";
};

export const releaseFilter = [
  { label: "This Month", value: "thisMonth" },
  { label: "This Quarter", value: "thisQuarter" },
];
