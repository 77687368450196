import { PlusCircle } from "@phosphor-icons/react";

import { useEffect, useState } from "react";
import * as TalentRequestAPI from "config/APIs/talentRequests";
import EmptyState from "components/Comman/EmptyState";
import RequestCard from "components/Comman/Cards/RequestCard";
import TalentRequestCreateEdit from "components/Modals/TalentRequest";

export default function Requests({
  selectedProject,
  selectedRequest,
  setSelectedRequest,
}) {
  const [showAddModal, setShowAddModal] = useState(false);
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    if (selectedProject) {
      getRequests();
    }
  }, [selectedProject]);

  const getRequests = async () => {
    try {
      const response = await TalentRequestAPI.getAllEntity({
        projectId: selectedProject?.id,
      });

      setRequests(response?.data?.data);
    } catch (err) {
      console.log("Error", err);
    }
  };

  return (
    <>
      <TalentRequestCreateEdit
        isOpen={showAddModal}
        closeModal={() => setShowAddModal(false)}
        onCreate={() => {
          getRequests();
        }}
        project={selectedProject}
      />
      <div className="w-full h-full border bg-white border-gray-100 rounded-md p-4 px-6 shadow-md">
        <div className="flex justify-between items-center">
          <h2 className="font-extrabold text-xl text-black font-inter">
            Ongoing Talent Requests
          </h2>
          <button
            onClick={() => setShowAddModal(true)}
            className="flex items-center gap-1 border p-1.5 border-black rounded text-xs text-black"
          >
            <PlusCircle size={12} />
            Add New
          </button>
        </div>

        <div className="flex flex-col mt-3 gap-6">
          {requests?.length > 0 ? (
            requests?.map((request, i) => (
              <RequestCard
                key={i}
                request={request}
                isSelected={selectedRequest?.id === request?.id}
                onClick={() => setSelectedRequest(request)}
              />
            ))
          ) : (
            <EmptyState
              image="/assets/images/empty/member.svg"
              text="The project currently have no ongoing talent requests!"
            />
          )}
        </div>
      </div>
    </>
  );
}
