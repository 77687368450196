import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { TShirt } from "@phosphor-icons/react";

const availableSizes = ["XS", "S", "M", "L", "XL", "XXL"];

export default function TShirtSizeDropdown({ size, setSize, index, disabled }) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button disabled={disabled} className="">
          <div className="relative cursor-pointer text-primary-gray-1000 mt-1 ">
            <TShirt className="w-8 h-8" />
            <span className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 text-3xs">
              {size ?? "N/A"}
            </span>
          </div>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          style={{
            borderRadius: 8,
            // Index === 0 ? "20px 0px 20px 20px" : "20px 20px 0px 20px",
          }}
          className={`${
            index === 0 ? "origin-top-right top-7" : "origin-bottom-right top-6"
          } right-0 absolute  w-auto z-30 shadow-lg px-3 py-3 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <div className="py-1 max-h-64 overflow-y-auto space-y-1.5">
            {availableSizes.map((item) => {
              return (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={() => {
                        setSize(item);
                      }}
                      className="relative cursor-pointer text-primary-gray-1000 mt-1 "
                    >
                      <TShirt className="w-8 h-8" />
                      <span className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 text-3xs">
                        {item ?? "N/A"}
                      </span>
                    </div>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
