import { instance, getHeader } from "config";

/**
 * The file contains all requests/talent related CRUDS
 */

/**
 * To create
 */
export const createEntity = (body) =>
  instance.post(`/admin/requests/talent`, body, getHeader());

/**
 * To delete
 * @param id --> id of the entity
 */
export const deleteEntity = (id) =>
  instance.delete(`/admin/requests/talent/${id}`, getHeader());

/**
 * To get all
 */
export const getAllEntity = (query = {}) =>
  instance.get(
    `/admin/requests/talent${
      Object.keys(query)?.length > 0 ? `?${new URLSearchParams(query)}` : ""
    }`,
    getHeader()
  );

/**
 * Gives
 * @param id --> id of attributes or attributeTypes
 */
export const getOneEntity = (id) =>
  instance.get(`/admin/requests/talent/${id}`, getHeader());

/**
 * To update
 * @param id --> id of the entity
 */
export const updateEntity = (id, body) =>
  instance.patch(`/admin/requests/talent/${id}`, body, getHeader());
