/**
 *
 * @param {Object} college
 * @param {Function} onClick
 * @returns
 */

import { CollegeTypes } from "helpers/constants/colleges";

function CollegeCard({ college, onClick }) {
  return (
    <div
      onClick={onClick}
      className={`cursor-pointer bg-white shadow-container rounded-20px`}
    >
      <div>
        <div className="relative group py-5 px-5 focus-within:ring-0">
          <div>
            <span
              className={`${
                college?.type == "Live"
                  ? "bg-primary-green-medium"
                  : "bg-primary-red-medium"
              } text-2xs text-white rounded-full inline-flex px-2 py-0.5`}
            >
              {CollegeTypes?.find((c) => c?.value == college?.type)?.label}
            </span>
          </div>
          <div className="mt-2 flex flex-col items-start w-full">
            <h3 className="w-8/12">
              <div className="focus:outline-none w-full">
                <p className="w-full text-sm line-clamp-2 leading-4 font-inter text-primary-gray-1000 cursor-pointer break-words font-bold">
                  {college?.college_name}
                </p>
              </div>
            </h3>
            <p className="mt-1 text-sm text-primary-gray-1000 opacity-70 line-clamp-2 inter">
              {college?.college_location}
            </p>
            <div className="mt-4 font-inter text-xs font-bold text-primary-red-darkest border-primary-red-lighter break-all border-t pt-2 w-full flex flex-row items-center justify-between">
              <div className="font-normal">
                {college?.activeMembers || 0} active member
                {college?.activeMembers > 1 && "s"}
              </div>{" "}
              <div>View -></div>
            </div>
          </div>
          {college?.image && (
            <span
              className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
              aria-hidden="true"
            >
              <img
                src={college?.image?.url}
                alt="creator"
                className="h-20 w-20 object-contain rounded-md"
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

function CollegeCardLoader() {
  return (
    <div className=" cursor-pointer bg-white shadow-container rounded-20px">
      <div>
        <div className="relative group py-5 px-5 focus-within:ring-0 animate-pulse">
          <div>
            <span className="bg-primary-red-dark text-2xs text-white rounded-full inline-flex px-2 py-0.5"></span>
          </div>
          <div className="mt-2 flex flex-col items-start w-full">
            <h3 className="">
              <div className="focus:outline-none w-full">
                <p className="w-10/12 text-sm line-clamp-2 leading-4 font-inter text-primary-gray-1000 cursor-pointer break-words font-bold">
                  <p className="inter text-sm h-2 rounded bg-primary-red-lighter animate-pulse w-full font-normal text-primary-gray-600"></p>
                </p>
              </div>
            </h3>
            <p className="inter text-sm h-2 mt-1 rounded w-7/12 bg-primary-red-lighter animate-pulse w-full font-normal text-primary-gray-600"></p>

            <a
              target="_blank"
              className="mt-4 text-xs font-bold text-primary-red-darkest border-primary-red-lighter break-all inter border-t pt-2 w-full"
            >
              <p className="inter text-sm h-2 rounded w-20 bg-primary-red-lighter animate-pulse w-full font-normal text-primary-gray-600"></p>
            </a>
          </div>
          <span
            className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
            aria-hidden="true"
          >
            <img
              src={"/assets/svg/defaultUser.svg"}
              alt="creator"
              className="h-6 w-6 rounded-full"
            />
          </span>
        </div>
      </div>
    </div>
  );
}

export default CollegeCard;
export { CollegeCardLoader };
