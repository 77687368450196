const Member = ({ isSelected = false, member, onClick }) => {
  return (
    <div
      className={`flex items-start gap-4 w-full cursor-pointer bg-gray-100 text-black rounded-lg p-3 hover:shadow border ${
        isSelected ? "border-gray-300" : "border-transparent"
      }`}
      onClick={onClick}
    >
      <img
        src={
          member?.image?.url ||
          "https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-portrait-176256935.jpg"
        }
        className={`h-8 w-8 rounded-full`}
      />
      <div>
        <h2 className="font-bold">{member?.first_name}</h2>
        <p className="text-sm capitalize">
          {member?.type || member?.userProjects?.type || "Member"}
        </p>
      </div>
    </div>
  );
};

export default Member;
