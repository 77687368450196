import PriorityDropdown from "components/Tasks/Card/PriorityDropdown";
import { USER_TYPE_CUSTOMER_FIC } from "helpers/constants/users";
import moment from "moment";
import { useEffect, useState } from "react";

const RequestCard = ({ isSelected = false, request, onClick }) => {
  const [priority, setPriority] = useState(1);

  useEffect(() => {
    setPriority(request?.priority);
  }, [request]);

  return (
    <div
      className={`flex flex-col items-start space-y-4 w-full cursor-pointer talentCard text-black px-6 py-4 hover:shadow ${
        isSelected ? "border" : "border-0"
      }`}
      onClick={onClick}
    >
      <div className="w-full flex flex-row items-center justify-between space-x-2">
        <p className="font-inter text-sm text-primary-gray-600">
          Role: <b>{request?.designation?.role?.role_name}</b>
        </p>
        <p className="bg-secondary-green-light rounded px-2 py-1 font-inter text-white text-2xs font-semibold">
          {request?.userType == USER_TYPE_CUSTOMER_FIC ? "FIC" : "FWS"}
        </p>
      </div>
      <div className="w-full flex flex-row items-center justify-between space-x-2">
        <p className="font-inter text-sm text-primary-gray-600">
          Openings: <b>{request?.openings || 0}</b>
        </p>
      </div>
      <div className="w-full flex flex-row items-center justify-between space-x-2">
        <PriorityDropdown
          priorityValue={priority}
          setPriorityValue={(val) => setPriority(val)}
        />
        <p className="font-inter text-2xs text-primary-gray-300 font-semibold">
          Created on {moment(request?.createdAt).format("DD-MM-YY")}
        </p>
      </div>
    </div>
  );
};

export default RequestCard;
