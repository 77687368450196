import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { UserIcon } from "@heroicons/react/outline";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { useHistory } from "react-router-dom";
import { getCollegeById } from "config/APIs/college";
import { CollegeTypes } from "helpers/constants/colleges";
import { getAllUsersAdmin } from "config/APIs/users";
import CollegeMembers from "components/CGC/College/Members";
import CollegeEvents from "components/CGC/College/Events";
import { getAllEvents } from "config/APIs/events";
import CollegeInsights from "components/CGC/College/Insights";
import Tabs from "components/Comman/Tabs";

const tabs = [
  { label: "Insights", value: "insights" },
  { label: "Members", value: "members" },
  { label: "Events", value: "events" },
];

function College() {
  const { id } = useParams();

  const history = useHistory();
  const [details, setDetails] = useState({});
  const [active, setActive] = useState(true);
  const [selectedTab, setSelectedTab] = useState("insights");
  const [members, setMembers] = useState([]);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      getDetails();
      getMembers();
      getEvents();
    }

    return () => {
      isMounted = false;
    };
  }, [id]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      window.scrollTo({ top: 0, behavior: "smooth" });

      getMembers();
    }

    return () => {
      isMounted = false;
    };
  }, [active]);

  const getDetails = async () => {
    try {
      const response = await getCollegeById("colleges", id);
      const college = response.data.data;
      setDetails(college);
    } catch (err) {
      console.log("Fetch college error", err);
    }
  };

  const getMembers = async () => {
    try {
      let q = { college_id: id };
      if (active) {
        q["is_active"] = true;
      }
      const response = await getAllUsersAdmin(q);
      const users = response.data.data?.response;
      setMembers(users);
    } catch (err) {
      console.log("Fetch users error", err);
    }
  };

  const getEvents = async () => {
    try {
      const response = await getAllEvents({
        collegeId: id,
      });
      const events = response.data.data;
      setEvents(events);
    } catch (err) {
      console.log("Fetch events error", err);
    }
  };

  return (
    <>
      <div className="w-full pt-2.5 px-2.5">
        <div className="w-full px-5 relative">
          <div className="absolute top-3 right-5">
            <ArrowLeftIcon
              onClick={() => {
                history.goBack();
              }}
              className="w-4 text-black mr-2"
            />
          </div>
          <div
            className="p-5 flex flex-col w-full bg-white"
            style={{ borderRadius: "20px" }}
          >
            <div className="flex flex-row items-center space-x-2.5">
              <div
                className={`w-14 h-14 flex flex-row items-center justify-center rounded-md `}
              >
                {details?.image?.url ? (
                  <img
                    src={details?.image?.url}
                    className="rounded-md h-14 w-14 object-contain"
                    alt=""
                  />
                ) : (
                  <UserIcon className="rounded-full h-7 w-7 text-gray-500" />
                )}
              </div>
              <div className="">
                <p className="text-xs font-inter text-primary-gray-1000 font-bold leading-4 cursor-pointer break-all">
                  {details?.college_name}
                </p>

                <p className="text-2xs tracking-normal font-inter font-normal leading-3 text-primary-gray-250 max-w-max cursor-pointer">
                  {CollegeTypes?.find((c) => c?.value == details?.type)?.label}
                </p>
                <p className="text-2xs tracking-normal mt-0.5 font-inter font-normal leading-3 text-primary-gray-250 max-w-max cursor-pointer">
                  {details?.college_location}
                </p>
              </div>
            </div>
          </div>
        </div>
        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </div>
      <div className="max-h-60vh px-2.5">
        {selectedTab == "insights" && (
          <CollegeInsights details={details} events={events} />
        )}
        {selectedTab == "members" && (
          <CollegeMembers
            list={members}
            active={active}
            setActive={setActive}
          />
        )}
        {selectedTab == "events" && <CollegeEvents list={events} />}
      </div>
    </>
  );
}

export default College;
