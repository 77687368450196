import Drawer from "@mui/material/Drawer";
import React, { useEffect, useState } from "react";
import { XIcon } from "@heroicons/react/solid";
import { useDispatch } from "react-redux";
import * as ProjectAPI from "config/APIs/project";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { showToast } from "redux/toaster";
import ChronosButton from "components/Comman/Buttons";
import DropdownInput from "components/Comman/Inputs/DropdownInput";
import DateInput from "components/Comman/Inputs/DateInput";
import { PROJECT_CATEGORY_KANBAN, PROJECT_TYPE_FWS } from "helpers/projects";
import Toggle from "components/Comman/Inputs/Toggle";
import { fetchAllProjects } from "redux/projects";
import { getAllUsersAdmin } from "config/APIs/users";
import ImageSelector from "components/Comman/Inputs/ImageSelector";

const defaultValue = {
  title: "",
  description: "",
};

function ProjectCreateEdit({
  closeModal,
  isOpen,
  onCreate,
  onUpdate,
  editValues,
}) {
  const dispatch = useDispatch();
  const [data, setData] = useState(defaultValue);
  const [fieldErrors, setFieldErrors] = useState({});
  const [profiles, setProfiles] = useState([]);
  const [creating, setCreating] = useState(false);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && isOpen) {
      setData(editValues);
    }
    return () => {
      isMounted = false;
    };
  }, [isOpen, editValues]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && isOpen) {
      getAllUsers();
    }
    return () => {
      isMounted = false;
    };
  }, [isOpen]);

  const showSuccessNotification = (message) => {
    dispatch(showToast({ message }));
  };

  const showErrorNotification = (message) => {
    dispatch(showToast({ message, type: "error" }));
  };
  const getAllUsers = async () => {
    try {
      const res = await getAllUsersAdmin({
        user_type: "caarya-core",
        is_active: true,
      });
      setProfiles(res?.data?.data?.response || []);
    } catch (err) {
      console.log("Goal delete error", err);
    }
  };

  const handleCreate = async () => {
    if (data?.title == "") {
      setFieldErrors({ title: { msg: "Please enter the title!" } });
      return;
    }

    setCreating(true);

    try {
      let body = {
        project: {
          ...data,
          category: PROJECT_CATEGORY_KANBAN,
          type: PROJECT_TYPE_FWS,
          status: "Live",
        },
      };

      const response = await ProjectAPI.create(body);
      closeModal();
      onCreate();
      setData(defaultValue);
      dispatch(fetchAllProjects("FWS", "Live", true));
      setData(defaultValue);

      showSuccessNotification("Project created successfully!");
    } catch (err) {
      console.log("Error", err);
      switch (err.response?.status) {
        case 422:
          let error = {},
            { data } = err.response.data;
          for (let key of Object.keys(data)) {
            if (key.split(".")[0] === "task")
              error[key.split(".")[1]] = data[key];
          }
          setFieldErrors(error);
          break;
        case 401:
          console.log(err?.response);
          showErrorNotification(err.response?.data?.message);
          break;
        default:
          showErrorNotification("Validation Failed!");
      }
    }
    setCreating(false);
  };

  const handleUpdate = async () => {
    if (data?.title == "") {
      setFieldErrors({ title: { msg: "Please enter the title!" } });
      return;
    }

    setCreating(true);

    try {
      let body = {
        update: {
          ...data,
          category: PROJECT_CATEGORY_KANBAN,
          type: PROJECT_TYPE_FWS,
          status: "Live",
        },
      };

      const response = await ProjectAPI.update(editValues?.id, body);
      closeModal();
      onUpdate();
      setData(defaultValue);
      dispatch(fetchAllProjects("FWS", "Live", true));
      setData(defaultValue);

      showSuccessNotification("Project created successfully!");
    } catch (err) {
      console.log("Error", err);
      switch (err.response?.status) {
        case 422:
          let error = {},
            { data } = err.response.data;
          for (let key of Object.keys(data)) {
            if (key.split(".")[0] === "task")
              error[key.split(".")[1]] = data[key];
          }
          setFieldErrors(error);
          break;
        case 401:
          console.log(err?.response);
          showErrorNotification(err.response?.data?.message);
          break;
        default:
          showErrorNotification("Validation Failed!");
      }
    }
    setCreating(false);
  };

  return (
    <Drawer
      anchor={window.innerWidth < 1024 ? "bottom" : "right"}
      PaperProps={{
        style: {
          borderRadius: window.innerWidth < 1024 ? "20px 20px 0px 0px" : "0px",
          maxHeight: "100vh",
          width: window.innerWidth < 1024 ? "100%" : "560px",
        },
      }}
      open={isOpen}
      onClose={() => {
        closeModal();
        setData(defaultValue);
      }}
      transitionDuration={250}
    >
      <div className="bg-white md:max-w-xl lg:h-screen lg:pt-20 mx-auto w-full transform transition-all ease-in-out duration-150">
        <div className="flex flex-row items-end justify-between px-5 pt-5 rounded-t-20px">
          <h5
            className="font-inter font-medium text-sm text-primary-gray-1000 flex flex-col items-start"
            id="exampleModalLiveLabel"
          >
            {editValues ? `Update ${editValues?.title}` : "Add New Cohort"}
          </h5>

          <button
            aria-label="Close"
            type="button"
            onClick={() => {
              closeModal();
              setData(defaultValue);
            }}
          >
            <XIcon className="h-6 w-6 text-primary-gray-1000" />
          </button>
        </div>

        <div className="mt-3.5 flex w-full flex-col items-start px-5 space-y-5 h-auto transition-all ease-in-out duration-150">
          <div className="flex flex-col items-start w-full">
            <label
              htmlFor="Title"
              className="text-sm text-primary-gray-1000 leading-3 mb-2.5 font-inter font-normal"
            >
              Title
            </label>
            <TextareaAutosize
              minRows={1}
              autoFocus={true}
              placeholder="Enter Cohort..."
              style={{ fontSize: "24px" }}
              className="font-inter font-bold focus:outline-none focus:ring-transparent border-t-0 border-r-0 border-l-0 py-1.5 px-0 text-black w-full border-b bg-transparent border-primary-gray-450 focus:border-primary-gray-450 placeholder:text-primary-gray-200"
              invalid={fieldErrors?.title}
              value={data?.title}
              onChange={(e) => {
                setData({ ...data, title: e.target.value });
                setFieldErrors();
              }}
            />
            {fieldErrors?.title?.msg && (
              <p className="text-alert text-2xs flex flex-row items-center mt-1 ml-0.5">
                {fieldErrors?.title?.msg}
              </p>
            )}
          </div>

          <div className="w-full flex flex-col space-y-5 lg:space-y-0 lg:space-x-2.5 lg:flex-row items-stretch justify-between">
            <div className="w-full lg:w-1/2">
              <label className="input-label">Start Date</label>

              <DateInput
                date={data?.start_date}
                onChange={(value) => setData({ ...data, start_date: value })}
              />
            </div>
            <div className="w-full lg:w-1/2">
              <label className="input-label">End Date</label>

              <DateInput
                date={data?.end_date}
                onChange={(value) => setData({ ...data, end_date: value })}
              />
            </div>
          </div>
          <div className="w-full flex flex-col space-y-5 lg:space-y-0 lg:space-x-2.5 lg:flex-row items-stretch justify-between">
            <div className="w-full lg:w-1/2">
              <div className="input-label mb-0.5">Owner</div>
              <DropdownInput
                list={profiles?.map((i) => ({
                  label: i?.first_name,
                  value: i?.id,
                  image: i?.image || {
                    url: "/assets/images/defaultUser.svg",
                  },
                }))}
                value={data?.owner_id}
                setValue={(val) => {
                  setData({
                    ...data,
                    owner_id: val,
                    owner: profiles?.find((i) => i?.id == val),
                  });
                }}
              />
            </div>
            <div className="w-full lg:w-1/2">
              <div className="input-label mb-2">Active</div>
              <Toggle
                label={data?.is_active ? "Active" : "Inactive"}
                enabled={data?.is_active}
                setEnabled={(val) => {
                  setData({ ...data, is_active: val });
                }}
              />
            </div>
          </div>

          <ImageSelector
            onSuccess={(val) => {
              setData({ ...data, image: val, image_id: val?.id });
            }}
            onDelete={(val) => {
              setData({ ...data, image: null, image_id: null });
            }}
            image={data?.image}
          />
        </div>
        <div className="mt-8 flex w-full flex-row items-center justify-between px-5 pb-5">
          <ChronosButton
            text="Cancel"
            secondary
            onClick={() => {
              closeModal();
              setData(defaultValue);
            }}
          />
          <ChronosButton
            loader={creating}
            text={editValues ? "Update Cohort" : "Add Cohort"}
            primary
            onClick={() => {
              editValues ? handleUpdate() : handleCreate();
            }}
          />
        </div>
      </div>
    </Drawer>
  );
}

export default ProjectCreateEdit;
