import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

export default function PriorityDropdown({
  priorityValue,
  setPriorityValue,
  index = 0,
  disabled,
}) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button disabled={disabled} className="">
          <div className="flex items-center">
            {priorityValue ? (
              <div className={`priority-dark-${priorityValue || 0}`}>
                {priorityValue == 1 ? "🔥" : ""} P{priorityValue}
              </div>
            ) : (
              <div className={`priority-dark-0`}>P?</div>
            )}
          </div>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          style={{
            borderRadius:
              index === 0 ? "20px 0px 20px 20px" : "20px 20px 0px 20px",
          }}
          className={`${
            index === 0
              ? "origin-top-right top-7"
              : "origin-bottom-right bottom-6"
          } right-0 absolute  w-14 z-30 shadow-lg p-2 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <div className="py-1 max-h-64 overflow-y-auto space-y-1.5">
            {[1, 2, 3, 4, 5, 6].map((item) => {
              return (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={() => {
                        setPriorityValue(item);
                      }}
                      className={`priority-dark-${item} mx-auto max-w-fit`}
                    >
                      {item == 1 ? "🔥" : ""} P{item}
                    </div>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
