import { TASK_TYPE_BUGS } from "helpers/task";
import { useEffect, useState } from "react";
import Stories from "react-insta-stories";
import IssueCard from "../Cards/IssuesCards";
import ProjectCard from "../Cards/ProjectCards";
import StatCard from "../Cards/StatCards";
import TaskCard from "../Cards/TaskCard";
import TaskWrapperCard from "../Cards/TaskWrapperCard";
import UserCard from "../Cards/UserCard";
import ReportsStoryWrapper from "./ReportsStoryWrapper";
import ProjectGoal from "../Cards/ProjectGoal";

function StoryPreview({ selectedReport }) {
  const [stories, setStories] = useState([
    {
      content: ({ action, isPaused }) => {
        return (
          <div className="w-100vw h-100vh bg-white flex flex-row items-center justify-center"></div>
        );
      },
    },
  ]);

  useEffect(() => {
    let temp = [];
    switch (selectedReport?.report) {
      case "project":
        selectedReport?.reports?.map((item) => {
          temp.push({
            content: ({ action, isPaused }) => {
              return (
                <ReportsStoryWrapper
                  bgClass="dsr-screen-bg-pink"
                  image={selectedReport?.image}
                  name={selectedReport?.name}
                >
                  <ProjectCard project={item} />
                </ReportsStoryWrapper>
              );
            },
          });
        });

        setStories(temp);
        break;

      case "user":
        selectedReport?.reports?.map((item) => {
          if (item?.projects?.length > 3) {
            let obj1 = { ...item };
            delete obj1?.stat;
            let obj2 = { ...item };
            delete obj2?.projects;
            temp.push({
              content: ({ action, isPaused }) => {
                return (
                  <ReportsStoryWrapper
                    bgClass="dsr-screen-bg-pink"
                    image={selectedReport?.image}
                    name={selectedReport?.name}
                  >
                    <UserCard user={obj1} />
                  </ReportsStoryWrapper>
                );
              },
            });
            temp.push({
              content: ({ action, isPaused }) => {
                return (
                  <ReportsStoryWrapper
                    bgClass="dsr-screen-bg-pink"
                    image={selectedReport?.image}
                    name={selectedReport?.name}
                  >
                    <UserCard user={obj2} />
                  </ReportsStoryWrapper>
                );
              },
            });
          } else {
            temp.push({
              content: ({ action, isPaused }) => {
                return (
                  <ReportsStoryWrapper
                    bgClass="dsr-screen-bg-pink"
                    image={selectedReport?.image}
                    name={selectedReport?.name}
                  >
                    <UserCard user={item} />
                  </ReportsStoryWrapper>
                );
              },
            });
          }
        });

        setStories(temp);
        break;

      case "userStory":
        if (selectedReport?.projects?.length > 3) {
          let obj1 = { ...selectedReport };
          delete obj1?.stat;
          let obj2 = { ...selectedReport };
          delete obj2?.projects;
          temp.push({
            content: ({ action, isPaused }) => {
              return (
                <ReportsStoryWrapper
                  bgClass="dsr-screen-bg-pink"
                  image={selectedReport?.image}
                  name={selectedReport?.name}
                >
                  <UserCard user={obj1} />
                </ReportsStoryWrapper>
              );
            },
          });
          temp.push({
            content: ({ action, isPaused }) => {
              return (
                <ReportsStoryWrapper
                  bgClass="dsr-screen-bg-pink"
                  image={selectedReport?.image}
                  name={selectedReport?.name}
                >
                  <UserCard user={obj2} />
                </ReportsStoryWrapper>
              );
            },
          });
        } else {
          temp.push({
            content: ({ action, isPaused }) => {
              return (
                <ReportsStoryWrapper
                  bgClass="dsr-screen-bg-pink"
                  image={selectedReport?.image}
                  name={selectedReport?.name}
                >
                  <UserCard user={selectedReport} />
                </ReportsStoryWrapper>
              );
            },
          });
        }

        selectedReport?.tasks?.map((task) => {
          temp.push({
            content: ({ action, isPaused }) => {
              return (
                <ReportsStoryWrapper
                  bgClass={
                    task?.status == "NotStarted"
                      ? "dsr-screen-bg-red"
                      : task?.status == "InProgress"
                      ? "dsr-screen-bg-yellow"
                      : task?.status == "NotStarted"
                      ? "dsr-screen-bg-pink"
                      : "dsr-screen-bg-blue"
                  }
                  image={selectedReport?.image}
                  name={selectedReport?.name}
                >
                  <TaskWrapperCard user={selectedReport} task={task} />
                </ReportsStoryWrapper>
              );
            },
          });
        });

        setStories(temp);
        break;

      case "projectStory":
        temp.push({
          content: ({ action, isPaused }) => {
            return (
              <ReportsStoryWrapper
                bgClass="dsr-screen-bg-pink"
                image={selectedReport?.image}
                name={selectedReport?.name}
              >
                <ProjectCard project={selectedReport} />
              </ReportsStoryWrapper>
            );
          },
        });
        selectedReport?.goals?.map((goal) => {
          goal?.tasks?.map((task) => {
            temp.push({
              content: ({ action, isPaused }) => {
                return (
                  <ReportsStoryWrapper
                    bgClass={
                      task?.status == "NotStarted"
                        ? "dsr-screen-bg-red"
                        : task?.status == "InProgress"
                        ? "dsr-screen-bg-yellow"
                        : task?.status == "NotStarted"
                        ? "dsr-screen-bg-pink"
                        : "dsr-screen-bg-blue"
                    }
                    image={selectedReport?.image}
                    name={selectedReport?.name}
                  >
                    <TaskWrapperCard goal={goal} task={task} />
                  </ReportsStoryWrapper>
                );
              },
            });
          });
        });

        setStories(temp);
        break;

      case "projectGoal":
        selectedReport?.reports?.map((item) => {
          if (item?.goals?.length > 0) {
            temp.push({
              content: ({ action, isPaused }) => {
                return (
                  <ReportsStoryWrapper
                    bgClass="dsr-screen-bg-blue-black"
                    image={selectedReport?.image}
                    name={selectedReport?.name}
                  >
                    <ProjectGoal project={item} goals={item?.goals} />
                  </ReportsStoryWrapper>
                );
              },
            });
          }
        });

        setStories(temp);
        break;
      default:
        setStories([
          {
            content: ({ action, isPaused }) => {
              return (
                <ReportsStoryWrapper
                  bgClass="dsr-screen-bg-yellow"
                  image={selectedReport?.image}
                  name={selectedReport?.name}
                >
                  <StatCard count="12" label="Marketing Needs Completed" />
                </ReportsStoryWrapper>
              );
            },
          },
          {
            content: ({ action, isPaused }) => {
              return (
                <ReportsStoryWrapper
                  bgClass="dsr-screen-bg-red"
                  image={selectedReport?.image}
                  name={selectedReport?.name}
                >
                  <IssueCard count="12" label="Marketing Needs Completed" />
                  <IssueCard
                    count="12"
                    label="Marketing Needs Completed"
                    description="Issue Description lorem ipsum dolor sit amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. "
                  />
                </ReportsStoryWrapper>
              );
            },
          },
          {
            content: ({ action, isPaused }) => {
              return (
                <ReportsStoryWrapper
                  bgClass="dsr-screen-bg-pink"
                  image={selectedReport?.image}
                  name={selectedReport?.name}
                >
                  <TaskCard count="12" label="Marketing Needs Completed" />
                  <TaskCard
                    count="12"
                    label="Marketing Needs Completed"
                    description="Issue Description lorem ipsum dolor sit amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. "
                  />
                </ReportsStoryWrapper>
              );
            },
          },
          {
            content: ({ action, isPaused }) => {
              return (
                <ReportsStoryWrapper
                  bgClass="dsr-screen-bg-blue"
                  image={selectedReport?.image}
                  name={selectedReport?.name}
                >
                  <TaskWrapperCard
                    count="12"
                    label="Marketing Needs Completed"
                  />
                  <TaskWrapperCard
                    count="12"
                    label="Marketing Needs Completed"
                    description="Issue Description lorem ipsum dolor sit amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. "
                  />
                </ReportsStoryWrapper>
              );
            },
          },
        ]);
    }
  }, [selectedReport]);

  return (
    <div className="insta-stories-wrapper mx-auto">
      <Stories
        stories={stories}
        loop
        keyboardNavigation
        defaultInterval={4000}
        onStoryEnd={(s, st) => console.log("story ended", s, st)}
        onAllStoriesEnd={(s, st) => {
          console.log("all stories ended", s, st);
          // if (setShowReports && s == selectedReport?.reports?.length - 1)
          //   setShowReports(false);
        }}
        height="100vh"
        width="100vw"
        onStoryStart={(s, st) => console.log("story started", s, st)}
        storyContainerStyles={{
          overflow: "hidden",
          backgroundColor: "white",
          borderRadius: "0px",
          maxWidth: "100vw",
          margin: "auto",
        }}
      />
    </div>
  );
}

export default StoryPreview;
