import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import {
  ChevronDoubleRightIcon,
  UserCircleIcon,
  XIcon,
} from "@heroicons/react/solid";
import { Fade } from "react-awesome-reveal";

import Arrow from "assets/icons/Arrow.svg";
import { useSelector } from "react-redux";
import ChronosButton from "components/Comman/Buttons";
import { CheckCircle, Plus } from "@phosphor-icons/react";
import { getAllUsersAdmin } from "config/APIs/users";

function AddMembers({
  isOpen,
  closeModal,
  id,
  onUpdate,
  type = "Members",
  selected,
  selectOne,
  project,
  allUsers,
  userType,
}) {
  const squad = useSelector((state) => state.user.squad);
  const [creating, setCreating] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [selectedProfiles, setSelectedProfiles] = useState([]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && isOpen) {
      if (selected?.length > 0) setSelectedProfiles(selected);
      setProfiles(squad?.teamMembers);
      if (allUsers) getAllUsers();
    }

    return () => {
      isMounted = false;
    };
  }, [isOpen]);

  const addMembers = async () => {
    setCreating(true);

    onUpdate(id, selectedProfiles);

    setCreating(false);
    closeModal();
  };

  const handleSearch = (e) => {
    let value = e.target.value;
    if (value === "") {
      setProfiles(squad?.teamMembers);
    } else {
      let filtered = squad?.teamMembers?.filter(
        (p) =>
          p?.first_name?.toLowerCase().includes(value.toLowerCase()) ||
          p?.last_name?.toLowerCase().includes(value.toLowerCase()) ||
          p?.middle_name?.toLowerCase().includes(value.toLowerCase())
      );
      setProfiles(filtered);
    }
  };

  const getAllUsers = async () => {
    try {
      const res = await getAllUsersAdmin({
        user_type: userType || "caarya-core",
        is_active: true,
      });
      setProfiles(res?.data?.data?.response || []);
    } catch (err) {
      console.log("Goal delete error", err);
    }
  };

  return (
    <Drawer
      anchor={window.innerWidth < 1024 ? "bottom" : "right"}
      PaperProps={{
        style: {
          borderRadius: window.innerWidth < 1024 ? "20px 20px 0px 0px" : "0px",
          maxHeight: "100vh",
          width: window.innerWidth < 1024 ? "100%" : "340px",
        },
      }}
      open={isOpen}
      onClose={() => closeModal()}
      transitionDuration={250}
    >
      <div className="modals-component md:max-w-xl lg:h-screen lg:pt-10 mx-auto w-full transform transition-all ease-in-out duration-150">
        <div className="flex flex-row items-end justify-between p-5 rounded-t-20px">
          <div className="flex flex-col space-y-1">
            <h5
              className="font-lato font-bold text-sm text-primary-gray-1000 flex flex-col items-start"
              id="exampleModalLiveLabel"
            >
              Add {type} {project ? " for " : ""}
            </h5>

            <p className="font-lato mb-5 font-normal text-sm text-primary-gray-1000 flex flex-row items-center">
              <img
                src={project?.image?.url || "/assets/images/icons/rocket.png"}
                alt=""
                className="h-5 w-5 rounded mr-1.5"
              />
              {project?.title}
            </p>
          </div>
          <button
            aria-label="Close"
            type="button"
            onClick={() => {
              closeModal();
            }}
          >
            <XIcon className="h-6 w-6 text-primary-gray-1000" />
          </button>
        </div>
        <div className="relative px-5">
          <input
            type="text"
            className="w-full h-10 rounded-md border border-primary-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-gray-300 focus:border-transparent px-5"
            placeholder="Search"
            onChange={handleSearch}
          />
        </div>
        <div
          className={`w-full grid grid-cols-2 gap-4 max-h-75vh overflow-y-auto p-3`}
        >
          {profiles?.map((item) => (
            <div className="p-1 max-h-32">
              <div
                onClick={() => {
                  if (selectOne) {
                    setSelectedProfiles([item]);
                  } else {
                    if (selectedProfiles.includes(item)) {
                      let temp = selectedProfiles.filter(
                        (e) => e?.id !== item?.id
                      );
                      setSelectedProfiles(temp);
                    } else {
                      setSelectedProfiles((state) => [...state, item]);
                    }
                  }
                }}
                className={`${
                  selectedProfiles.find((e) => e?.id === item?.id)
                    ? "bg-primary-red-lightest"
                    : "bg-primary-gray-100"
                } px-2.5 pb-3.5 pt-2.5 rounded-lg h-full relative flex flex-col items-start justify-center cursor-pointer transition transform hover:scale-105 ease-in-out duration-150`}
              >
                {selectedProfiles.find((e) => e?.id === item?.id) ? (
                  <CheckCircle
                    className={`h-3 w-3 absolute top-1.5 right-1.5 components-modals-multiple-addmember-index-checkcircle`}
                  />
                ) : (
                  <div className="w-3 h-3 absolute top-1.5 right-1.5 rounded-full flex flex-row items-center justify-center components-modals-multiple-addmember-index-div2">
                    <Plus className={`h-3 w-3 text-primary-gray-350 `} />
                  </div>
                )}
                <img
                  className="h-9 w-9 rounded-full object-cover"
                  src={item.image?.url || "/assets/images/defaultUser.png"}
                  alt=""
                />
                <div className="flex flex-col mt-2.5">
                  <p className="font-lato text-sm font-normal text-primary-gray-600">
                    {item?.first_name}{" "}
                    {item?.middle_name ? item?.middle_name : ""}{" "}
                    {item?.last_name ? item?.last_name : ""}
                  </p>
                  <p className="font-lato text-2xs font-normal text-primary-gray-350">
                    {item?.designation?.role?.role_name}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="mt-8 flex w-full flex-row items-center justify-between px-5 pb-5">
          <ChronosButton
            text="Cancel"
            secondary
            onClick={() => {
              closeModal();
            }}
          />

          <ChronosButton
            loader={creating}
            text="Add"
            primary
            icon={<ChevronDoubleRightIcon className="w-4 h-4" />}
            onClick={() => {
              addMembers();
            }}
          />
        </div>
      </div>
    </Drawer>
  );
}

export default AddMembers;
