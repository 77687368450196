import React, { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Timer } from "@phosphor-icons/react";
import { TimePicker } from "@mui/x-date-pickers";
function TimeInput({ value, onChange }) {
  const [clockOpen, setClockOpen] = useState(false);
  const [viewChange, setViewChange] = useState(false);
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          open={clockOpen}
          label=""
          value={value}
          onChange={(value) => {
            onChange(value);
            if (viewChange) {
              setClockOpen(false);
              setViewChange(false);
            }
          }}
          onViewChange={() => {
            setViewChange(true);
          }}
          renderInput={({ inputRef, inputProps }) => (
            <div
              onClick={() => setClockOpen(true)}
              className="relative cursor-pointer flex flex-row items-center w-full justify-between theme-input"
            >
              {value ? (
                <input
                  ref={inputRef}
                  {...inputProps}
                  className={`w-full appearance-none border-0 text-primary-gray-1000 font-intertext-sm leading-6 placeholder:text-primary-gray-300 bg-transparent focus:outline-none focus:ring-transparent`}
                ></input>
              ) : (
                <input
                  ref={inputRef}
                  {...inputProps}
                  placeholder="Add Due Date"
                  className={`w-full appearance-none border-0 text-primary-gray-1000 font-intertext-sm leading-6 placeholder:text-primary-gray-300 bg-transparent focus:outline-none focus:ring-transparent`}
                ></input>
              )}
              <Timer size={24} />
            </div>
          )}
        />
      </LocalizationProvider>
    </>
  );
}

export default TimeInput;
