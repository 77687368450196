import { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";

function ObjectivesModal({ isOpen, closeModal, goal }) {
  const [selectedGoal, setSelectedGoal] = useState();

  useEffect(() => {
    if (goal) {
      setSelectedGoal(goal);
    }
  }, [goal]);

  return (
    <Drawer
      anchor="bottom"
      open={isOpen}
      onClose={() => closeModal()}
      transitionDuration={200}
      PaperProps={{
        style: { maxHeight: "100vh" },
      }}
      className="objectiveModal rounded-t-md"
    >
      <div className="rounded-t-md">
        <div className="bg-primary-red-medium p-2.5 overflow-x-auto w-full">
          <div className="flex flex-row items-stretch space-x-2.5 min-w-max">
            {selectedGoal?.objectives?.length > 0 ? (
              selectedGoal?.objectives?.map((obj) => {
                return (
                  <div
                    className={`cursor-pointer bg-white shadow-container rounded-20px ${
                      selectedGoal?.objectives?.length === 1
                        ? "w-full max-w-95vw"
                        : "max-w-92vw w-max"
                    }`}
                  >
                    <div>
                      <div className="relative group py-5 px-5 focus-within:ring-0">
                        <div className="flex flex-col items-start w-full">
                          <h3 className="flex flex-row items-start space-x-2 w-full">
                            <img
                              src="/assets/svg/tabbar/taskGray.svg"
                              alt=""
                              className="w-4 h-4 mt-0.5"
                            />
                            <div className="focus:outline-none w-10/12">
                              <p className="w-full text-sm leading-4 font-inter text-primary-gray-1000 cursor-pointer break-words font-bold">
                                {obj?.title}
                              </p>
                            </div>
                          </h3>

                          <div className="mt-2 font-inter border-primary-red-lighter break-words border-t pt-2 w-full flex flex-col space-y-1.5">
                            {obj?.keyResults?.length > 0 ? (
                              obj?.keyResults?.map((kr) => {
                                return (
                                  <div className="flex flex-row items-start space-x-2 w-full">
                                    <img
                                      src="/assets/svg/key.svg"
                                      alt=""
                                      className="w-4 h-4 mt-2 transform -rotate-45"
                                    />
                                    <p className="mt-1 text-sm text-primary-gray-1000 opacity-70 inter">
                                      {kr?.title}
                                    </p>
                                  </div>
                                );
                              })
                            ) : (
                              <p className="mt-1 text-sm text-primary-gray-1000 opacity-70 inter">
                                {obj?.title} has no key results!
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div
                className={`cursor-pointer bg-white shadow-container rounded-20px`}
              >
                <div>
                  <div className="relative group py-5 px-5 focus-within:ring-0">
                    <div className="flex flex-col items-start w-full">
                      <h3 className="">
                        <div className="focus:outline-none w-full">
                          <p className="w-full text-sm leading-4 font-inter text-primary-gray-1000 cursor-pointer break-words font-bold">
                            {goal?.title} has no objectives!
                          </p>
                        </div>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default ObjectivesModal;
