import { Calendar, Cards } from "@phosphor-icons/react";
import { TbCards, TbUserSearch } from "react-icons/tb";

const mobileBottomNav = [
  {
    name: "Leads",
    path: "/home/releases",
    image: "/assets/svg/tabbar/releaseGray.svg",
    selectedImage: "/assets/svg/tabbar/releaseGreen.svg",
  },

  {
    name: "Requests",
    path: "/home/targets",
    image: "/assets/svg/tabbar/target.svg",
    selectedImage: "/assets/svg/tabbar/targetGreen.svg",
  },
  {
    name: "Today",
    path: "/today",
    image: "/assets/caaryaLogos/liveLogo.png",
    selectedImage: "/assets/caaryaLogos/liveLogo.png",
  },
  {
    name: "CGCs",
    path: "/home/cgc",
    image: "/assets/svg/tabbar/cgcGray.svg",
    selectedImage: "/assets/svg/tabbar/cgcGreen.svg",
  },
  {
    name: "Drives",
    path: "/home/reports",
    image: "/assets/svg/tabbar/report.svg",
    selectedImage: "/assets/svg/tabbar/reportGreen.svg",
  },
];

const laptopNav = [
  {
    name: "Programs",
    path: "/home/programs",
    icon: <TbCards size={25} />,
  },
  {
    name: "Events",
    path: "/home/events",
    icon: <Calendar size={25} />,
  },
  {
    name: "Talent Requests",
    path: "/home/talentRequests",
    icon: <TbUserSearch size={25} />,
  },
  // {
  //   name: "Event Planner",
  //   path: "/eventplanner",
  //   icon: <TbUserSearch size={25} />,
  // },
];
export { mobileBottomNav, laptopNav };
