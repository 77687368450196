import { CircularProgress, Drawer } from "@mui/material";
import { Plus, TShirt } from "@phosphor-icons/react";
import ChronosButton from "components/Comman/Buttons";
import { PriorityButton } from "components/Comman/Buttons/PriorityButton";
import EmptyState from "components/Comman/EmptyState";
import { createTasks, getUsersTasks } from "config/APIs/task/task";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { IoEyeOutline } from "react-icons/io5";
import PriorityDropdown from "components/Tasks/Card/PriorityDropdown";
import TShirtSizeDropdown from "components/Comman/Inputs/TShirtSizeDropdown";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

export default function UserTasksDrawer({
  member,
  isOpen,
  closeModal,
  projectId,
  userTasks,
  onUpdate,
}) {
  const [tasks, setTasks] = useState();
  const [isAddingTask, setIsAddingTask] = useState(false);
  const [dueDate, setDueDate] = useState(moment().format("YYYY-MM-DD"));
  const [priority, setPriority] = useState();
  const [size, setSize] = useState();
  const [taskTitle, setTaskTitle] = useState("");
  const [createTaskLoading, setCreateTaskLoading] = useState(false);
  const [taskLoading, setTaskLoading] = useState(false);
  const [customDate, setCustomDate] = useState(null);

  const getUserTasks = async () => {
    try {
      const response = await getUsersTasks(member.id, {
        project_id: projectId,
      });
      const tasksData = response.data.data.tasks;
      setTasks(tasksData);
    } catch (err) {
      console.log(err);
    } finally {
      setTaskLoading(false);
    }
  };

  const isAddDisabled = () => {
    return taskTitle.length === 0 || !dueDate || createTaskLoading;
  };

  const addTask = async () => {
    const body = {
      task: {
        title: taskTitle,
        date: dueDate,
        status: "NotStarted",
        t_shirt_size: size,
        priority: priority,
        project_id: projectId,
      },
      owner: member.id,
    };

    setCreateTaskLoading(true);

    try {
      const response = await createTasks(body);
      console.log(response);
    } catch (err) {
      console.log(err);
    } finally {
      setCreateTaskLoading(false);
      setIsAddingTask(false);
      setDueDate(moment().format("YYYY-MM-DD"));
      setTaskTitle("");
      setPriority(null);
      setCustomDate(null);
      let t = [...tasks];
      t.push(body?.task);
      setTasks(t);
      if (userTasks) onUpdate();
    }
  };

  useEffect(() => {
    setTaskLoading(true);
    if (userTasks) {
      setTasks(userTasks);
      setTaskLoading(false);
    } else getUserTasks();
  }, [member, userTasks]);

  return (
    <Drawer
      anchor={window.innerWidth < 1024 ? "bottom" : "right"}
      PaperProps={{
        style: {
          borderRadius: window.innerWidth < 1024 ? "20px 20px 0px 0px" : "0px",
          maxHeight: "100vh",
          width: window.innerWidth < 1024 ? "100%" : "580px",
        },
      }}
      open={isOpen}
      onClose={() => closeModal()}
      transitionDuration={250}
    >
      <div className="p-4 w-full bg-primary-gray-50 min-h-screen">
        <div className="flex justify-between items-center">
          <h3 className="text-xl font-bold">{member?.first_name}'s Tasks</h3>
          <ChronosButton
            primary
            text="Add New Task"
            icon={<Plus />}
            onClick={() => setIsAddingTask(true)}
          />
        </div>

        {isAddingTask && (
          <div className="mt-4">
            <div className="flex justify-between items-center">
              <p className="text-sm font-semibold">
                Add New Task for {member?.first_name}
              </p>
              <div className="flex gap-2">
                <button onClick={() => setIsAddingTask(false)}>
                  <AiOutlineCloseCircle size={24} />
                </button>
                <button disabled={isAddDisabled()} onClick={addTask}>
                  {createTaskLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    <AiOutlineCheckCircle size={24} />
                  )}
                </button>
              </div>
            </div>
            <div className="bg-white border border-primary-gray-200 rounded p-2.5">
              <textarea
                placeholder="Add new task..."
                className="text-sm p-0 border-0 bg-transparent w-full mt-1 focus:border-0 focus:outline-none focus:ring-0"
                onChange={(e) => setTaskTitle(e.target.value)}
                rows={4}
              />
              <div className="flex gap-8">
                <div>
                  <p className="font-semibold text-sm mb-0.5">Due Date</p>
                  <div className="flex gap-2 rounded border border-primary-gray-280 px-2 py-1.5">
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="flex flex-row items-center justify-center">
                          <div className="relative cursor-pointer text-primary-gray-1000 font-lato text-xs ">
                            {moment(dueDate).format("l") == moment().format("l")
                              ? "Today"
                              : moment(dueDate).add(7, "days").format("l") ==
                                moment().add(7, "days").format("l")
                              ? "This Week"
                              : moment(dueDate).format("l")}
                          </div>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          style={{
                            borderRadius: 8,
                            // Index === 0 ? "20px 0px 20px 20px" : "20px 20px 0px 20px",
                          }}
                          className={`origin-bottom-right top-6 absolute  w-auto z-30 shadow-lg px-3 py-3 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                        >
                          <div className="py-1 max-h-64 overflow-y-auto space-y-1.5">
                            <Menu.Item>
                              {({ active }) => (
                                <div
                                  onClick={() => {
                                    setDueDate(moment().format("YYYY-MM-DD"));
                                  }}
                                  className="relative cursor-pointer text-primary-gray-1000 py-1.5 hover:underline font-lato text-xs "
                                >
                                  Today
                                </div>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <div
                                  onClick={() => {
                                    setDueDate(
                                      moment()
                                        .add(7, "days")
                                        .format("YYYY-MM-DD")
                                    );
                                  }}
                                  className="relative cursor-pointer text-primary-gray-1000 py-1.5 hover:underline font-lato text-xs "
                                >
                                  This Week
                                </div>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <MobileDatePicker
                                    value={dayjs(dueDate)}
                                    onChange={(value) => {
                                      setDueDate(value);
                                    }}
                                    sx={{
                                      width: "100%",
                                      ".MuiInputBase-input": {
                                        padding: "4px 8px",
                                        fontSize: ".8rem",
                                      },
                                    }}
                                    renderInput={({ inputRef, inputProps }) => (
                                      <div className="flex w-full flex-col items-center relative cursor-pointer text-primary-gray-1000 py-1.5 hover:underline font-lato text-xs max-w-max">
                                        {dueDate ? (
                                          <input
                                            ref={inputRef}
                                            {...inputProps}
                                            className="text-black w-24 font-lato font-normal focus:border-0 focus:ring-0 focus:outline-none"
                                          ></input>
                                        ) : (
                                          <input
                                            ref={inputRef}
                                            {...inputProps}
                                            placeholder="Custom"
                                            className="text-black w-24 bg-transparent border-0 p-0 focus:border-0 focus:ring-0 focus:outline-none"
                                          ></input>
                                        )}
                                      </div>
                                    )}
                                  />
                                </LocalizationProvider>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
                <div>
                  <p className="font-semibold text-sm mb-0.5">Add priority</p>
                  <div className="flex flex-wrap">
                    <PriorityDropdown
                      priorityValue={priority}
                      setPriorityValue={(val) => setPriority(val)}
                    />
                  </div>
                </div>
                <div>
                  <p className="font-semibold text-sm mb-0.5">
                    Add T-shirt size
                  </p>
                  <div className="flex flex-wrap">
                    <TShirtSizeDropdown size={size} setSize={setSize} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="flex flex-col gap-2 mt-4">
          {taskLoading ? (
            [1, 2, 3, 4, 5, 6]?.map((item) => {
              return (
                <div className="shadow-md p-4 bg-white">
                  <div className="flex justify-between items-center font-lato mb-2">
                    <p className="text-xs text-primary-gray-350">Due by:</p>
                  </div>{" "}
                  <div className="flex justify-between items-start font-karla">
                    <p className="text-sm h-3 rounded w-10/12 bg-primary-gray-250 animate-pulse font-medium text-primary-gray-1000"></p>
                    {/* <PriorityButton priority={null} /> */}
                  </div>
                  <div className="flex justify-between items-end font-karla">
                    <p className="flex items-center gap-1 text-sm font-semibold text-primary-green-600 underline">
                      <IoEyeOutline size={16} /> Recommend to focus
                    </p>
                    <div className="relative cursor-pointer text-primary-gray-1000 mt-1 ">
                      <TShirt className="w-8 h-8" />
                      <span className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 text-3xs">
                        {"?"}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })
          ) : tasks?.length > 0 ? (
            tasks.map((task) => {
              return (
                <div className="shadow-md p-4 bg-white" key={task.id}>
                  <div className="flex justify-between items-center font-lato mb-1">
                    <p className="text-xs text-primary-gray-350">
                      Due by: {task.date}
                    </p>
                  </div>{" "}
                  <div className="flex justify-between items-start font-karla">
                    <p className="text-sm font-medium text-primary-gray-1000">
                      {task.title}
                    </p>
                    {task.priority && (
                      <PriorityButton priority={task.priority} />
                    )}
                  </div>
                  <div className="flex justify-between items-end font-karla">
                    <p className="flex items-center gap-1 text-sm font-semibold text-primary-green-600 underline">
                      <IoEyeOutline size={16} /> Recommend to focus
                    </p>
                    <div className="relative cursor-pointer text-primary-gray-1000 mt-1 ">
                      <TShirt className="w-8 h-8" />
                      <span className="absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2 text-3xs">
                        {task.t_shirt_size || "?"}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <EmptyState
              text={`No tasks found for ${member?.first_name}`}
              image="/assets/images/empty/tasks.png"
            />
          )}
        </div>
      </div>
    </Drawer>
  );
}
