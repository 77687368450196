import React from "react";
import { ChevronRightIcon } from "@heroicons/react/solid";

function SquadCard({ profile, onSelect }) {
  return (
    <div className="py-2.5 border-b border-primary-gray-50">
      <div
        onClick={() => {
          onSelect(profile);
        }}
        className="flex flex-row items-center relative "
      >
        <div
          className={`flex flex-row items-center space-x-1 text-xs text-primary-indigo-650 cursor-pointer absolute top-40% right-0`}
        >
          <ChevronRightIcon className="w-4 h-4" />
        </div>
        <div className="h-12 w-12 rounded-full shadow flex flex-row items-center justify-center relative">
          <img
            className="h-11 w-11  rounded-full object-cover"
            src={
              (profile?.image && profile?.image?.url) ||
              "/assets/svg/defaultUser.svg"
            }
            alt=""
          />
          <div
            className={`${
              profile?.is_active ? "bg-active" : "bg-inactive"
            } w-2 h-2 rounded-full absolute top-0 right-0`}
          ></div>
        </div>

        <div className={`cursor-pointer w-10/12 ml-4`}>
          <h3 className="text-primary-gray-600 inter text-sm">
            {profile?.first_name}
          </h3>
          <h3 className="text-2xs text-primary-gray-350 inter">
            {profile?.designation?.rank?.rank_name}
          </h3>
          <h3 className="text-2xs text-primary-gray-350 inter">
            {profile?.designation?.role?.role_name}
          </h3>
        </div>
      </div>
      <div className="flex ml-3 flex-row mt-2 items-center space-x-5 text-xs inter text-primary-gray-600">
        <div className="flex flex-row items-center">
          <img
            src="/assets/images/icons/taskIndigo.svg"
            className="w-4 h-4 mr-0.5 mt-0.5"
            alt=""
          />
          {profile?.tasksCount || 0} tasks
        </div>
        <div className="flex flex-row items-center">
          <img
            src="/assets/images/icons/rocketIndigo.svg"
            className="w-4 h-4 mr-0.5 mt-0.5"
            alt=""
          />
          {profile?.projectsCount || 0} projects
        </div>
      </div>
    </div>
  );
}

export default SquadCard;
