import { useEffect, useState } from "react";
import * as TalentRequestAPI from "config/APIs/talentRequests";
import EmptyState from "components/Comman/EmptyState";

export default function RequestsDetails({ selectedProject, selectedRequest }) {
  const [request, setRequest] = useState([]);

  useEffect(() => {
    if (selectedRequest) {
      getRequest();
    }
  }, [selectedRequest]);

  const getRequest = async () => {
    try {
      const response = await TalentRequestAPI.getOneEntity(selectedRequest?.id);

      setRequest(response?.data?.data);
    } catch (err) {
      console.log("Error", err);
    }
  };

  return (
    <>
      <div className="w-full h-full border bg-white border-gray-100 rounded-md p-4 px-6 shadow-md">
        <div className="flex justify-between items-center">
          <h2 className="font-extrabold text-xl text-black font-inter">
            Details
          </h2>
        </div>

        <div className="flex flex-col mt-3 gap-6">
          {selectedRequest ? (
            <></>
          ) : (
            <EmptyState
              image="/assets/images/empty/member.svg"
              text="Select a talent request to view their details!"
            />
          )}
        </div>
      </div>
    </>
  );
}
