import { redirectToUrl } from "config/APIs/shortUrls";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";

function ShortenedUrl() {
  const dispatch = useDispatch();
  const getRedirect = async () => {
    try {
      const res = await redirectToUrl({ url: window.location.href });
      if (res?.data?.url) {
        window.location.replace(res?.data?.url);
      } else {
        dispatch(
          showToast({
            message: "Couldn't find url to redirect!",
            type: "error",
          })
        );
      }
    } catch (e) {
      console.log("Redirect API: ", e);
      dispatch(
        showToast({
          message: e?.response?.data?.message,
          type: "error",
        })
      );
    }
  };

  useEffect(() => {
    getRedirect();
  }, []);

  return (
    <div className="flex flex-row items-center justify-center w-screen h-screen">
      <div className="w-8 h-8 bg-transparent border-primary-red-medium border-2 rounded-full border-t-0 animate-spin" />
    </div>
  );
}

export default ShortenedUrl;
