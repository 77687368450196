import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { showToast } from "redux/toaster";
import * as TaskAPI from "config/APIs/task/task";
import { QuestionMarkCircleIcon, UserCircleIcon } from "@heroicons/react/solid";
import { useHistory } from "react-router-dom";
import StatusDropDown from "./StatusDropDown";
import moment from "moment";
import PriorityDropdown from "./PriorityDropdown";
import { getProjectName } from "helpers/utils/task";

function TaskCard({
  task,
  setShowAddModal,
  onUpdate,
  showAssignedBy,
  showAssignedTo,
}) {
  let isMountedP = useRef(false);
  let isMountedS = useRef(false);
  const user = useSelector((state) => state.user.user);

  const history = useHistory();
  const [priorityValue, setPriorityValue] = useState(task?.priority);
  const [status, setStatus] = useState(task?.status);

  const updateTask = async (field, val) => {
    let newData = { ...task };
    newData[field] = val;
    try {
      let body = {
        task: newData,
      };
      const response = await TaskAPI.updateTasks(task?.id, body);
      if (onUpdate) {
        onUpdate(task?.id, val, field);
      }
    } catch (err) {
      switch (err.response?.status) {
        case 401:
          showToast({ message: "Unauthorized!", type: "error" });
          break;
        default:
          showToast({ message: "Something went wrong!", type: "error" });
      }
    }
  };

  useEffect(() => {
    if (isMountedP.current) {
      updateTask("priority", priorityValue);
    } else {
      isMountedP.current = true;
    }
  }, [priorityValue]);

  useEffect(() => {
    if (isMountedS.current) {
      updateTask("status", status);
    } else {
      isMountedS.current = true;
    }
  }, [status]);

  return (
    <>
      <div
        key={task?.id}
        className="p-5 flex flex-col w-full space-y-2 bg-white rounded-20px"
      >
        <div className="flex flex-row items-start justify-between space-x-2 relative">
          <div className="flex flex-col items-start w-9/12">
            <p
              onClick={() =>
                history.push({
                  pathname: `/tasks/${task?.id}`,
                  state: {
                    task: task,
                  },
                })
              }
              className="text-sm leading-4 font-inter text-primary-gray-1000 cursor-pointer break-words font-bold"
            >
              {task.title}
            </p>
          </div>
          {/* <PriorityDropdown
            disabled={task?.owner?.id === user?.id}
            priorityValue={priorityValue}
            setPriorityValue={setPriorityValue}
          /> */}
        </div>
        {task?.project && (
          <div className="flex flex-row items-center space-x-1">
            <img
              src={task?.project?.image?.url || "/assets/svg/project.svg"}
              alt="member"
              className="w-3 h-3 bg-primary-red-dark text-white rounded-full flex-shrink-0 shadow-lg"
            />

            <p className="text-primary-gray-280 font-inter text-2xs">
              {getProjectName(task)}
            </p>
          </div>
        )}
        <div className="flex flex-row items-center justify-between">
          {showAssignedBy ? (
            <div className="flex flex-row items-center justify-start">
              <p className="text-primary-gray-250 font-inter text-2xs mr-1">
                Assigned by:{" "}
              </p>
              {task?.creator?.id == user?.id ? (
                <div className="flex flex-row items-center space-x-1">
                  {task?.creator?.image?.url ? (
                    <img
                      src={task?.creator?.image?.url}
                      alt="member"
                      className="w-3 h-3 bg-primary-red-dark text-white rounded-full flex-shrink-0 shadow-lg"
                    />
                  ) : (
                    <UserCircleIcon className="w-3 h-3 bg-primary-red-dark text-white rounded-full flex-shrink-0 shadow-lg" />
                  )}
                  <p className="text-primary-red-dark font-inter font-bold text-2xs">
                    {/* {task?.creator?.first_name} */}
                    Self
                  </p>
                </div>
              ) : (
                <div className="flex flex-row items-center space-x-1">
                  {task?.creator?.image?.url ? (
                    <img
                      src={task?.creator?.image?.url}
                      alt="member"
                      className="w-3 h-3 bg-primary-red-dark text-white rounded-full flex-shrink-0 shadow-lg"
                    />
                  ) : (
                    <UserCircleIcon className="w-3 h-3 bg-primary-red-dark text-white rounded-full flex-shrink-0 shadow-lg" />
                  )}
                  <p className="text-primary-red-dark font-inter font-bold text-2xs">
                    {task?.creator?.first_name}
                  </p>
                </div>
              )}
            </div>
          ) : showAssignedTo ? (
            <div
              onClick={() => {
                setShowAddModal(task);
              }}
              className="flex flex-row items-center justify-start"
            >
              <p className="text-primary-gray-250 font-inter text-2xs mr-1">
                Assigned to:{" "}
              </p>
              {task?.owner ? (
                <div className="flex flex-row items-center space-x-1">
                  {task?.owner?.image?.url ? (
                    <img
                      src={task?.owner?.image?.url}
                      alt="member"
                      className="w-3 h-3 bg-primary-red-dark text-white rounded-full flex-shrink-0 shadow-lg"
                    />
                  ) : (
                    <UserCircleIcon className="w-3 h-3 bg-primary-red-dark text-white rounded-full flex-shrink-0 shadow-lg" />
                  )}
                  <p className="text-primary-red-dark font-inter font-bold text-2xs">
                    {task?.owner?.first_name}
                  </p>
                </div>
              ) : (
                <QuestionMarkCircleIcon className="w-6 h-6 sm:w-10 sm:h-10 bg-gray-300 text-gray-500 rounded-full flex-shrink-0 shadow-lg" />
              )}
            </div>
          ) : (
            <div
              onClick={() => {
                setShowAddModal(task);
              }}
              className="flex flex-row items-center justify-start"
            >
              <p className="text-primary-gray-250 font-inter text-2xs mr-1">
                Owned by:{" "}
              </p>
              {task?.owner ? (
                <div className="flex flex-row items-center space-x-1">
                  {task?.owner?.image?.url ? (
                    <img
                      src={task?.owner?.image?.url}
                      alt="member"
                      className="w-3 h-3 bg-primary-red-dark text-white rounded-full flex-shrink-0 shadow-lg"
                    />
                  ) : (
                    <UserCircleIcon className="w-3 h-3 bg-primary-red-dark text-white rounded-full flex-shrink-0 shadow-lg" />
                  )}
                  <p className="text-primary-red-dark font-inter font-bold text-2xs">
                    {task?.owner?.first_name}
                  </p>
                </div>
              ) : (
                <QuestionMarkCircleIcon className="w-6 h-6 sm:w-10 sm:h-10 bg-gray-300 text-gray-500 rounded-full flex-shrink-0 shadow-lg" />
              )}
            </div>
          )}
          <StatusDropDown
            disabled={task?.owner?.id !== user?.id}
            status={status}
            setStatus={setStatus}
            index={0}
          />
        </div>
        <div className="text-primary-gray-280 flex flex-row items-center justify-between">
          <p className="font-inter text-2xs">
            Due: {moment(task?.date).format("LL")}
          </p>
          <div
            onClick={() => {
              history.push({
                pathname: `/tasks/${task?.id}`,
                state: {
                  task: task,
                },
              });
            }}
            className="flex flex-row items-center space-x-2 text-primary-gray-350 inter font-normal text-2xs"
          >
            <div className="flex flex-row items-center space-x-0.5">
              <img
                src="/assets/images/icons/comments.svg"
                alt=""
                className="w-2 h-3"
              />
              <p className="">{task?.commentsCount || 0}</p>
            </div>
            <div className="flex flex-row items-center space-x-0.5">
              <img
                src="/assets/images/icons/files.svg"
                alt=""
                className="w-2 h-3"
              />
              <p className="">{task?.filesCount || 0}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TaskCard;
