import React, { useEffect, useState } from "react";
import { RadioTabs } from "components/Comman/Tabs";
import SearchBox from "components/Comman/Inputs/SearchBox";
import { getReports } from "config/APIs/common";

const list = [
  { name: "No. of students in FTP ", key: "ftpCount" },
  { name: "No. of applicants in ftp ", key: "applicantInFTP" },
  { name: "No. of new applications on ftp", key: "newApplicantCount" },
  { name: "No. of events held ", key: "eventCount" },
  { name: "No. of sourcing drives conducted", key: "sourcingDriveCount" },
  { name: "No. of startup researches done ", key: "startupCount" },
  { name: "No. of students ME profiled ", key: "meProfiledCount" },
  { name: "No. of clock ins completed", key: "clockInCount" },
  { name: "No. of clock outs completed", key: "clockOutCount" },
  { name: "No. of core team members who didn't work", key: "noWorkCoreCount" },
];

function Reports() {
  const [selectedTab, setSelectedTab] = useState("today");
  const [count, setCount] = useState({});
  const [fetching, setFetching] = useState(false);
  const [search, setSearch] = useState({
    searchText: "",
    isSearch: false,
  });

  const getCount = async () => {
    try {
      let q = { time: selectedTab };
      const response = await getReports(q);
      let data = response.data.data?.stats;
      setCount(data);
    } catch (err) {
      console.log(err);
    }
    setFetching(false);
  };

  useEffect(() => getCount(), [selectedTab]);

  return (
    <>
      <div className="lg:px-5 lg:w-[80vw] mx-auto max-w-[1380px]">
        <div className="px-7.5 pt-2.5">
          <SearchBox
            placeholder="Search..."
            search={search}
            setSearch={setSearch}
          />
        </div>
        <div className="bg-white rounded-2xl mt-2.5">
          <div className="w-full border-b border-primary-gray-100 px-7.5 py-2">
            <RadioTabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              tabs={[
                {
                  label: "Today",
                  value: "today",
                },
                {
                  label: "This Week",
                  value: "thisWeek",
                },
                {
                  label: "This Month",
                  value: "thisMonth",
                },
              ]}
            />
          </div>
          <div className="bg-primary-gray-50 w-full px-4 pt-4 pb-20 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 h-70vh lg:h-auto overflow-y-auto">
            {list?.map((item) => {
              if (
                search?.searchText == "" ||
                (search?.searchText != "" &&
                  item?.name
                    ?.toLowerCase()
                    ?.includes(search?.searchText?.toLowerCase()))
              )
                return (
                  <div className="relative shadow cursor-pointer hover:border hover:border-primary-red-lighter hover:bg-opacity-50 hover:bg-primary-red-30 bg-white p-4 rounded-2xl w-full h-40 flex flex-col justify-between space-y-4">
                    <h1 className="text-primary-gray-800 font-karla text-base font-medium">
                      {item?.name}
                    </h1>
                    <p
                      className={`flex flex-row justify-end text-5xl font-medium font-karla ${
                        count[item?.key] < 1
                          ? "text-primary-red-lightest"
                          : count[item?.key] > 0 && count[item?.key] < 5
                          ? "text-primary-dawn-300"
                          : count[item?.key] > 4 && count[item?.key] < 10
                          ? "text-primary-yellow-100"
                          : count[item?.key] > 9
                          ? "text-primary-red-lighter"
                          : "text-primary-red-lighter"
                      }`}
                    >
                      {count[item?.key] || 0}
                    </p>
                    <div className="absolute bottom-0 left-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="76"
                        height="76"
                        viewBox="0 0 76 76"
                        fill="none"
                      >
                        <g filter="url(#filter0_i_2335_28541)">
                          <path
                            d="M30 0L46.2635 29.7365L76 46L46.2635 62.2635L30 92L13.7365 62.2635L-16 46L13.7365 29.7365L30 0Z"
                            fill="#FAFAFA"
                          />
                        </g>
                        <defs>
                          <filter
                            id="filter0_i_2335_28541"
                            x="-16"
                            y="0"
                            width="92"
                            height="94"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="BackgroundImageFix"
                              result="shape"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dy="2" />
                            <feGaussianBlur stdDeviation="2" />
                            <feComposite
                              in2="hardAlpha"
                              operator="arithmetic"
                              k2="-1"
                              k3="1"
                            />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="shape"
                              result="effect1_innerShadow_2335_28541"
                            />
                          </filter>
                        </defs>
                      </svg>
                    </div>
                  </div>
                );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Reports;
