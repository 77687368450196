import ReportsStory from "components/Reports/Reports/ReportsStory";
import {
  getAProjectReports,
  getAUserReports,
  getProjectGoalReports,
  getProjectReports,
  getUsersReports,
} from "config/APIs/reports";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import queryString from "query-string";
import { ProjectTypes } from "helpers/projects";
import { ranksList } from "helpers/constants/ranks";

function DailyReports() {
  const { report } = useParams();
  const [reports, setReports] = useState({});

  const getReports = async () => {
    const queryParams = queryString.parse(window.location.search);

    switch (report) {
      case "project": {
        // URL - /dailyReports/project?type=WorkStudy
        const response = await getProjectReports({
          is_active: true,
          status: "Live",
          ...queryParams,
        });

        if (response.status === 200) {
          let data = response.data.data;
          let obj = {
            ...queryParams,
            report,
            name: ProjectTypes?.find((p) => p?.value == queryParams?.type)
              ?.label,
          };

          obj["reports"] = data;
          setReports(obj);
        }
        break;
      }
      case "user": {
        // URL - /dailyReports/user?rankName=level-5-student-professional
        let rankName = queryParams?.rankName?.replaceAll("-", " ");
        delete queryParams?.rankName;
        const response = await getUsersReports({
          is_active: true,
          ...queryParams,
          rankName,
        });

        if (response.status === 200) {
          let data = response.data.data;
          let obj = {
            ...queryParams,
            report,
            name: ranksList?.find((p) => p?.value == rankName)?.label,
            rankName: rankName?.replaceAll(" ", "-"),
          };

          obj["reports"] = data;
          setReports(obj);
        }
        break;
      }
      case "userStory": {
        // URL - /dailyReports/userStory?id=
        const response = await getAUserReports(queryParams?.id);

        if (response.status === 200) {
          let data = response.data.data;
          data["name"] =
            data?.first_name + " " + (data?.last_name ? data?.last_name : "");
          data["report"] = report;
          setReports(data);
        }
        break;
      }
      case "projectStory": {
        // URL - /dailyReports/projectStory?id=
        const response = await getAProjectReports(queryParams?.id);

        if (response.status === 200) {
          let data = response.data.data;
          data["name"] = data?.title;
          data["report"] = report;
          let tasks = data?.tasks;

          let goals = [];
          let unassigned = [];

          tasks?.map((t) => {
            if (t?.goal || t?.story?.epic?.goal) {
              let g = t?.goal || t?.story?.epic?.goal;
              if (goals?.find((e) => e?.id == g?.id)) {
                let idx = goals?.findIndex((e) => e?.id == g?.id);
                let ta = goals[idx]["tasks"];
                goals[idx]["tasks"] = ta.push(t);
              } else {
                goals.push({
                  ...g,
                  tasks: [t],
                  image: "/assets/svg/tabbar/taskGray.svg",
                });
              }
            } else {
              unassigned.push(t);
            }
          });
          if (unassigned?.length > 0) {
            goals.push({
              id: 0,
              title: "Goal not assigned",
              tasks: unassigned,
            });
          }
          data["goals"] = goals;
          setReports(data);
        }
        break;
      }
      case "projectGoal": {
        // URL - /dailyReports/projectGoal?time=&type=
        const response = await getProjectGoalReports({
          is_active: true,
          status: "Live",
          ...queryParams,
        });
        if (response.status === 200) {
          let data = response.data.data;
          let obj = {
            ...queryParams,
            report,
            name: "Weekly Goals",
          };

          obj["reports"] = data;
          setReports(obj);
        }
        break;
      }
      default:
    }
  };

  useEffect(() => {
    getReports();
  }, []);

  return <ReportsStory selectedReport={reports} />;
}

export default DailyReports;
