import EmptyState from "components/Comman/EmptyState";
import React from "react";

function UsersLearning() {
  return (
    <div className="w-full px-5 py-4">
      <div className="h-40vh w-full flex flex-row items-center justify-center">
        <EmptyState imageMedium image={"/assets/images/empty/learning.svg"} />
      </div>
    </div>
  );
}

export default UsersLearning;
