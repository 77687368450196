import { instance, getHeader } from "config";

/**
 * The file contains all DSR APIs
 */

/**
 * To get Projects Reports
 */
export const getProjectGoalReports = (query = {}) =>
  instance.get(
    `/admin/tools/dsr/projects/goals${
      Object.keys(query)?.length > 0 ? `?${new URLSearchParams(query)}` : ""
    }`,
    getHeader()
  );

/**
 * To get Projects Reports
 */
export const getProjectReports = (query = {}) =>
  instance.get(
    `/admin/tools/dsr/projects${
      Object.keys(query)?.length > 0 ? `?${new URLSearchParams(query)}` : ""
    }`,
    getHeader()
  );

export const getAProjectReports = (id) =>
  instance.get(`/admin/tools/dsr/projects/${id}`, getHeader());

/**
 * To get Users Reports
 */
export const getUsersReports = (query = {}) =>
  instance.get(
    `/admin/tools/dsr/users${
      Object.keys(query)?.length > 0 ? `?${new URLSearchParams(query)}` : ""
    }`,
    getHeader()
  );

export const getAUserReports = (id) =>
  instance.get(`/admin/tools/dsr/users/${id}`, getHeader());
