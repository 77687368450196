import { useState, useEffect } from "react";
import Toggle from "components/Comman/Inputs/Toggle";

import EmptyState from "components/Comman/EmptyState";
import * as CGCAPI from "config/APIs/college";

function CollegeInsights({ details, events }) {
  return (
    <>
      <div className="w-full pt-2.5">
        <div className="space-y-5 max-h-70vh overflow-y-auto pb-16 px-5">
          <div className="p-5 flex flex-row items-center bg-white shadow-container rounded-20px text-primary-gray-1000">
            <h1 className="text-2xl font-bold w-1/3 text-center">
              {events?.length || 0}
            </h1>
            <p className="text-sm">Upcoming Events</p>
          </div>
          <div className="p-5 flex flex-row items-center bg-white shadow-container rounded-20px text-primary-gray-1000">
            <h1 className="text-2xl font-bold w-1/3 text-center">
              {details?.activeMembers || 0}
            </h1>
            <p className="text-sm">Active Members</p>
          </div>
          <div className="p-5 flex flex-row items-center bg-white shadow-container rounded-20px text-primary-gray-1000">
            <h1 className="text-2xl font-bold w-1/3 text-center">
              {details?.members || 0}
            </h1>
            <p className="text-sm">Members</p>
          </div>
          <div className="p-5 flex flex-row items-center bg-white shadow-container rounded-20px text-primary-gray-1000">
            <h1 className="text-2xl font-bold w-1/3 text-center">
              {details?.recentlyJoined || 0}
            </h1>
            <p className="text-sm">Recently Joined</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default CollegeInsights;
