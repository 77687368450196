export const ranksList = [
  {
    value: "jsquad a",
    label: "JSquad A",
  },
  {
    value: "level 5 student professional",
    label: "Team Owners",
  },
  {
    value: "level 4 student professional",
    label: "Team leads",
  },
  {
    value: "level 3 student professional",
    label: "Team contributors",
  },
  {
    value: "level 2 student professional",
    label: "Individual contributors",
  },
  {
    value: "level 1 student professional",
    label: "Learners",
  },
];
