import { Clock } from "@phosphor-icons/react";
import moment from "moment";
export default function EventItem({ item }) {
  return (
    <div className="px-4 py-2 border-l-2 border-red-500 shadow-2xl rounded-sm">
      <h3 className="text-sm font-semibold text-black mb-1">{item?.title}</h3>
      <div className="flex text-xs items-center gap-1 text-gray-500">
        <Clock size={12} />
        <span>
          {moment(item?.startDate).format("ddd, hA")}{" "}
          {item?.endDate && `to ${moment(item?.endDate).format("ddd, hA")}`}
        </span>
      </div>
      <p className="text-xs my-2.5 text-gray-500 line-clamp-4">
        {item?.description}
      </p>
      <div className="flex mb-2 -space-x-1.5">
        {item?.attendees?.length > 0
          ? item?.attendees?.slice(0, 4)?.map((a) => {
              return (
                <img
                  className="w-6 h-6 rounded-full"
                  src={
                    a?.image?.url ||
                    "https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=2000"
                  }
                  alt=""
                />
              );
            })
          : "NA"}
        {item?.attendees?.length > 4 && (
          <div className="text-red-500 text-sm font-bold pl-4">
            +{item?.attendees?.length - 4}
          </div>
        )}{" "}
      </div>
      <p className="text-sm">
        Owner:{" "}
        <span className="font-semibold">{item?.owner?.first_name || "-"}</span>
      </p>
    </div>
  );
}
