import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import UsersLearning from "components/SquadUser/UsersLearning";
import UsersTask from "components/SquadUser/UsersTask";
import * as TaskAPI from "config/APIs/task/task";
import { useLocation } from "react-router-dom";
import { getUserDetails } from "config/APIs/squad";
import { UserIcon } from "@heroicons/react/outline";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { useHistory } from "react-router-dom";
import UserMessages from "components/SquadUser/UserMessages";
import { useSelector } from "react-redux";
import Tabs from "components/Comman/Tabs";

const tabs = [
  { label: "Tasks", value: "tasks" },
  { label: "Learning", value: "learning" },
  { label: "Messages", value: "messages" },
];

function SquadUser() {
  const { id } = useParams();
  const user = useSelector((state) => state.user.user);
  const location = useLocation();
  const history = useHistory();
  const [details, setDetails] = useState({});
  const [fetching, setFetching] = useState(false);
  const [fetchingMessages, setFetchingMessages] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [selectedTab, setSelectedTab] = useState("tasks");
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      getMemberDetails();
      getTasks();
    }

    return () => {
      isMounted = false;
    };
  }, [id]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted && tasks.length > 0 && Object.keys(details).length > 0) {
      getMessages();
    }

    return () => {
      isMounted = false;
    };
  }, [tasks, details]);

  const getMessages = async () => {
    setFetchingMessages(true);
    let msg = [];
    await tasks.map(async (item) => {
      let obj = { taskId: item?.id, title: item?.title, comments: [] };

      try {
        const response = await TaskAPI.getAllComments({ task: item?.id });
        const c = response.data.data.response;
        let comments = [];
        c.map((comment) => {
          if (
            comment?.message?.includes(details?.first_name) ||
            comment?.message?.includes(user?.first_name.split(" ")[0]) ||
            comment.users.find((e) => e?.first_name == details?.first_name) ||
            comment.users.find((e) => e?.first_name == user?.first_name)
          ) {
            comments.push(comment);
          }
        });
        obj.comments = comments;
      } catch (err) {
        console.log("Fetch task comment error", err);
      }

      if (obj.comments.length > 0) {
        msg.push(obj);
      }
    });
    setMessages(msg);
    setFetchingMessages(false);
  };

  const getMemberDetails = async () => {
    try {
      const response = await getUserDetails(id);
      const user = response.data.data?.user;
      setDetails(user);
    } catch (err) {
      console.log("Fetch user error", err);
    }
  };

  const getTasks = async () => {
    setFetching(true);

    TaskAPI.getUsersTasks(id)
      .then((res) => {
        let resp = res.data.data.tasks;
        setTasks(resp);
        setFetching(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="w-full pt-2.5 px-2.5">
        <div className="w-full px-5 relative">
          <div className="absolute top-3 right-5">
            <ArrowLeftIcon
              onClick={() => {
                history.goBack();
              }}
              className="w-4 text-black mr-2"
            />
          </div>
          <div
            className="p-5 flex flex-col w-full bg-white"
            style={{ borderRadius: "20px" }}
          >
            <div className="flex flex-row items-center space-x-2.5">
              <div
                className={`w-10 h-10 bg-gray-200 flex flex-row items-center justify-center rounded-full `}
              >
                {details?.image?.url ? (
                  <img
                    src={details?.image?.url}
                    className="rounded-full h-10 w-10"
                    alt=""
                  />
                ) : (
                  <UserIcon className="rounded-full h-7 w-7 text-gray-500" />
                )}
              </div>
              <div className="">
                <p className="text-xs font-inter text-primary-gray-900 font-normal leading-4 cursor-pointer break-all">
                  {details?.first_name}
                </p>

                <p className="text-2xs tracking-normal font-inter font-normal leading-3 text-primary-gray-250 max-w-max cursor-pointer">
                  {details?.designation?.role?.role_name}
                </p>
                <p className="text-2xs tracking-normal font-inter font-normal leading-3 text-primary-gray-250 max-w-max cursor-pointer">
                  {details?.designation?.rank?.rank_name}
                </p>
              </div>
            </div>
          </div>
        </div>
        <Tabs
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </div>
      <div className="max-h-60vh overflow-y-auto px-2.5">
        {selectedTab == "learning" && <UsersLearning userId={id} />}
        {selectedTab == "tasks" && (
          <UsersTask tasks={tasks} fetching={fetching} getTasks={getTasks} />
        )}
        {selectedTab == "messages" && <UserMessages messages={messages} />}
      </div>
    </>
  );
}

export default SquadUser;
