import UserTasksDrawer from "./UserTasksDrawer";
import { useEffect, useState } from "react";
import { getUsersTasks } from "config/APIs/task/task";
import moment from "moment";
import EmptyState from "components/Comman/EmptyState";
import ChronosButton from "components/Comman/Buttons";

const getClassName = (type) => {
  switch (type) {
    case "completed":
      return {
        text: "text-primary-green-medium",
        title: "Completed Tasks",
      };
    case "overdue":
      return {
        text: "text-primary-red-medium",
        title: "Overdue Tasks",
      };
    case "incomplete":
      return {
        text: "text-primary-yellow-medium",
        title: "Incomplete Tasks",
      };
    case "total":
      return {
        text: "text-primary-gray-1000",
        title: "Total Tasks",
      };
    default:
      return "";
  }
};

const Card = ({ type, stats }) => {
  const { text, title } = getClassName(type);
  return (
    <div
      className={`${text} bg-primary-gray-100 rounded-md p-3 flex flex-row items-center space-x-4`}
    >
      <p className="text-3xl">{stats[type] || 0}</p>
      <div className="flex gap-4 items-center text-primary-gray-600 font-light font-inter">
        {title}
      </div>
      {/* <p className="text-xs">Increased by 6 this week</p> */}
    </div>
  );
};

export default function Individual({ member, projectId }) {
  const [stats, setStats] = useState({});
  const [fetching, setFetching] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [tasks, setTasks] = useState();
  const [events, setEvents] = useState([]);

  const fetchStat = async () => {
    setFetching(true);
    try {
      const response = await getUsersTasks(member.id, {
        project_id: projectId,
      });
      const tasksData = response.data.data.tasks;
      setTasks(tasksData);

      setStats(response.data.data?.stats);
    } catch (err) {
      console.log(err);
    }
    setFetching(false);
  };

  useEffect(() => {
    if (member?.id) {
      fetchStat();
    }
  }, [member?.id]);

  return (
    <>
      <div className="w-full h-full bg-white border border-gray-100 rounded-md p-4 px-6 shadow-md">
        <div className="flex flex-row font-inter items-center justify-between space-x-2">
          <div
            className={`flex items-center gap-4 cursor-pointer text-black rounded-lg hover:shadow`}
          >
            <img
              src={
                member?.image?.url ||
                "https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-social-media-user-portrait-176256935.jpg"
              }
              className={`h-8 w-8 rounded-full`}
            />
            <div>
              <h2 className="font-bold">{member?.first_name}</h2>
              <p className="text-sm capitalize">
                {member?.type || member?.userProjects?.type || "Member"}
              </p>
            </div>
          </div>
          {/* <button
            className="w-max mb-auto flex items-center gap-1 border p-1.5 border-black rounded text-xs text-black"
          >
            View Tasks
          </button>{" "} */}
        </div>
        {member ? (
          <div className="space-y-6 mt-3">
            <div className="space-y-4 pb-6 border-b">
              <h2 className="font-bold font-inter text-primary-gray-1000">
                Tasks Performance (week)
              </h2>
              <div className="grid grid-cols-2 gap-6">
                <Card type="completed" stats={stats} />
                <Card type="overdue" stats={stats} />
                <Card type="incomplete" stats={stats} />
                <Card type="total" stats={stats} />
              </div>
            </div>
            <div className="space-y-4 pb-6 border-b">
              <h2 className="font-bold font-inter text-primary-gray-1000">
                Tasks Assigned (week)
              </h2>
              <div className="grid grid-cols-2 gap-6">
                {!fetching ? (
                  tasks?.length > 0 ? (
                    tasks?.slice(0, 2)?.map((task) => {
                      return (
                        <div className="col-span-2 bg-primary-gray-100 rounded-md p-3 flex flex-col space-y-2 font-inter">
                          <div className="text-2xs text-primary-gray-600">
                            Due on <b>{moment(task?.date).format("ll")}</b>
                          </div>
                          <div className="text-sm text-primary-gray-1000 font-bold">
                            {task?.title}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="col-span-2">
                      <EmptyState
                        text={`${member?.first_name} has no tasks!`}
                        noImage
                      />
                    </div>
                  )
                ) : (
                  [1, 2]?.map((task) => {
                    return (
                      <div className="col-span-2 bg-primary-gray-100 rounded-md p-3 flex flex-col space-y-2 font-inter">
                        <div className="text-2xs text-primary-gray-600">
                          Due on <b>{moment(task?.date).format("ll")}</b>
                        </div>
                        <div className="text-sm h-2 bg-primary-gray-600 rounded animate-pulse font-bold">
                          {task?.title}
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
              <div className="flex flex-row justify-end">
                <ChronosButton
                  tertiary
                  underline
                  onClick={() => setIsDrawerOpen(true)}
                  text={tasks?.length > 0 ? "View All Tasks" : "Add Tasks"}
                />
              </div>
            </div>
            <div className="space-y-4 pb-6 border-b">
              <h2 className="font-bold font-inter text-primary-gray-1000">
                Events Upcoming
              </h2>
              <div className="grid grid-cols-2 gap-6">
                {!fetching ? (
                  events?.length > 0 ? (
                    events?.slice(0, 2)?.map((event) => {
                      return (
                        <div className="col-span-2 bg-primary-gray-100 rounded-md p-3 flex flex-col space-y-2 font-inter">
                          <div className="text-2xs text-primary-gray-600">
                            <b>{moment(event?.startDate).format("ll")}</b>
                          </div>
                          <div className="text-sm text-primary-gray-1000 font-bold">
                            {event?.title}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div className="col-span-2">
                      <EmptyState
                        text={`${member?.first_name} has no upcoming events!`}
                        noImage
                      />
                    </div>
                  )
                ) : (
                  [1, 2]?.map((event) => {
                    return (
                      <div className="col-span-2 bg-primary-gray-100 rounded-md p-3 flex flex-col space-y-2 font-inter">
                        <div className="text-2xs text-primary-gray-600">
                          <b>{moment(event?.startDate).format("ll")}</b>
                        </div>
                        <div className="text-sm h-2 bg-primary-gray-600 rounded animate-pulse font-bold">
                          {event?.title}
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
              <div className="flex flex-row justify-end">
                <ChronosButton
                  tertiary
                  underline
                  onClick={() => setIsDrawerOpen(true)}
                  text={events?.length > 0 ? "View All Events" : "Add Events"}
                />
              </div>
            </div>
          </div>
        ) : (
          <EmptyState
            image="/assets/images/empty/member.svg"
            text="Select a member to view their details!"
          />
        )}
      </div>
      <UserTasksDrawer
        userTasks={tasks}
        onUpdate={getUsersTasks}
        member={member}
        isOpen={isDrawerOpen}
        projectId={projectId}
        closeModal={() => setIsDrawerOpen(false)}
      />
    </>
  );
}
