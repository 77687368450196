import Members from "./Members";
import Projects from "components/Comman/Lists/ProjectLists/Projects";
import Individual from "./Individual";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { PROJECT_TYPE_FWS } from "helpers/projects";

export default function AllPrograms() {
  const projects = useSelector((state) => state.projects?.typeWiseList);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);
  useEffect(() => {
    if (projects[PROJECT_TYPE_FWS]?.length > 0) {
      let p = projects[PROJECT_TYPE_FWS];
      setSelectedProject(p[0]);
      setSelectedMember(p[0].owner ? p[0].owner : p[0].members[0]);
    }
  }, [projects]);
  return (
    <div className="relative ">
      <div className="h-90vh grid grid-cols-3 gap-6 mt-5">
        <Projects
          editable
          title="Cohort"
          projects={projects[PROJECT_TYPE_FWS] || []}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
        />
        <Members
          project={selectedProject}
          selectedMember={selectedMember}
          setSelectedMember={setSelectedMember}
        />
        <Individual member={selectedMember} projectId={selectedProject?.id} />
      </div>
    </div>
  );
}
