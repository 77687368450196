import { useState } from "react";

function SimpleTextArea({
  label,
  field,
  details = {},
  setDetails,
  srOnly = false,
  isDisabled,
  showError,
  required = false,
  errorMessage = "Please fill in the details",
  bigText,
  placeholder,
  type,
  boldText,
  autoFocus = false,
  horizontal,
  rows = 3,
}) {
  const [error, setError] = useState();
  return (
    <>
      <div
        className={`flex flex-col items-start space-y-0.5 ${
          horizontal
            ? "lg:flex-row lg:items-center lg:space-y-0 lg:space-x-2.5"
            : ""
        } w-full`}
      >
        <label
          htmlFor={label}
          className={`${srOnly ? "sr-only" : "input-label"} ${
            horizontal && " lg:w-3/12"
          }`}
        >
          {required ? label + "*" : label}
        </label>
        <div className={`theme-input w-full ${horizontal && "lg:w-9/12"}`}>
          <textarea
            autoFocus={autoFocus}
            id={label}
            name={label}
            rows={rows}
            disabled={isDisabled}
            placeholder={placeholder ? placeholder : `Enter ${label}`}
            value={details[field] || ""}
            onChange={(e) => {
              setError();
              setDetails({ ...details, [field]: e.target.value });
            }}
            className={`w-full appearance-none border-0 text-primary-gray-1000 font-inter ${
              boldText ? "font-bold" : "font-normal"
            }  ${
              bigText ? "text-2xl" : "text-sm"
            } leading-6 placeholder:text-primary-gray-300 bg-transparent focus:outline-none focus:ring-transparent`}
          ></textarea>
          {((showError &&
            (!details[field] || (details[field] && details[field] == ""))) ||
            error) && (
            <p className="text-alert text-2xs flex flex-row items-center mt-1 ml-0.5">
              {error || errorMessage}
            </p>
          )}
          {showError && errorMessage && (
            <p className="text-alert text-2xs flex flex-row items-center mt-1 ml-0.5">
              {errorMessage}
            </p>
          )}{" "}
        </div>
      </div>
    </>
  );
}

export default SimpleTextArea;
