import EmptyState from "components/Comman/EmptyState";
import Toggle from "components/Comman/Inputs/Toggle";
import SquadCard from "components/MySquad/SquadCard/index.js";
import { useHistory } from "react-router-dom";

function CollegeMembers({ list, active, setActive }) {
  const history = useHistory();
  return (
    <>
      <div className="pb-2.5 px-7.5 flex flex-row items-center justify-between">
        <h1 className="text-xs font-inter font-normal w-full text-primary-gray-900 flex flex-row items-center">
          Showing:{" "}
          <p className="font-bold ml-0.5">
            {list?.length || 0} {active ? "active" : ""} members
          </p>
        </h1>{" "}
        <Toggle
          label="Active"
          enabled={active}
          setEnabled={setActive}
          labelLeft
        />
      </div>
      <div className="w-full bg-white px-10 pb-1.5 ">
        <div className="space-y-5 max-h-60vh overflow-y-auto pb-1">
          {list.length > 0 &&
            list?.map((i) => {
              return (
                <SquadCard
                  profile={i}
                  onSelect={() => {
                    history.push({
                      pathname: `/squadUser/${i?.id}`,
                      state: { user: i },
                    });
                  }}
                />
              );
            })}
        </div>
        {list?.length == 0 && <EmptyState text="No college members found!" />}
      </div>
    </>
  );
}

export default CollegeMembers;
