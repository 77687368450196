export const PriorityButton = (props) => {
  return (
    <button
      onClick={props.onClick}
      className={`flex gap-1 justify-center items-center ${
        props.selected
          ? `priority-dark-${props.priority}`
          : `priority-light-${props.priority}`
      }`}
      disabled={props.disabled}
    >
      {props.startIcon} P{props.priority} {props.endIcon}
    </button>
  );
};
