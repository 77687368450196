import React from "react";
import Drawer from "@mui/material/Drawer";

// Icons
import { XIcon } from "@heroicons/react/solid";

function ConfirmModal({ isOpen, closeModal, onAccept, text }) {
  return (
    <Drawer
      anchor="bottom"
      open={isOpen}
      onClose={() => closeModal()}
      transitionDuration={300}
      PaperProps={{
        style: { maxHeight: "50vh" },
      }}
    >
      <div className="bg-gray-50">
        <div className="relative">
          <div className="bg-primary-red-light text-black">
            <div className="flex flex-row w-full items-center justify-between p-3 rounded-t-lg">
              <h5 className="font-poppins text-sm">Confirmation!</h5>
              <button
                aria-label="Close"
                type="button"
                onClick={() => closeModal()}
              >
                <XIcon className="h-5 w-5" />
              </button>
            </div>
          </div>
          <div className="px-3 pt-3 pb-20 relative">
            <div className="relative">
              <div>
                <div className="" md="12">
                  <div className="text-left text-xs poppins font-medium mb-3">
                    {text}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border-t pt-3 border-gray-300 w-full fixed bottom-2 flex flex-row items-center justify-end space-x-3">
            <div
              onClick={onAccept}
              className="font-poppins text-xs font-medium px-5 py-1 rounded cursor-pointer"
            >
              Yes
            </div>
            <div
              onClick={closeModal}
              className="font-poppins text-xs font-medium px-5 py-1 rounded cursor-pointer"
            >
              No
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default ConfirmModal;
