import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

// Redux
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";

// Component
import { updateMyProfile } from "config/APIs/users";
import { userUpdate } from "redux/user";
import YodaLoader from "components/Comman/Loaders/YodaLoader";

/**
 * Page to
 * Middleware to connect to  Gmail, Update user profile with the token and redirect
 */
function GoogleAuth() {
  const history = useHistory();
  const dispatch = useDispatch();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const query = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    updateProfile();
  }, []);

  const updateProfile = async () => {
    try {
      let body = { googleAuth: query };

      const res = await updateMyProfile(body);

      if (res.data?.data) {
        let cred = res.data?.data;
        cred["role"] = "admin";
        cred["loginType"] = "phone";
        dispatch(
          userUpdate({
            user: cred,
          })
        );

        dispatch(
          showToast({
            message: "Google calendar synced!",
          })
        );
        window.location.replace("/home/events");
      } else {
        dispatch(
          showToast({
            message: "Something went wrong while updating profile",
            type: "error",
          })
        );
      }
    } catch (e) {
      console.log("Update Profile", e);
      dispatch(
        showToast({
          message: e?.response?.data?.message,
          type: "error",
        })
      );
    }
  };

  return <YodaLoader text="Redirecting you to a master piece!" />;
}

export default GoogleAuth;
