import { PlusCircle } from "@phosphor-icons/react";
import Member from "../Comman/Cards/MemberCard/Member";
import { useEffect, useState } from "react";
import AddMembers from "components/Modals/AddMembers";
import * as ProjectAPI from "config/APIs/project";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import EmptyState from "components/Comman/EmptyState";
import { USER_TYPE_CUSTOMER_FWS } from "helpers/constants/users";

export default function Members({
  project,
  selectedMember,
  setSelectedMember,
}) {
  const dispatch = useDispatch();
  const [showAddMembersModal, setShowAddMembersModal] = useState(false);

  const [allMembers, setAllMembers] = useState();
  useEffect(() => {
    if (project) {
      let all = project?.owner ? [{ ...project?.owner, type: "Owner" }] : [];
      all = all.concat(project?.members);
      setSelectedMember(all[0]);
      setAllMembers(all);
    }
  }, [project]);

  const handleUpdate = async (members) => {
    try {
      const response = await ProjectAPI.update(project.id, {
        update: { id: project?.id },
        members: members,
      });
    } catch (err) {
      console.log("Error", err);
      let all = project?.owner ? [{ ...project?.owner, type: "Owner" }] : [];
      all = all.concat(project?.members);
      setSelectedMember(all[0]);
      dispatch(
        showToast({
          message: "One or more fields is incorrect!",
          type: "error",
        })
      );
    }
  };

  return (
    <>
      <AddMembers
        type="members"
        allUsers
        userType={USER_TYPE_CUSTOMER_FWS}
        isOpen={showAddMembersModal}
        closeModal={() => setShowAddMembersModal(false)}
        selected={allMembers}
        onUpdate={(id, profiles) => {
          let all = [...allMembers];
          let oldIds = all?.map((p) => p?.id);
          let newMem = profiles?.filter((p) => !oldIds?.includes(p?.id));
          all = all.concat(newMem);
          setAllMembers(all);

          let ids = newMem?.map((p) => p?.id);
          handleUpdate(ids);
          setShowAddMembersModal(false);
        }}
        project={project}
      />
      <div className="w-full h-full border bg-white border-gray-100 rounded-md p-4 px-6 shadow-md">
        <div className="flex justify-between items-center">
          <h2 className="font-extrabold text-xl text-black font-inter">
            Members
          </h2>
          <button
            onClick={() => setShowAddMembersModal(true)}
            className="flex items-center gap-1 border p-1.5 border-black rounded text-xs text-black"
          >
            <PlusCircle size={12} />
            Add a Member
          </button>
        </div>

        <div className="flex flex-col mt-3 gap-6">
          {allMembers?.length > 0 ? (
            allMembers?.map((member, i) => (
              <Member
                key={i}
                member={member}
                isSelected={selectedMember?.id === member?.id}
                onClick={() => setSelectedMember(member)}
              />
            ))
          ) : (
            <EmptyState
              image="/assets/images/empty/member.svg"
              text="No members added to the project!"
            />
          )}
        </div>
      </div>
    </>
  );
}
