import React from "react";
import { AiOutlineSearch } from "react-icons/ai";
import Events from "./Events";
const events = [
  {
    id: 1,
    name: "Tribe Events",
    desc: " Events for the whole Caarya community.",
    logo: "tribe_events.png",
    details: [
      {
        id: 1,
        title: "Caarya Tribe",
        logo: "hq.png",
        inProgress: 0,
        ready: 0,
        scheduled: 0,
        last: "Spill the Coffee",
        date: "Thursday Oct 23",
        status: "done",
      },
    ],
  },
  {
    id: 2,
    name: "CREW Events",
    desc: "Events for remote work experience",
    logo: "crew_logo.png",
    details: [
      {
        id: 1,
        title: "Yoshi CREW",
        logo: "hq.png",
        inProgress: 0,
        ready: 0,
        scheduled: 0,
        last: "Spill the Coffee",
        date: "Thursday Oct 23",
        status: "done",
      },
      {
        id: 2,
        title: "Yoshi CREW",
        logo: "hq.png",
        inProgress: 0,
        ready: 0,
        scheduled: 0,
        last: "Spill the Coffee",
        date: "Thursday Oct 23",
        status: "incomplete",
      },
    ],
  },
  {
    id: 3,
    name: "CGC Events",
    desc: "Events curated for each CGC",
    logo: "cgc_events.png",
    details: [
      {
        id: 1,
        title: "Caarya Tribe",
        logo: "hq.png",
        inProgress: 0,
        ready: 0,
        scheduled: 0,
        last: "Spill the Coffee",
        date: "Thursday Oct 23",
        status: "not done",
      },
    ],
  },
  {
    id: 4,
    name: "Clan Events",
    desc: "Events curated for each Clan",
    logo: "clan_events.png",
    details: [
      {
        id: 1,
        title: "Caarya Tribe",
        logo: "hq.png",
        inProgress: 0,
        ready: 0,
        scheduled: 0,
        last: "No Record",
        date: "",
        status: "done",
      },
    ],
  },
];
const EventPlanner = () => {
  return (
    <div>
      <div className="bg-white w-full p-6">
        <h1 className="text-caarya-red-lighter poppins text-xl font-poppins font-medium">
          Event Planner
        </h1>
      </div>
      <div className="mt-2 bg-white w-full p-8 h-[90vh] overflow-y-auto">
        <h1 className="text-neutral-500">
          Here you will find all the entities you can plan an event for
        </h1>
        <div className="mt-4 w-full flex bg-white border-neutral-300 border items-center pr-2 pl-2 rounded-3xl">
          <AiOutlineSearch size={25} color="gray" />
          <input
            placeholder="Search by Name, CGC, Clan , Crew, etc..."
            type="text"
            className="w-full border-transparent  focus:border-transparent rounded-3xl focus:ring-0"
          />
        </div>

        {events?.map((event) => (
          <div className="mt-12">
            <Events event={event} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default EventPlanner;
