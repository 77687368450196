import React, { useState, useEffect } from "react";
import * as GoalAPI from "config/APIs/task/goal";
import { showToast } from "redux/toaster";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ViewEditDetails from "./Details";

function Details({ goalId, goalDetails, onUpdate }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [details, setDetails] = useState({});
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    setDetails(goalDetails);
  }, [goalDetails]);

  const deleteGoal = async () => {
    setFetching(true);
    try {
      const response = await GoalAPI.deleteGoal(goalId);
      if (response.data) {
        dispatch(showToast({ message: "Goal Deleted!" }));
        history.goBack();
      }
    } catch (err) {
      console.log("Delete task error", err);
      switch (err.response?.status) {
        case 401:
          dispatch(showToast({ message: "Unauthorized!", type: "error" }));
          break;
        default:
          dispatch(
            showToast({
              message: err?.response?.message || "Something went wrong!",
              type: "error",
            })
          );
      }
    }
    setFetching(false);
  };

  const handleUpdate = async () => {
    const update = { ...details };

    let body = {
      ...update,
    };
    try {
      const response = await GoalAPI.updateGoal(details.id, body);
      const data = response.data.data;
      onUpdate();
    } catch (err) {
      switch (err.response?.status) {
        case 401:
          dispatch(showToast({ message: "Unauthorized!", type: "error" }));
          break;
        default:
          dispatch(
            showToast({
              message: err?.response?.message || "Something went wrong!",
              type: "error",
            })
          );
      }
    }
  };

  return (
    <div className="w-full mt-2.5 lg:mt-5 h-70vh lg:max-h-90vh lg:h-80vh px-2.5 overflow-y-auto">
      <ViewEditDetails
        details={details}
        setDetails={setDetails}
        onUpdate={() => handleUpdate()}
        onDelete={() => deleteGoal()}
      />
    </div>
  );
}

export default Details;
