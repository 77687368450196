import React, { useEffect, useState } from "react";

import TasksList from "components/Comman/Lists/TaskList";
import TaskListLoader from "components/Comman/Loaders/TaskListLoader";
import EmptyState from "components/Comman/EmptyState";

function UsersTask({ tasks, fetching, getTasks }) {
  return (
    <div className="w-full px-5 py-4">
      {!fetching && tasks.length > 0 && (
        <TasksList onUpdate={() => getTasks()} tasks={tasks} />
      )}

      {fetching && <TaskListLoader />}
      {tasks.length === 0 && (
        <div className="h-40vh w-full flex flex-row items-center justify-center">
          <EmptyState
            imageMedium
            image={"/assets/images/empty/task.svg"}
            text="The user doesn't have any tasks yet!"
          />
        </div>
      )}
    </div>
  );
}

export default UsersTask;
