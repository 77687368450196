import { RocketLaunch, Target } from "@phosphor-icons/react";
import { TaskStatus } from "helpers/task";
import React from "react";

function ProjectGoal({ project = {}, goals = [] }) {
  return (
    <div className="dsr-project-goal-card w-full space-y-6">
      <div className="flex flex-row items-center justify-start space-x-2.5">
        {project?.iamge?.url ? (
          <img
            src={project?.image?.url}
            alt=""
            className="h-12 w-12 rounded-full object-cover"
          />
        ) : (
          <RocketLaunch size={48} className="text-white" />
        )}
        <div className="flex flex-col items-start w-11/12">
          <h1 className="project-title">{project?.title}</h1>
        </div>
      </div>
      {goals?.length > 0 && (
        <div className="flex flex-col space-y-6 px-2">
          {goals?.map((goal) => {
            return (
              <div className="goal grid grid-cols-12 gap-2">
                <div className="col-span-9 flex flex-row items-center space-x-2.5 justify-start">
                  <Target size={24} />
                  <p className="w-[90%]">{goal?.title}</p>
                </div>
                <div className="col-span-3 flex flex-row items-start justify-end">
                  <div className={`status ${goal?.status}`}>
                    {TaskStatus?.find((t) => t?.value == goal?.status)?.label}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default ProjectGoal;
