import EmptyState from "components/Comman/EmptyState";
import moment from "moment";
import React from "react";

function CGCsChronicles({ list }) {
  return (
    <div className="flex flex-col items-stretch">
      {list?.length > 0 ? (
        list?.map((item) => {
          return (
            <div className="py-5 px-2 border-b border-primary-neutral-300 flex flex-row items-start space-x-5">
              <div className="w-10 h-10 rounded-full bg-primary-gray-80">
                <img
                  src={`/assets/svg/chronicle/${item?.icon?.svg}.svg`}
                  alt=""
                  className="w-10 h-10 rounded-full object-cover"
                />
              </div>
              <div className="flex flex-col items-stretch space-y-3 text-primary-gray-800 font-lato w-10/12">
                <p className="text-base font-light">{item?.description}</p>
                <div className="flex flex-row w-full items-center justify-between">
                  <div className="flex flex-row items-center space-x-3">
                    <div className="w-[22px] h-[22px] rounded-full bg-primary-gray-80">
                      <img
                        src={item?.cgc?.image?.url}
                        alt=""
                        className="w-[22px] h-[22px] rounded-full object-cover"
                      />
                    </div>
                    <p className="text-primary-neutral-500 font-lato text-2xs font-semibold">
                      {item?.cgc?.name}
                    </p>
                  </div>
                  <p className="text-2xs font-light">
                    {moment(item?.createdAt).format("LL")}
                  </p>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <EmptyState
          svg={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="120"
              height="120"
              viewBox="0 0 120 120"
              fill="none"
            >
              <path
                d="M37.9403 68.1181C38.4697 67.5887 38.4697 66.7063 37.9403 66.1769L33.705 61.9416L39.1756 56.471C39.705 55.9416 39.705 55.0593 39.1756 54.5299C38.6461 54.0005 37.7638 54.0005 37.2344 54.5299L31.7638 60.0005L26.8226 55.0593C26.2932 54.5299 25.4108 54.5299 24.8814 55.0593C24.352 55.5887 24.352 56.471 24.8814 57.0005L29.8226 61.9416L24.8814 66.8828C24.352 67.4122 24.352 68.2946 24.8814 68.824C25.4108 69.3534 26.2932 69.3534 26.8226 68.824L31.7638 63.8828L35.9991 68.1181C36.705 68.6475 37.4108 68.6475 37.9403 68.1181Z"
                fill="currentColor"
                fill-opacity="0.5"
              />
              <path
                d="M37.4106 85.5891C36.8812 86.1185 36.8812 87.0008 37.587 87.5302C38.1165 88.0596 38.9988 88.0596 39.5282 87.3538C48.1753 77.8243 62.9988 78.0008 71.4694 86.8243C71.9988 87.3538 72.8812 87.3538 73.4106 86.8243C73.94 86.2949 73.94 85.4126 73.4106 84.8832C63.7047 75.0008 47.1165 74.8243 37.4106 85.5891Z"
                fill="currentColor"
                fill-opacity="0.5"
              />
              <path
                d="M103.411 96.176L107.999 49.4113C108.176 47.4701 107.47 45.529 106.235 44.1172C104.999 42.7054 103.058 41.8231 101.117 41.8231V29.6466C101.117 25.0584 97.5877 21.3525 92.9994 21.176L18.7053 20.1172C13.9406 20.1172 10.4112 23.8231 10.2347 28.2348V31.4113C8.11707 31.9407 6.52883 33.3525 5.64648 35.4701C4.58765 36.7054 3.88177 38.2937 3.52883 39.529C2.64648 42.7054 2.82295 46.0584 3.17589 49.4113C4.58765 64.9407 5.99942 80.2937 7.23471 95.8231C7.76412 102.529 9.52883 105.352 19.2347 105.882C46.9406 107.294 74.6465 107.117 102.352 106.588C103.411 106.588 104.646 106.588 105.529 106.058C106.588 105.529 107.294 104.47 107.117 103.411C106.588 101.117 102.705 101.47 100.411 101.294C101.999 100.235 103.058 98.4701 103.411 96.176ZM18.7053 22.9407L92.9994 23.9995C95.9994 23.9995 98.6465 26.6466 98.47 29.6466V41.9995H72.5288C70.5877 41.9995 68.6465 41.1172 67.2347 39.7054L63.1759 35.1172C61.0582 32.8231 58.0582 31.4113 54.8818 31.4113H12.8818V28.5878C12.8818 25.2348 15.5288 22.7642 18.7053 22.9407ZM96.5288 99.8819H16.9406C14.8229 99.8819 12.8818 98.2937 12.7053 95.9995L7.58765 38.4701C7.41118 35.9995 9.35236 33.8819 11.8229 33.8819H54.8818C57.3524 33.8819 59.6465 34.9407 61.2347 36.7054L65.2935 41.2937C67.0583 43.4113 69.7053 44.6466 72.5288 44.6466H101.117C102.352 44.6466 103.411 45.176 104.294 46.0584C105.176 46.9407 105.529 48.176 105.352 49.2348L100.764 95.9995C100.588 98.1172 98.6465 99.8819 96.5288 99.8819Z"
                fill="currentColor"
                fill-opacity="0.5"
              />
              <path
                d="M77.9999 59.6466L81.8822 55.7642C82.4117 55.2348 82.4117 54.3525 81.8822 53.8231C81.3528 53.2937 80.4705 53.2937 79.9411 53.8231L76.0587 57.7054L71.1175 52.7642C70.5881 52.2348 69.7058 52.2348 69.1764 52.7642C68.6469 53.2937 68.6469 54.176 69.1764 54.7054L74.1175 59.6466L69.5293 64.2348C68.9999 64.7642 68.9999 65.6466 69.5293 66.176C70.0587 66.7054 70.9411 66.7054 71.4705 66.176L76.0587 61.5878L80.9999 66.529C81.5293 67.0584 82.4116 67.0584 82.9411 66.529C83.4705 65.9995 83.4705 65.1172 82.9411 64.5878L77.9999 59.6466Z"
                fill="currentColor"
                fill-opacity="0.5"
              />
              <path
                d="M101.999 13.4122C100.411 15.5299 99.1754 17.6475 98.293 20.1181C98.1165 20.824 98.4695 21.5299 99.1754 21.7063C99.8813 21.8828 100.587 21.5299 100.764 20.824C101.469 18.7063 102.528 16.5887 103.94 14.824C104.469 14.2946 104.293 13.4122 103.764 12.8828C103.234 12.8828 102.528 12.8828 101.999 13.4122Z"
                fill="currentColor"
                fill-opacity="0.5"
              />
              <path
                d="M113.293 18.5289C112.94 17.9995 112.057 17.6465 111.528 18.1759L103.234 23.4701C102.704 23.823 102.528 24.7054 102.881 25.2348C103.234 25.7642 104.116 26.1171 104.646 25.5877L112.94 20.2936C113.469 19.9407 113.646 19.0583 113.293 18.5289Z"
                fill="currentColor"
                fill-opacity="0.5"
              />
              <path
                d="M115.764 28.0625L106.411 28.7684C105.705 28.7684 105.176 29.4743 105.176 30.1801C105.176 30.886 105.882 31.4154 106.588 31.4154L115.94 30.7096C116.646 30.7096 117.176 30.0037 117.176 29.2978C116.999 28.5919 116.47 28.0625 115.764 28.0625Z"
                fill="currentColor"
                fill-opacity="0.5"
              />
            </svg>
          }
          text="No CGC Updates found!"
        />
      )}
    </div>
  );
}

export default CGCsChronicles;
